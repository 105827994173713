export const titularInversionesObject = [
    {
        "nombre" : "",
        "post": "",
        "data" : [
            {
                "titulo": "Mensaje",
                "parrafo": "La Unidad de Inversiones (UI), es un área creada en el año de 2014 por el gobierno estatal y por conducto de la Secretaría de Hacienda elabora, evalúa y dictamina: Costos-Beneficios, Costos-Eficiencia, Notas y Fichas Técnicas, con la finalidad de aplicar los Recursos Federales del Ramo 23. Este centro tiene como propósito contribuir a optimizar el uso de los recursos destinados a la realización de proyectos y programas de inversión, así como la capacitación permanente en preparación y evaluación socioeconómica de proyectos, para todos los niveles de gobierno tanto estatal como municipal.",
                "lista": [
                    {
                        "datos": "",
                    },
                    {
                        "datos": "",
                    }
                ]
            },
            {
                "titulo": "Misión",
                "parrafo": "La Unidad de Inversiones (UI), tiene como propósito el promover la capacitación y las buenas prácticas en materia de evaluación financiera y socioeconómica de proyectos de inversión pública. Para lograr lo anterior la UI ofrece y patrocina cursos de capacitación a través de los cuales realiza estudios de costo-beneficio de proyectos reales de inversión pública. Además, apoya al gobierno municipal en las tareas de control, seguimiento y dictaminacion de proyectos.",
                "lista": [
                    {
                        "datos": "",
                    },
                    {
                        "datos": "",
                    }
                ]
            },
            {
                "titulo": "Visión",
                "parrafo": "El propósito de la Unidad de Inversiones (UI), es contribuir a optimizar el uso de los recursos destinados a la realización de proyectos y programas de inversión, así como la capacitación permanente en preparación y evaluación socioeconómica de proyectos estatales y municipales; dotando a las diferentes dependencias de herramientas objetivas que permitan fortalecer el sistema de planeación, inversión y gestión pública mediante el análisis costo-beneficio.",
                "lista": [
                    {
                        "datos": "",
                    },
                    {
                        "datos": "",
                    }
                ]
            },
            {
                "titulo": "Objetivos",
                "parrafo": "",
                "lista": [
                    {
                        "datos": "Promover la capacitación de los servidores públicos de los gobiernos estatales y municipales en materia de preparación, elaboración, evaluación y seguimiento de proyectos de inversión.",
                    },
                    {
                        "datos": "Difundir, las técnicas de preparación, elaboración, ejecución, evaluación y seguimiento de los proyectos de inversión, incluyendo la distribución de materiales sobre dichos temas, cuando no exista impedimento legal a hacerlo.",
                    },
                    {
                        "datos": "Realizar estudios que instruyan a la Unidad de Inversiones relacionadas con el ejercicio de sus atribuciones y para el mejor desempeño de éstas.",
                    }
                ]
            }
        ]
    }
]
