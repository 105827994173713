import React from 'react'
import { programaAnualdeEvaluacionArray } from '../../../json/Armonizacion/OtrasObligaciones/ProgramaAnualArray';
import { servidorArray } from '../../../json/servidor';
import './programaAnual.css';

var servers = JSON.parse(JSON.stringify (servidorArray));

const ProgramaAnualdeEvaluacion = () => {
  return (
    <>
        <div className='row row-cols-1 row-cols-lg-1 g-2 g-lg-3 pb-5'>
            {
              programaAnualdeEvaluacionArray.map((item, r) => (
                <>
                <h5 className='subtitulo-armonizacion hr-subs pb-1 fw-bolder pt-3' key={r}>{ item.ejercicio }</h5>
                  {
                    <div className='col'>
                      {
                        item.trimestre.map((item, t) => (
                          <div key={t}>
                            <>
                              {
                                item?.data ?
                                <div className='pb-3'>
                                  <p className='mb-0'>{item.nombre}</p>
                                  {
                                    item.data.map((tdata, k) => (
                                      <div className='d-flex flex-row align-items-center' key={k}>
                                          {/* <a className='pe-1' href={servers.servidor + tdata.docx}><span className="badge bg-info px-2 py-1">.docx</span></a>
                                          <a href={servers.servidoruno + tdata.pdf} target="_blank"><span className="badge bg-dangerous px-2 py-1">.pdf</span></a> */}
                                          <a className='pe-1' href={tdata.docx}><span className="badge bg-info px-2 py-1">.docx</span></a>
                                          <a href={tdata.pdf} target="_blank"><span className="badge bg-dangerous px-2 py-1">.pdf</span></a>
                                      </div>
                                  ))
                                  }
                                </div>
                                :
                                <div className='mt-2 pb-1'>
                                  {/* <a href={servers.servidoruno + item.url} target="_blank">{ item.nombre }</a> */}
                                  <a href={item.url} target="_blank">{ item.nombre }</a>
                                </div>
                              }
                            </>
                          </div>
                        ))
                      }
                    </div>
                  }
                </>
              ))
            }
        </div>
    </>
  )
}

export default ProgramaAnualdeEvaluacion