export const paqueteHacendario21Object = {
    'nombre': 'Paquete Hacendario 2021',
    'tipo': '2',
    'servidor': '1',
    'seccion': [
        {
            'titulo': '',
            'subtitulo': '',
            'docs': [
                {
                    'nombreLink':'Catálogos de la Clave Presupuestaria 2021',
                    'urltres': 'ServiciosInternos/CatalogosdelaClavePresupuestaria2021'
                },
                {
                    'nombreLink':'Manual de Formulación Presupuestal 2021',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2021/Manual%20de%20Formulacion%20Presupuestal.pdf'
                },
                {
                    'nombreLink':'Criterios para la Integración del Paquete Hacendario 2021',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2021/Criterios%20para%20la%20Integracion%20del%20Paquete%20Hacendario.pdf'
                },
                {
                    'nombreLink':'Iniciativa de la Miscelánea Fiscal',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2021/Iniciativa%20de%20la%20Miscelanea%20Fiscal.pdf'
                },
                {
                    'nombreLink':'Iniciativa de la Ley de Ingresos',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2021/Iniciativa%20de%20la%20Ley%20de%20Ingresos.pdf'
                },
                {
                    'nombreLink':'Proyecto de Decreto de Presupuesto de Egresos',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2021/Proyecto%20de%20Decreto%20de%20Presupuesto%20de%20Egresos.pdf'
                },
                {
                    'nombreLink':'Anexos del Proyecto de Decreto de Presupuesto de Egresos',
                    'urltres': 'ServiciosInternos/AnexosdelProyectodeDecretodePresupuestodeEgresos2021'
                }
            ]
        }
    ]
}