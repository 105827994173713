export const ejercicioPrepEgre21Object = {
    'nombre': 'Ejercicio del Presupuesto 2021',
        'tipo': '2',
        'servidor': '1',
        'seccion': [
            {
                'titulo': '',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Circular No. 0001',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2021/Circular%20No%200001.pdf'
                    },
                    {
                        'nombreLink':'Circular No. 0002',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2021/Circular%20No%200002.pdf'
                    },
                    {
                        'nombreLink':'Circular No. 0003',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2021/Circular%20No%200003.pdf'
                    },
                    {
                        'nombreLink':'Circular No. 0006',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2021/Circular%20No%200006.pdf'
                    }
                ]
            },
            {
                'titulo': '',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Manual Sistema de Entero de Pago de Deductivas y Retenciones',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2021/Manual%20Sistema%20de%20Entero%20de%20Pago%20de%20Deductivas%20y%20Retenciones.pdf'
                    }
                ]
            },
            {
                'titulo': '',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Manual de Normas y Lineamientos para el Ejercicio de los Recursos del Gasto de Operación 2021',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2021/Manual%20de%20Normas%20y%20Lineamientos%20para%20el%20Ejercicio%20de%20los%20Recursos%20del%20Gasto%20de%20Operacion%202021.pdf',
                        'urldos': [
                            {
                                'descripcion': 'Anexo A1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GO/Anexo%20A1_21.docx'
                            },
                            {
                                'descripcion': 'Anexo A2',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2021/Anexos%20GO/Anexo%20A2_21.pdf'
                            },
                            {
                                'descripcion': 'Anexo A3',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GO/Anexo%20A3_21.docx'
                            },
                            {
                                'descripcion': 'Anexo C1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GO/Anexo%20C1_21.docx'
                            },
                            {
                                'descripcion': 'Anexo C2',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GO/Anexo%20C2_21.docx'
                            },
                            {
                                'descripcion': 'Anexo C3',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GO/Anexo%20C3_21.docx'
                            },
                            {
                                'descripcion': 'Anexo C4',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GO/Anexo%20C4_21.docx'
                            },
                            {
                                'descripcion': 'Anexo C5',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GO/Anexo%20C5_21.docx'
                            },
                            {
                                'descripcion': 'Anexo C6',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GO/Anexo%20C6_21.docx'
                            },
                            {
                                'descripcion': 'Anexo C7',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GO/Anexo%20C7_21.docx'
                            },
                            {
                                'descripcion': 'Anexo C8',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GO/Anexo%20C8_21.docx'
                            },
                            {
                                'descripcion': 'Anexo C9-A',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GO/Anexo%20C9-A_21.docx'
                            },
                            {
                                'descripcion': 'Anexo C9-B',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GO/Anexo%20C9-B_21.docx'
                            },
                            {
                                'descripcion': 'Anexo C10',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GO/Anexo%20C10_21.docx'
                            },
                            {
                                'descripcion': 'Anexo C11',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GO/Anexo%20C11_21.docx'
                            },
                            {
                                'descripcion': 'Anexo C12',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GO/Anexo%20C12_21.docx'
                            },
                            {
                                'descripcion': 'Anexo C13',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GO/Anexo%20C13_21.docx'
                            },
                            {
                                'descripcion': 'Anexo D1',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2021/Anexos%20GO/Anexo%20D1%20-%2021.pdf'
                            },
                            {
                                'descripcion': 'Anexo E',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2021/Anexos%20GO/Anexo%20E_21.pdf'
                            },
                            {
                                'descripcion': 'Anexo F',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GO/Anexo%20F_21.docx'
                            },
                            {
                                'descripcion': 'Anexo H',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2021/Anexos%20GO/Anexo%20H_21.pdf'
                            },
                            {
                                'descripcion': 'Anexo I',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2021/Anexos%20GO/Anexo%20I_21.pdf'
                            },
                            {
                                'descripcion': 'Anexo J',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GO/Anexo%20J_21.docx'
                            },
                            {
                                'descripcion': 'Anexo K',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2021/Anexos%20GO/Anexo%20K_21.pdf'
                            },
                            {
                                'descripcion': 'Anexo L1',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2021/Anexos%20GO/Anexo%20L1%20-%2021.pdf'
                            }
                        ]
                    }
                ]
            },
            {
                'titulo': '',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Manual de Normas y Lineamientos para el Ejercicio de los Recursos del Gasto de Inversión 2021',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2021/Manual%20de%20Normas%20y%20Lineamientos%20para%20el%20Ejercicio%20de%20los%20Recursos%20del%20Gasto%20de%20Inversion%202021.pdf',
                        'urldos': [
                            {
                                'descripcion': 'Anexo A1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GI/Anexo%20A1_21.docx'
                            },
                            {
                                'descripcion': 'Anexo A2',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2021/Anexos%20GI/Anexo%20A2_21.pdf'
                            },
                            {
                                'descripcion': 'Anexo A3',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GI/Anexo%20A3_21.docx'
                            },
                            {
                                'descripcion': 'Anexo C1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GI/Anexo%20C1_21.docx'
                            },
                            {
                                'descripcion': 'Anexo C2',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GI/Anexo%20C2_21.docx'
                            },
                            {
                                'descripcion': 'Anexo C3',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GI/Anexo%20C3_21.docx'
                            },
                            {
                                'descripcion': 'Anexo C4',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GI/Anexo%20C4_21.docx'
                            },
                            {
                                'descripcion': 'Anexo C5',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GI/Anexo%20C5_21.docx'
                            },
                            {
                                'descripcion': 'Anexo C6',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GI/Anexo%20C6_21.docx'
                            },
                            {
                                'descripcion': 'Anexo C7',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GI/Anexo%20C7_21.docx'
                            },
                            {
                                'descripcion': 'Anexo C8',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GI/Anexo%20C8_21.docx'
                            },
                            {
                                'descripcion': 'Anexo C9',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GI/Anexo%20C9_21.docx'
                            },
                            {
                                'descripcion': 'Anexo C10',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GI/Anexo%20C10_21.docx'
                            },
                            {
                                'descripcion': 'Anexo D1',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2021/Anexos%20GI/Anexo%20D1_21.pdf'
                            },
                            {
                                'descripcion': 'Anexo D2',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2021/Anexos%20GI/Anexo%20D2_21.pdf'
                            },
                            {
                                'descripcion': 'Anexo D3 (1 a 5 partidas)',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2021/Anexos%20GI/Anexo%20D3_21%20(1%20a%205%20partidas).pdf'
                            },
                            {
                                'descripcion': 'Anexo D3 (6 a 16 partidas',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2021/Anexos%20GI/Anexo%20D3_21%20(6%20a%2016%20partidas).pdf'
                            },
                            {
                                'descripcion': 'Anexo D3 (17 a 26 partidas)',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2021/Anexos%20GI/Anexo%20D3_21%20(17%20a%2026%20partidas).pdf'
                            },
                            {
                                'descripcion': 'Anexo D4',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2021/Anexos%20GI/Anexo%20D4_21.pdf'
                            },
                            {
                                'descripcion': 'Anexo D5',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20GI/Anexo%20D5_21.docx'
                            },
                            {
                                'descripcion': 'Anexo D6',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2021/Anexos%20GI/Anexo%20D6_21.pdf'
                            },
                            {
                                'descripcion': 'Anexo D7',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2021/Anexos%20GI/Anexo%20D7_21.pdf'
                            },
                            {
                                'descripcion': 'Anexo D8',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2021/Anexos%20GI/Anexo%20D8_21.pdf'
                            },
                            {
                                'descripcion': 'Anexo D9',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2021/Anexos%20GI/Anexo%20D9_21.pdf'
                            }
                        ]
                    }
                ]
            },
            {
                'titulo': '',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Manual de Lineamientos y Operación del Sistema Validador de CFDI',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2021/Manual%20de%20Lineamientos%20y%20Operacion%20del%20Sistema%20Validador%20de%20CFDI.pdf',
                        'urldos': [
                            {
                                'descripcion': 'Anexo SVA',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20Validador%20-%20SEPDR/Anexo%20SVA1.docx'
                            },
                            {
                                'descripcion': 'Anexo SVA',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20Validador%20-%20SEPDR/Anexo%20SVA2.docx'
                            },
                            {
                                'descripcion': 'Anexo SVA',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20Validador%20-%20SEPDR/Anexo%20SVA3.docx'
                            },
                            {
                                'descripcion': 'Anexo SVB',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2021/Anexos%20Validador%20-%20SEPDR/Anexo%20SVB.docx'
                            }
                        ]
                    }
                ]
            }
        ]
    }