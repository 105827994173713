import React from 'react';
import { asociacionesPubPrivArray } from '../../../json/Servicios/asociacionesPubPriv';
import DynamicBreadcrumb from '../../ReusableComponents/Breadcrumb'

const AsociacionesPublicoPrivadas = () => {

    const breadcrumbItems = [
        { label: 'Inicio', link: 'https://hacienda.hidalgo.gob.mx/' },
        { label: 'Unidad de Inversiones', link: 'https://hacienda.hidalgo.gob.mx/Micrositios/UnidaddeInversiones' },
        { label: 'Asociaciones Público Privadas' },
      ];

  return (
    <div className='container pt-5'>
        <div className='row'>
            <DynamicBreadcrumb items={breadcrumbItems} />
            <div>
                <h3 className='title mt-5'>Asociaciones Público Privadas</h3>
                <hr className='hr-gob'/>
            </div>
            {
                asociacionesPubPrivArray.map((item,i) => (
                    <div key={i}>
                        <h3 className='ps-3 mt-5'>{ item.name }</h3>
                        <hr className='hr'/>
                        {
                            item.data?
                            item.data.map((data, t)=>(
                                <div key={t}>
                                    <h5 className='ps-4 pt-2'>{data.subtitulo}</h5>
                                    <a className='ps-5' href={data.urlpdf} target="_blank">{ data.titulo }</a>
                                </div>
                            ))
                            : null
                        }
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default AsociacionesPublicoPrivadas