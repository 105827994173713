import React, { useEffect } from 'react';
import './detalleFrames.css';
import { useParams } from 'react-router-dom';
import { armonizacionContableObject } from '../../json/Armonizacion/armonizacionContableObj';
import { paqueteHacendarioObject } from '../../json/Secretaría/paqueteHacendario';
import { evaluaciondelDesempeñoObject } from '../../json/Secretaría/evaluaciondelDesempeño';
import { estadosFinancierosObject } from '../../json/Secretaría/estadosFinancieros';
import { impuestosyDerechosObject } from '../../json/Secretaría/impuestosyDerechos';
import { transparenciaFinporTemaObject } from '../../json/Secretaría/transparenciaFinancieraporTema';
import { aRegionalObject } from '../../json/Secretaría/aRegional'
import Frame from '../../components/ReusableComponents/Frame';

const DetalleFramesObjects = () => {

const { nombre } = useParams();
useEffect(() => {
    window.scrollTo(0, 0);
    }, []);

    const changeObject = (n) => {
        switch(n){
            //frames
            case 'ArmonizacionContable':
                return armonizacionContableObject;
            case 'PaqueteHacendario':
                return paqueteHacendarioObject;
            case 'EvaluaciondelDesempeño':
                return evaluaciondelDesempeñoObject;
            case 'EstadosFinancieros':
                return estadosFinancierosObject;
            case 'ImpuestosyDerechos':
                return impuestosyDerechosObject;
            case 'TransparenciaFinancieraporTema':
                return transparenciaFinporTemaObject;
            case 'ARegional':
                return aRegionalObject;
            default:
                return [ ]
            }
        }

  return (
    <div className='container'>
        <div className='col-12 pt-1'>
            <div className='servicios_title'>
                <h1 className='my-5'>{ changeObject(nombre).titulo }</h1>
            </div>
            <div className='servicio_title'>
                <h3 className='title'>{ changeObject(nombre).nombre }</h3>
                {/* <hr className='hr-gob' /> */}
            </div>
            { 
                changeObject(nombre).tipo == "5" ?
                <Frame object={ changeObject(nombre) } /> :null
            }
        </div>
    </div>
  )
}

export default DetalleFramesObjects