export const ramo23Object = {
    'nombre': 'Ramo 23',
    'tipo': '2',
    'servidor': '1',
    'seccion': [
        {
            'titulo': 'Proyecto de Desarrollo Regional "APDER" (Ramo 23)',
            'subtitulo': '',
            'docs': [
                {
                    'nombreLink':'Ejercicio Fiscal 2018',
                    'url': '../files/servicios/pdf/Ramo%2023/APDER/Ejercicio%20Fiscal%202018.pdf',
                    'data': [
                        {
                        'urlxlsx': '',
                        'urlpdf': ''
                        }
                    ]
                },
                {
                    'nombreLink':'Ejercicio Fiscal 2017',
                    'url': '../files/servicios/pdf/Ramo%2023/APDER/Ejercicio%20Fiscal%202017.pdf',
                    'urldos': '',
                    'data': [
                        {
                        'urlxlsx': '',
                        'urlpdf': ''
                        }
                    ]
                },
                {
                    'nombreLink':'Ejercicio Fiscal 2016',
                    'url': '../files/servicios/pdf/Ramo%2023/APDER/Ejercicio%20Fiscal%202016.pdf',
                    'urldos': '',
                    'data': [
                        {
                        'urlxlsx': '',
                        'urlpdf': ''
                        }
                    ]
                }
            ]
        },
        {
            'titulo': 'Programas Regionales "PREGI" (Ramo 23)',
            'subtitulo': '',
            'docs': [
                {
                    'nombreLink':'Ejercicio Fiscal 2018',
                    'url': '../files/servicios/pdf/Ramo%2023/PREGI/Ejercicio%20Fiscal%202018.pdf',
                    'data': [
                        {
                        'urlxlsx': '',
                        'urlpdf': ''
                        }
                    ]
                },
                {
                    'nombreLink':'Ejercicio Fiscal 2017',
                    'url': '../files/servicios/pdf/Ramo%2023/PREGI/Ejercicio%20Fiscal%202017.pdf',
                    'data': [
                        {
                        'urlxlsx': '',
                        'urlpdf': ''
                        }
                    ]
                },
                {
                    'nombreLink':'Ejercicio Fiscal 2016',
                    'url': '../files/servicios/pdf/Ramo%2023/PREGI/Ejercicio%20Fiscal%202016.pdf',
                    'data': [
                        {
                        'urlxlsx': '',
                        'urlpdf': ''
                        }
                    ]
                }
            ]
        },
        {
            'titulo': 'Fondo para la Infraestructura Estatal y Municipal',
            'subtitulo': '',
            'docs': [
                {
                    'nombreLink':'Ejercicio Fiscal 2017',
                    'url': '../files/servicios/pdf/Ramo%2023/FondoInfraestructuraEstatalyMunicipal/Ejercicio%20Fiscal%202017.pdf',
                    'data': [
                        {
                        'urlxlsx': '',
                        'urlpdf': ''
                        }
                    ]
                },
                {
                    'nombreLink':'Ejercicio Fiscal 2016',
                    'url': '../files/servicios/pdf/Ramo%2023/FondoInfraestructuraEstatalyMunicipal/Ejercicio%20Fiscal%202016.pdf',
                    'data': [
                        {
                        'urlxlsx': '',
                        'urlpdf': ''
                        }
                    ]
                }
            ]
        },
        {
            'titulo': 'Fortalecimiento Financiero Gasto de Inversión',
            'subtitulo': '',
            'docs': [
                {
                    'nombreLink':'Ejercicio Fiscal 2017',
                    'url': '../files/servicios/pdf/Ramo%2023/FortalecimientoFinancieroGastodeInversion/Ejercicio%20Fiscal%202017.pdf',
                    'data': [
                        {
                        'urlxlsx': '',
                        'urlpdf': ''
                        }
                    ]
                },
                {
                    'nombreLink':'Ejercicio Fiscal 2016',
                    'url': '../files/servicios/pdf/Ramo%2023/FortalecimientoFinancieroGastodeInversion/Ejercicio%20Fiscal%202016.pdf',
                    'data': [
                        {
                        'urlxlsx': '',
                        'urlpdf': ''
                        }
                    ]
                }
            ]
        },
        {
            'titulo': 'Fondo para el fortalecimiento de la Infraestructura Estatal y Municipal',
            'subtitulo': '2017',
            'docs': [
                {
                    'nombreLink':'Recursos Ministrados a Municipios Fondo para el Fortalecimiento de la Infraestructura Estatal y Municipal 1er. Convenio (descargable excel)',
                    'url': '../files/servicios/xls/Ramo%2023/FondoFortalecimientodelaInfraestructuraEstatalyMunicipal/2017/FFIEM%20Primer%20Convenio.xlsx'
                },
                {
                    'nombreLink':'Recursos Ministrados a Municipios Fondo para el Fortalecimiento de la Infraestructura Estatal y Municipal 2do. Convenio (descargable excel)',
                    'url': '../files/servicios/xls/Ramo%2023/FondoFortalecimientodelaInfraestructuraEstatalyMunicipal/2017/FFIEM%20Segundo%20Convenio.xlsx'
                },
                {
                    'nombreLink':'Recursos Ministrados a Municipios Fondo para el Fortalecimiento de la Infraestructura Estatal y Municipal 3er. Convenio (descargable excel)',
                    'url': '../files/servicios/xls/Ramo%2023/FondoFortalecimientodelaInfraestructuraEstatalyMunicipal/2017/FFIEM%20Tercer%20Convenio.xlsx',

                },
                {
                    'nombreLink':'Recursos Ministrados a Municipios Fondo para el Fortalecimiento de la Infraestructura Estatal y Municipal 4to. Convenio (descargable excel)',
                    'url': '../files/servicios/xls/Ramo%2023/FondoFortalecimientodelaInfraestructuraEstatalyMunicipal/2017/FFIEM%20Cuarto%20Convenio.xlsx'
                }
            ]
        },
        {
            'titulo': '',
            'subtitulo': '2016',
            'docs': [
                {
                    'nombreLink':'Recursos Ministrados a Municipios Fondo para el Fortalecimiento de la Infraestructura Estatal y Municipal (descargable excel)',
                    'url': '../files/servicios/xls/Ramo%2023/FondoFortalecimientodelaInfraestructuraEstatalyMunicipal/2016/FFIEM.xlsx'
                }
            ]
        },
        {
            'titulo': 'Fondos Metropolitanos',
            'subtitulo': '2017',
            'docs': [
                {
                    'nombreLink':'FIDEICOMISO FONMETRO PACHUCA',
                    'url': '../files/servicios/pdf/Ramo%2023/FondosMetropolitanos/2017/FIDEICOMISO%20FONMETRO%20PACHUCA.pdf'
                },
                {
                    'nombreLink':'FIDEICOMISO FONMETRO TULA',
                    'url': '../files/servicios/pdf/Ramo%2023/FondosMetropolitanos/2017/FIDEICOMISO%20FONMETRO%20TULA.pdf'
                },
                {
                    'nombreLink':'FIDEICOMISO FONMETRO TULANCINGO',
                    'url': '../files/servicios/pdf/Ramo%2023/FondosMetropolitanos/2017/FIDEICOMISO%20FONMETRO%20TULANCINGO.pdf'
                },
                {
                    'nombreLink':'FIDEICOMISO FONMETRO VALLE DE MÉXICO',
                    'url': '../files/servicios/pdf/Ramo%2023/FondosMetropolitanos/2017/FIDEICOMISO%20FONMETRO%20VALLE%20DE%20ME%CC%81XICO.pdf'
                }
            ]
        },
        {
            'titulo': '',
            'subtitulo': '2016',
            'docs': [
                {
                    'nombreLink':'FIDEICOMISO FONMETRO PACHUCA',
                    'url': '../files/servicios/pdf/Ramo%2023/FondosMetropolitanos/2016/FIDEICOMISO%20FONMETRO%20PACHUCA.pdf'
                },
                {
                    'nombreLink':'FIDEICOMISO FONMETRO TULA',
                    'url': '../files/servicios/pdf/Ramo%2023/FondosMetropolitanos/2016/FIDEICOMISO%20FONMETRO%20TULA.pdf'
                },
                {
                    'nombreLink':'FIDEICOMISO FONMETRO TULANCINGO',
                    'url': '../files/servicios/pdf/Ramo%2023/FondosMetropolitanos/2016/FIDEICOMISO%20FONMETRO%20TULANCINGO.pdf'
                },
                {
                    'nombreLink':'FIDEICOMISO FONMETRO VALLE DE MÉXICO',
                    'url': '../files/servicios/pdf/Ramo%2023/FondosMetropolitanos/2016/FIDEICOMISO%20FONMETRO%20VALLE%20DE%20MEXICO.pdf'
                }
            ]
        },
        {
            'titulo': 'FONREGION',
            'subtitulo': '',
            'docs': [
                {
                    'nombreLink':'Ejercicio Fiscal 2017',
                    'url': '../files/servicios/pdf/Ramo%2023/FONREGION/Ejercicio%20Fiscal%202017.pdf'
                },
                {
                    'nombreLink':'Ejercicio Fiscal 2016',
                    'url': '../files/servicios/pdf/Ramo%2023/FONREGION/Ejercicio%20Fiscal%202016.pdf'
                }
            ]
        },
        {
            'titulo': 'Fondo para la Accesibilidad en el Transporte Público para las Personas con Discapacidad',
            'subtitulo': '',
            'docs': [
                {
                    'nombreLink':'Ejercicio Fiscal 2017',
                    'url': '../files/servicios/pdf/Ramo%2023/FondoTransportePublicoparaPersonasconDiscapacidad/Ejercicio%20Fiscal%202017.pdf'
                }
            ]
        }
    ]
}