import React from 'react';
import { Link } from 'react-router-dom';
import { servidorArray } from '../../json/servidor';

const ListLink = ({object}) => {

  var servers = JSON.parse(JSON.stringify (servidorArray));

  const changeServer = (n) => {
    console.log(n);
    switch(n){
        //ic
        case '1':
          return servers.servidoruno;
        case '2':
          return servers.servidordos;
        case '3':
          return servers.servidortres;
        default:
            return []
        }
    }

  return (
    <>
        {
          object &&
          <div className='container'>
            <div className='row'>
              <ul className="list-group">
                {
                  object.seccion.map((sect,s) => (
                    <div className='my-3' key={s}>
                      <h4 className='fw-medium pb-1 ps-3'>{sect.titulo}</h4>
                      <h6 className='fw-lighter'>{sect.subtitulo}</h6>
                      <>
                        {
                          sect.docs.map((titt,c) => (
                            titt?.url ?
                            <ul className='list-group'>
                              <li className='list-group-item list-unstyled py-3 px-2' key={c}>
                                {/* <a className='ps-3' href={changeServer(object.servidor) + titt.url} target="_blank">{titt.nombreLink}</a> */}
                                <a className='ps-3' href={titt.url} target="_blank">{titt.nombreLink}</a>
                                  <ul>
                                    {
                                    titt.urldos ?
                                      titt.urldos.map((url2, l) => (
                                        <li className='list-unstyled py-1' key={l}>
                                          {/* <a href={changeServer(object.servidor) + url2.link} target='_blank'>{url2.descripcion}</a> */}
                                          <a href={url2.link} target='_blank'>{url2.descripcion}</a>
                                        </li>
                                        ))
                                      : null
                                    }
                                  </ul>
                              </li>
                            </ul>
                              :
                              titt.urltres ?
                            <div>
                              <ul className='list-group'>
                                <li className='list-group-item py-3'>
                                  <Link className='ps-1' to={titt.urltres}>{titt.nombreLink}</Link>
                                </li>
                              </ul>
                            </div>
                              :
                            <div>
                              <p className='ps-2'>{titt.nombreLink}</p>
                              <>
                                {
                                  titt.data.map((dat,t) => (
                                    <ul className='list-group'>
                                      <li className='list-group-item list-unstyled px-2 pb-2' key={t}>
                                        {/* <a className='mr-2' href={changeServer(sect.servidor) + dat.urlxlsx}><span className='badge bg-alert px-2'>.xlsx</span></a>
                                        <a href={changeServer(object.servidor) + dat.urlpdf} target="_blank"><span className='badge bg-primary px-2'>.pdf</span></a> */}
                                        <a className='mr-2' href={dat.urlxlsx}><span className='badge bg-alert px-2'>.xlsx</span></a>
                                        <a href={dat.urlpdf} target="_blank"><span className='badge bg-primary px-2'>.pdf</span></a>
                                      </li>
                                    </ul>
                                  ))
                                }
                              </>
                            </div>
                          ))
                        }
                      </>

                    </div>
                  ))
                }
              </ul>
            </div>
          </div>
        }
    </>
  )
}

export default ListLink