import { React } from 'react';
import { titularInversionesObject } from '../../json/Micrositios/UnidadInversiones/contenidoMicrositio';
import './micrositios.css';
import BotonMicrositios from './BtnMicrositios';

const Micrositios = ({object}) => {

  return (
    <>
        {
        object &&
        <>
            <BotonMicrositios />
            <div className='container mb-5'>
                <div className='row'>
                    {
                    titularInversionesObject.map((item, index) =>(
                        <>
                            <div key={index}>
                                <div className="pt-2">
                                    <h3>{ item.nombre }</h3>
                                    <h4 className='pb-5'>{ item.post }</h4>
                                </div>
                                {
                                    item.data.map((mat, ph)=>(
                                        <div key={ph}>
                                            <h4 className='fs-2 fw-medium'>{mat.titulo}</h4>
                                            <p className='text-justify lh-lg pt-3'>{mat.parrafo}</p>
                                            {
                                                mat.lista.map((roster, ls)=>(
                                                    <ul class="list-inline">
                                                        <li class="list-inline-item">{roster.datos}</li>
                                                    </ul>
                                                ))
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </>
                    ))
                    }
                </div>
            </div>
        </>
        }
    </>
  )
}

export default Micrositios