export const catalogosClavePrep2022Object = {
    'nombre': 'Catálogos de la Clave Presupuestaria 2022',
    'tipo': '2',
    'servidor': '1',
    'seccion': [
        {
            'titulo': '',
            'subtitulo': '',
            'docs': [
                {
                    'nombreLink':'Catálogo de Beneficiarios',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2022/CatalogosdelaClavePresupuestaria/Catalogo%20de%20Beneficiarios.pdf'
                },
                {
                    'nombreLink':'Catálogo de Ejes (Actualización PED 2016-2022)',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2022/CatalogosdelaClavePresupuestaria/Catalogo%20de%20Ejes%20(Actualizacion%20PED%202016-2022).pdf'
                },
                {
                    'nombreLink':'Catálogo de Entes',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2022/CatalogosdelaClavePresupuestaria/Catalogo%20de%20Entes%202022%20-%20actualizacion%2020sep21.pdf'
                },
                {
                    'nombreLink':'Clasificación Funcional del Gasto',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2022/CatalogosdelaClavePresupuestaria/Clasificacion%20Funcional%20del%20Gasto.pdf'
                },
                {
                    'nombreLink':'Clasificación por Espacio Geográfico',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2022/CatalogosdelaClavePresupuestaria/Clasificacion%20por%20Espacio%20Geografico.pdf'
                },
                {
                    'nombreLink':'Clasificación por Ramo',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2022/CatalogosdelaClavePresupuestaria/Clasificacion%20por%20Ramo.pdf'
                },
                {
                    'nombreLink':'Clasificación por Tipo de Gasto',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2022/CatalogosdelaClavePresupuestaria/Clasificacion%20por%20Tipo%20de%20Gasto.pdf'
                },
                {
                    'nombreLink':'Clasificación Programática',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2022/CatalogosdelaClavePresupuestaria/Clasificacion%20Programatica.pdf'
                },
                {
                    'nombreLink':'Clasificador por Objeto del Gasto',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2022/CatalogosdelaClavePresupuestaria/Clasificador%20por%20Objeto%20del%20Gasto.pdf'
                },
                {
                    'nombreLink':'Clasificador por Rubro de Ingresos (descargable)',
                    'url': '../../../files/servicios/xls/PaqueteHacendario/2022/CatalogosdelaClavePresupuestaria/Catalogo%20del%20Clasificador%20por%20Rubro%20de%20Ingreso%202022%20con%20fuente%20de%20financiamiento%20y%20LDF.15.10.2021.xlsx'
                },
                {
                    'nombreLink':'Indicadores Estratégicos',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2022/CatalogosdelaClavePresupuestaria/Indicadores%20Estrategicos.pdf'
                },
                {
                    'nombreLink':'Objetivos de Desarrollo Sostenible (ODS)',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2022/CatalogosdelaClavePresupuestaria/Objetivos%20de%20Desarrollo%20Sostenible%20(ODS).pdf'
                },
                {
                    'nombreLink':'Objetivos Estratégicos Transversales',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2022/CatalogosdelaClavePresupuestaria/Objetivos%20Estrategicos%20Transversales.pdf'
                },
                {
                    'nombreLink':'Políticas Sectoriales',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2022/CatalogosdelaClavePresupuestaria/Politicas%20Sectoriales.pdf'
                }
            ]
        }
    ]
}