import { BrowserRouter, Routes, Route, Switch } from "react-router-dom";
import Inicio from './components/Inicio/Inicio';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Transparencia from './components/Transparencia/Transparencia';
import TransparenciaSecretaria from './components/TransparenciaSecretaria/TransparenciaSecretaria';
import Rubros from './components/Rubros/Rubros';
import DetalleRubro from './components/DetalleRubro/DetalleRubro';
import RubrosHistoricos from "./components/RubrosHistoricos/RubrosHistoricos";
import Agenda from "./components/Agenda/Agenda";
import Error404 from "./components/Error404/Error404";
import Armonizacion from './components/Armonizacion/Armonizacion';
import ArmonizacionDetalle from './components/Armonizacion/ArmonizacionDetalle';
import Servicios from "./components/Servicios/Servicios";
import DetalleServiciosScreen from "./screens/DetalleServicios/DetalleServiciosScreen"
import DetalleServiciosInternosScreen from "./screens/DetalleServiciosInternos/DetalleServiciosInternosScreen";
import PaqueteHacendario from "./components/PaqueteHacendario/PaqueteHacendario";
import InformedeResultadosCuentaPublica from "./components/TransparenciaSecretaria/InformedeResultadosCuentaPublica/InformedeResultadosCuentaPublica";
import FormatosDisciplinaFinanciera from "./components/TransparenciaSecretaria/FormatosDisciplinaFinanciera/FormatosDisciplinaFInanciera"
import DetalleFramesObjects from "./screens/DetalleFramesSH/DetalleFramesObjects";
import IHDEP from "./components/IHDEP/IHDEP";
import InformeHaciendaPublica from "./components/InformeHaciendaPublica/InformeHaciendaPublica";
import AsociacionesPublicoPrivadas from "./components/Servicios/AsociacionesPublicoPrivadas/AsociacionesPublicoPrivadas"
import RegistrodeProyectosdeInversion from "./components/Servicios/AsociacionesPublicoPrivadas/RegistrodeProyectosdeInversion";
import Micrositios from "./components/ReusableComponents/Micrositios";
import DetalleMicrositiosScreen from "./screens/DetalleMicrositios/DetalleMicrositiosScreen";


function App() {
  return (
    <BrowserRouter>
      <Header/>
        <Routes>
          <Route exact path="/" element={ <Inicio /> } />
          <Route exact path="/InformedeResultadosCuentaPublica" element={ <InformedeResultadosCuentaPublica/> } />
          <Route exact path="/FormatosDisciplinaFinanciera" element={ <FormatosDisciplinaFinanciera />} />
          <Route exact path="/TransparenciaSecretaria" element={ <TransparenciaSecretaria/> } />
          <Route exact path="/Transparencia" element={ <Transparencia/> } />
          <Route exact path="/Transparencia/Rubros" element={ <Rubros/> } />
          <Route exact path="/Transparencia/Rubros/DetalleRubro/:nombre" element={ <DetalleRubro/> } />
          <Route exact path="/Transparencia/Rubros/RubrosHistoricos" element={ <RubrosHistoricos/> } />
          <Route exact path="/Agenda" element={ <Agenda/> } />
          <Route exact path="/PaqueteHacendario" element={ <PaqueteHacendario/> } />
          {/* <Route exact path="/Armonizacion" element={ <Armonizacion/> } />
          <Route exact path="/Armonizacion/DetalleArmonizacion/:children" element={ <ArmonizacionDetalle/> } /> */}
          <Route exact path="/Armonizacion/:nombre" element={ <DetalleFramesObjects /> } />
          <Route exact path="/Secretaría/:nombre" element={ <DetalleFramesObjects /> } />
          <Route exact path="/Inicio/:nombre" element={ <DetalleFramesObjects /> } />
          <Route exact path="/Servicios" element={ <Servicios/> } />
          <Route exact path="/Servicios/:nombre" element={ <DetalleServiciosScreen/> } />
          <Route exact path="/Servicios/:nombre/ServiciosInternos/:nombre" element={ <DetalleServiciosInternosScreen/> } />
          <Route exact path="/InformeHaciendaPublica" element={ <InformeHaciendaPublica /> } />
          <Route exact path="/IHDEP" element={ <IHDEP/> } />
          <Route exact path="/AsociacionesPublicoPrivadas" element={ <AsociacionesPublicoPrivadas/> } />
          <Route exact path="/RegistrodeProyectosdeInversion" element={ <RegistrodeProyectosdeInversion/> } />
          <Route exact path="/Micrositios" element={ <Micrositios/> } />
          <Route exact path="/Micrositios/:nombre" element={ <DetalleMicrositiosScreen/> } />
          <Route exact path="*" element={<Error404 /> } />
        </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
