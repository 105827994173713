import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './transparencia.css';
import { transparencia_item } from '../../json/transparenciaItem';

const Transparencia = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='container transparencia-container'>
        <div className="pt-5">
            <div className='row'>
                {
                    transparencia_item.map((item, index) => (
                        <>
                            <div key={ index } className='col-12'>
                                <div className='transparencia_title'>
                                    <h1>{item.titulo}</h1>
                                </div>
                            </div>
                            {
                                item.rubros.map((item, r) => (
                                    <div key={ r }>
                                        <h3 className='title'>{item.subtitulo}</h3>
                                        <hr className='hr-gob'/>
                                        <div className='row'>
                                        {
                                            item.data.map((item, d) => (
                                                <div key={ d } className='col-md-4 col-sm-6 transparencia_item pb-5'>
                                                    <Link to={ item.transparencia_item_url }>{ item.transparencia_item_nombre }</Link>
                                                </div>
                                            ))
                                        }
                                        </div>
                                    </div>
                                ))
                            }
                        </>
                    ))
                }
            </div>
        </div>
    </div>
  )
}

export default Transparencia