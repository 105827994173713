import React from 'react'
import { registroProyectosInvArray } from '../../../json/Servicios/registroProyInv';
import DynamicBreadcrumb from '../../ReusableComponents/Breadcrumb'

const RegistrodeProyectosdeInversion = () => {

    const breadcrumbItems = [
        { label: 'Inicio', link: 'https://hacienda.hidalgo.gob.mx/' },
        { label: 'Unidad de Inversiones', link: 'https://hacienda.hidalgo.gob.mx/Micrositios/UnidaddeInversiones' },
        { label: 'Registro de Proyectos de Inversión' },
      ];

      return (
        <>
            <div className='container pt-5'>
                <div className='row'>
                    <DynamicBreadcrumb items={breadcrumbItems} />
                    <div>
                        <h3 className='title mt-5 pt-5'>Registro de Proyectos de Inversión</h3>
                        <hr className='hr-gob' />
                    </div>
                    <div class="alert alert-primary d-flex align-items-center" role="alert">
                        <span className="pe-3"><i class="bi bi-exclamation-circle"></i></span>
                        <div>
                            Análisis Costo Beneficio para Programas o proyectos de inversión mayores a 10 millones de UDIS
                        </div>
                    </div>
                    <div className="accordion accordion-flush px-0" id="accordionExample">
                        {
                            registroProyectosInvArray.map((acor, co) => (
                                <div className="accordion-item my-3" key={co}>
                                    <h2 className="accordion-header border-bottom">
                                        <button
                                            className="accordion-button py-4"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#collapse_${co}`}
                                            aria-expanded="false"
                                            aria-controls={`collapse_${co}`}
                                        >
                                            {acor.titulo}
                                        </button>
                                    </h2>
                                    <div
                                        id={`collapse_${co}`}
                                        className="accordion-collapse collapse"
                                        data-bs-parent="#accordionExample"
                                    >
                                        {
                                            acor.data.map((cont, ni) => (
                                                cont.url ?
                                                <div className="accordion-body" key={ni}>
                                                    <ul>
                                                        <li>
                                                            <a className='ps-2' href={cont.url} target="_blank" rel="noopener noreferrer">
                                                                {cont.nombre}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>:
                                                <div className='px-4'>
                                                    <p className='text-justify lh-lg pt-3'>{cont.texto}</p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}
    

export default RegistrodeProyectosdeInversion