export const informacionFinsrftObject = 
    {
        'nombre': 'Información Financiera SRFT',
        'tipo': '1',
        'servidor': '1',
        'seccion': [
            {   
                'id': '0',
                'docs': [
                    {
                        'titulo': '2024',
                        'data': [
                            {
                                'trimestre': 'Tercer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2024/Hidalgo_Ejercicio del Gasto_Tercer Timestre 2024.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2024/Hidalgo_Destino del Gasto_Tercer Timestre 2024.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2024/Hidalgo_Indicadores de Ramo 33_Tercer Trimestre 2024.zip'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Segundo Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2024/Hidalgo_Ejercicio del Gasto_Segundo Trimestre 2024.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2024/Hidalgo_Destino del Gasto_Segundo Trimestre 2024.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2024/Hidalgo_Indicadores de Ramo 33_Segundo Trimestre 2024.zip'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Primer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2024/Hidalgo_Ejercicio%20del%20Gasto_Primer%20Trimestre%202024.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2024/Hidalgo_Destino%20del%20Gasto_Primer%20Trimestre%202024.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2024/Hidalgo_Indicadores%20de%20Ramo%2033_Primer%20Trimestre%202024.zip'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Formatos 2024',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Formato del Ejercicio Destino Resultados del Gasto Federalizado y Reintegros Ministración de Recursos a través de la SH_FMSH',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2024/Formato%20del%20Ejercicio%20Destino%20Resultados%20del%20Gasto%20Federalizado%20y%20Reintegros%20Ministracion%20de%20Recursos%20a%20traves%20de%20la%20SH_FMSH.xlsx'
                                    },
                                    {
                                        'nombre': 'Formato del Ejercicio Destino Resultados del Gasto Federalizado y Reintegros Ministración de Recursos Directos_FMD',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2024/Formato%20del%20Ejercicio%20Destino%20Resultados%20del%20Gasto%20Federalizado%20y%20Reintegros%20Ministracion%20de%20Recursos%20Directos_FMD.xlsx'
                                    },
                                    {
                                        'nombre': 'Formato Destino del Gasto y Ejercicio del Gasto_FDG y FEG',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2024/Formato%20Destino%20del%20Gasto%20y%20Ejercicio%20del%20Gasto_FDG%20y%20FEG.xlsx'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Calendario 2024',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Atención a Entes Públicos para validación de información reportada en el SRFT de la S.H.C.P.',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2024/Calendario%20de%20Atencion%20a%20Entes%20Publicos%20para%20validacion%20de%20informacion%20reportada%20en%20el%20SRFT%20de%20la%20SHCP.docx'
                                    },
                                    {
                                        'nombre': 'Registro de Avances en el SRFT de la S.H.C.P.',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2024/Calendario%20para%20el%20Registro%20de%20Avances%20en%20el%20SRFT%20de%20la%20SHCP.docx'
                                    },
                                    {
                                        'nombre': 'Publicación en el Periódico Oficial del Estado de Hidalgo y en las páginas electrónicas de internet de los Entes Públicos',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2024/Calendario%20para%20la%20Publicacion%20en%20el%20Periodico%20Oficial%20del%20Estado%20de%20Hidalgo%20y%20en%20las%20paginas%20electronicas%20de%20internet%20de%20los%20Entes%20Publicos.docx'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Formatos Veda 2024',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Formato del Ejercicio Destino Resultados del Gasto Federalizado y Reintegros Ministración de Recursos a través de la SH_FMSH',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2024/Formatos%20Veda/Formato%20del%20Ejercicio%20Destino%20Resultados%20del%20Gasto%20Federalizado%20y%20Reintegros%20Ministracion%20de%20Recursos%20a%20traves%20de%20la%20SH_FMSH.xlsx'
                                    },
                                    {
                                        'nombre': 'Formato del Ejercicio Destino Resultados del Gasto Federalizado y Reintegros Ministración de Recursos Directos_FMD',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2024/Formatos%20Veda/Formato%20del%20Ejercicio%20Destino%20Resultados%20del%20Gasto%20Federalizado%20y%20Reintegros%20Ministracion%20de%20Recursos%20Directos_FMD.xlsx'
                                    },
                                    {
                                        'nombre': 'Formato Destino del Gasto y Ejercicio del Gasto_FDG y FEG',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2024/Formatos%20Veda/Formato%20Destino%20del%20Gasto%20y%20Ejercicio%20del%20Gasto_FDG%20y%20FEG.xlsx'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Calendario Veda 2024',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Atención a Entes Públicos para validación de información reportada en el SRFT de la S.H.C.P.',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2024/Calendarios%20Veda/Calendario%20de%20Atencion%20a%20Entes%20Publicos%20para%20validacion%20de%20informacion%20reportada%20en%20el%20SRFT%20de%20la%20SHCP.docx'
                                    },
                                    {
                                        'nombre': 'Registro de Avances en el SRFT de la S.H.C.P.',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2024/Calendarios%20Veda/Calendario%20para%20el%20Registro%20de%20Avances%20en%20el%20SRFT%20de%20la%20SHCP.docx'
                                    },
                                    {
                                        'nombre': 'Publicación en el Periódico Oficial del Estado de Hidalgo y en las páginas electrónicas de internet de los Entes Públicos',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2024/Calendarios%20Veda/Calendario%20para%20la%20Publicacion%20en%20el%20Periodico%20Oficial%20del%20Estado%20de%20Hidalgo%20y%20en%20las%20paginas%20electronicas%20de%20internet%20de%20los%20Entes%20Publicos.docx'
                                    }
                                ]
                            }
                        ]   
                    }
                ]
            },
            {   
                'id': '1',
                'docs': [
                    {
                        'titulo': '2023',
                        'data': [
                            {
                                'trimestre': 'Cuarto Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2023/Hidalgo%20Ejercicio%20del%20Gasto%20Cuarto%20Trimestre%202023.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2023/Hidalgo%20Destino%20del%20Gasto%20Cuarto%20Trimestre%202023.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2023/Hidalgo%20Indicadores%20de%20Ramo%2033%20Cuarto%20Trimestre%202023.rar'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Tercer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2023/Hidalgo_Ejercicio%20del%20Gasto_Tercer%20Trimestre%202023.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2023/Hidalgo_Destino%20del%20Gasto_Tercer%20Trimestre%202023.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2023/Hidalgo_Indicadores%20de%20Ramo%2033_Tercer%20Trimestre%202023.rar'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Segundo Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2023/Hidalgo_Ejercicio%20del%20Gasto_Segundo%20Trimestre%202023.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2023/Hidalgo_Destino%20del%20Gasto_Segundo%20Trimestre%202023.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2023/Hidalgo_Indicadores%20de%20Ramo%2033_Segundo%20Trimestre%202023.zip'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Primer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2023/Hidalgo_Ejercicio%20del%20Gasto_Primer%20Trimestre%202023.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2023/Hidalgo_Destino%20del%20Gasto_Primer%20Trimestre%202023.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2023/Hidalgo_Indicadores%20de%20Ramo%2033_Primer%20Trimestre%202023.rar'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Informe Definitivo 2023',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2023/Hidalgo_Ejercicio del Gasto_Informe Definitivo 2023.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2023/Hidalgo_Destino del Gasto_Informe Definitivo 2023.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2023/Hidalgo_Indicadores de Ramo 33_Informe Definitivo 2023.zip'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Formatos 2023 - Secretaría de Hacienda - Dirección General de Tesorería',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Formato del Ejercicio, Destino, Resultados del Gasto Federalizado y Reintegros (FMSH)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2023/Formatos%20DGT/Formato%20del%20Ejercicio%20Destino%20Resultados%20del%20Gasto%20Federalizado%20y%20Reintegros%20Ministracion%20de%20Recursos%20a%20traves%20de%20la%20SH.xlsx'
                                    },
                                    {
                                        'nombre': 'Formato del Ejercicio, Destino, Resultados del Gasto Federalizado y Reintegros (FMD)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2023/Formatos%20DGT/Formato%20del%20Ejercicio%20Destino%20Resultados%20del%20Gasto%20Federalizado%20y%20Reintegros%20Ministracion%20de%20Recursos%20Directos.xlsx'
                                    },
                                    {
                                        'nombre': 'Formato Destino del Gasto (FDG) y Ejercicio del Gasto (FEG)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2023/Formatos%20DGT/Formato%20Destino%20del%20Gasto%20y%20Ejercicio%20del%20Gasto.xlsx'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Formatos 2023',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Formato del Ejercicio, Destino, Resultados del Gasto Federalizado y Reintegros (FMSH) (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2023/Formato%20del%20Ejercicio%20Destino%20Resultados%20del%20Gasto%20Federalizado%20y%20Reintegros%20Ministracion%20de%20Recursos%20a%20traves%20de%20la%20SH.xlsx'
                                    },
                                    {
                                        'nombre': 'Formato Destino del Gasto y Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2023/Formato%20Destino%20del%20Gasto%20y%20Ejercicio%20del%20Gasto.xlsx'
                                    },
                                    {
                                        'nombre': 'Formato del Ejercicio, Destino, Resultados del Gasto Federalizado y Reintegros (FMD) (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2023/Formato%20del%20Ejercicio%20Destino%20Resultados%20del%20Gasto%20Federalizado%20y%20Reintegros%20Ministracion%20de%20Recursos%20Directos.xlsx'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Calendarios 2023 - Secretaría de Hacienda - Dirección General de Tesorería',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Atención a Entes Públicos para validación de información reportada en el SRFT de la S.H.C.P.',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2023/Calendarios%20DGT/Calendario%20de%20Atencion%20a%20Entes%20Publicos%20para%20validacion%20de%20informacion%20reportada%20en%20el%20SRFT%20de%20la%20SHCP.docx'
                                    },
                                    {
                                        'nombre': 'Registro de Avances en el SRFT de la S.H.C.P.',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2023/Calendarios%20DGT/Calendario%20para%20el%20Registro%20de%20Avances%20en%20el%20SRFT%20de%20la%20SHCP.docx'
                                    },
                                    {
                                        'nombre': 'Publicación en el Periódico Oficial del Estado de Hidalgo y en las páginas electrónicas de internet de los Entes Públicos',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2023/Calendarios%20DGT/Calendario%20para%20la%20Publicacion%20en%20el%20Periodico%20Oficial%20del%20Estado%20de%20Hidalgo%20y%20en%20las%20paginas%20electronicas%20de%20internet%20de%20los%20Entes%20Publicos.docx'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Calendario 2023',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Calendario de Atención a Entes Públicos para validación de información reportada en el SRFT de la SHCP',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2023/Calendario%20de%20Atencion%20a%20Entes%20Publicos%20para%20validacion%20de%20informacion%20reportada%20en%20el%20SRFT%20de%20la%20SHCP.docx'
                                    },
                                    {
                                        'nombre': 'Calendario para el Registro de Avances en el SRFT de la SHCP',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2023/Calendario%20para%20el%20Registro%20de%20Avances%20en%20el%20SRFT%20de%20la%20SHCP.docx'
                                    },
                                    {
                                        'nombre': 'Calendario para la Publicación en el Periódico Oficial del Estado de Hidalgo y en las páginas electrónicas de internet de los Entes Públicos',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2023/Calendario%20para%20la%20Publicacion%20en%20el%20Periodico%20Oficial%20del%20Estado%20de%20Hidalgo%20y%20en%20las%20paginas%20electronicas%20de%20internet%20de%20los%20Entes%20Publicos.docx'
                                    }
                                ]
                            }
                        ]   
                    }
                ]
            },
            {   
                'id': '2',
                'docs': [
                    {
                        'titulo': '2022',
                        'data': [
                            {
                                'trimestre': 'Cuarto Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2022/Hidalgo_Ejercicio%20del%20Gasto_Cuarto%20Trimestre%202022.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2022/Hidalgo_Destino%20del%20Gasto_Cuarto%20Trimestre%202022.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2022/Hidalgo_Indicadores%20de%20Ramo%2033_Cuarto%20Trimestre%202022.rar'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Tercer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': 'https://efinanzas.hidalgo.gob.mx/SIAUUV2/pdf/Hidalgo_Ejercicio%20del%20Gasto_Tercer%20Trimestre%202022.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': 'https://efinanzas.hidalgo.gob.mx/SIAUUV2/pdf/Hidalgo_Destino%20del%20Gasto_Tercer%20Trimestre%202022.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': 'https://efinanzas.hidalgo.gob.mx/SIAUUV2/pdf/Hidalgo_Indicadores%20de%20Ramo%2033_Tercer%20Trimestre%202022.rar'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Segundo Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2022/Ejercicio%20del%20Gasto_Segundo%20Trimestre%202022.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2022/Destino%20del%20Gasto_Segundo%20Trimestre%202022.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2022/Indicadores%20de%20Ramo%2033_Segundo%20Trimestre%202022.zip'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Primer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2022/Ejercicio%20del%20Gasto_Primer%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2022/Destino%20del%20Gasto_Primer%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2022/Indicadores%20de%20Ramo%2033_Primer%20Trimestre.rar'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Informe definitivo 2022',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2022/Hidalgo_Ejercicio%20del%20Gasto_Informe%20Definitivo%202022.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2022/Hidalgo_Destino%20del%20Gasto_Informe%20Definitivo%202022.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2022/Hidalgo_Indicadores%20de%20Ramo%2033_Informe%20Definitivo%202022.zip'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Formatos 2022',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Formato del Ejercicio, Destino, Resultados del Gasto Federalizado y Reintegros (FMSH) (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2022/Formato%20del%20Ejercicio%20Destino%20Resultados%20del%20Gasto%20Federalizado%20y%20Reintegros%20Ministracion%20de%20Recursos%20a%20traves%20de%20la%20SFP.xlsx'
                                    },
                                    {
                                        'nombre': 'Formato Destino del Gasto y Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2022/Formato%20Destino%20del%20Gasto%20y%20Ejercicio%20del%20Gasto.xlsx'
                                    },
                                    {
                                        'nombre': 'Formato del Ejercicio, Destino, Resultados del Gasto Federalizado y Reintegros (FMD) (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2022/Formato%20del%20Ejercicio%20Destino%20Resultados%20del%20Gasto%20Federalizado%20y%20Reintegros%20Ministracion%20de%20Recursos%20Directos.xlsx'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Formatos 2022 - Nueva administración',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (FEG) y Destino del Gasto (FDG)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2022/nuevaAdministracion/Formato%20Destino%20del%20Gasto%20y%20Ejercicio%20del%20Gasto.xlsx'
                                    },
                                    {
                                        'nombre': 'Ejercicio, Destino, Resultados del Gasto Federalizado y Reintegros (FMSFP)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2022/nuevaAdministracion/Formato%20del%20Ejercicio%20Destino%20Resultados%20del%20Gasto%20Federalizado%20y%20Reintegros_Ministracion%20de%20Recursos%20a%20traves%20de%20la%20SFP.xlsx'
                                    },
                                    {
                                        'nombre': 'Ejercicio, Destino, Resultados del Gasto Federalizado y Reintegros (FMD)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2022/nuevaAdministracion/Formato%20del%20Ejercicio%20Destino%20Resultados%20del%20Gasto%20Federalizado%20y%20Reintegros_Ministracion%20de%20Recursos%20Directos.xlsx'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Calendario 2022',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Calendario de Atención a Entes Públicos para validación de información reportada en el SRFT de la SHCP',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2022/Calendario%20de%20Atencion%20a%20Entes%20Publicos%20para%20validacion%20de%20informacion%20reportada%20en%20el%20SRFT%20de%20la%20SHCP.docx'
                                    },
                                    {
                                        'nombre': 'Calendario para el Registro de Avances en el SRFT de la SHCP',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2022/Calendario%20para%20el%20Registro%20de%20Avances%20en%20el%20SRFT%20de%20la%20SHCP.docx'
                                    },
                                    {
                                        'nombre': 'Calendario para la Publicación en el Periódico Oficial del Estado de Hidalgo y en las páginas electrónicas de internet de los Entes Públicos',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2022/Calendario%20para%20la%20Publicacion%20en%20el%20Periodico%20Oficial%20del%20Estado%20de%20Hidalgo%20y%20en%20las%20paginas%20electronicas%20de%20internet%20de%20los%20Entes%20Publicos.docx'
                                    }
                                ]
                            }
                        ]   
                    }
                ]
            },
            {   
                'id': '3',
                'docs': [
                    {
                        'titulo': '2021',
                        'data': [
                            {
                                'trimestre': 'Cuarto Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2021/Ejercicio%20del%20Gasto_Cuarto%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2021/Destino%20del%20Gasto_Cuarto%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2021/Indicadores%20de%20Ramo%2033_Cuarto%20Trimestre.rar'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Tercer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2021/Ejercicio%20del%20Gasto_Tercer%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2021/Destino%20del%20Gasto_Tercer%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2021/Indicadores%20de%20Ramo%2033_Tercer%20Trimestre.rar'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Segundo Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2021/Ejercicio%20del%20Gasto_Segundo%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2021/Destino%20del%20Gasto_Segundo%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2021/Indicadores%20de%20Ramo%2033_Segundo%20Trimestre.zip'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Primer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2021/Ejercicio%20del%20Gasto_Primer%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2021/Destino%20del%20Gasto_Primer%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2021/Indicadores%20de%20Ramo%2033_Primer%20Trimestre.rar'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Informe definitivo 2021',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2021/Ejercicio%20del%20Gasto_Informe%20Definitivo%202021.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2021/Destino%20del%20Gasto_Informe%20Definitivo%202021.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2021/Indicadores%20de%20Ramo%2033_Informe%20Definitivo%202021.zip'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Formatos 2021',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Formato del Ejercicio, Destino, Resultados del Gasto Federalizado y Reintegros (FMSH) (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2021/Formato%20del%20Ejercicio%20Destino%20Resultados%20del%20Gasto%20Federalizado%20y%20Reintegros%20Ministracion%20de%20Recursos%20a%20traves%20de%20la%20SFP.xlsx'
                                    },
                                    {
                                        'nombre': 'Formato Destino del Gasto y Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2021/Formato%20Destino%20del%20Gasto%20y%20Ejercicio%20del%20Gasto.xlsx'
                                    },
                                    {
                                        'nombre': 'Formato del Ejercicio, Destino, Resultados del Gasto Federalizado y Reintegros (FMD) (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2021/Formato%20del%20Ejercicio%20Destino%20Resultados%20del%20Gasto%20Federalizado%20y%20Reintegros%20Ministracion%20de%20Recursos%20Directos.xlsx'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Calendario 2021',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Calendario de Atención a Entes Públicos para validación de información reportada en el SRFT de la SHCP',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2021/Calendario%20de%20Atencion%20a%20Entes%20Publicos%20para%20validacion%20de%20informacion%20reportada%20en%20el%20SRFT%20de%20la%20SHCP.docx'
                                    },
                                    {
                                        'nombre': 'Calendario para el Registro de Avances en el SRFT de la SHCP',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2021/Calendario%20para%20el%20Registro%20de%20Avances%20en%20el%20SRFT%20de%20la%20SHCP.docx'
                                    },
                                    {
                                        'nombre': 'Calendario para la Publicación en el Periódico Oficial del Estado de Hidalgo y en las páginas electrónicas de internet de los Entes Públicos',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2021/Calendario%20para%20la%20Publicacion%20en%20el%20Periodico%20Oficial%20del%20Estado%20de%20Hidalgo%20y%20en%20las%20paginas%20electro%CC%81nicas%20de%20internet%20de%20los%20Entes%20Publicos.docx'
                                    }
                                ]
                            }
                        ]   
                    }
                ]
            },
            {   
                'id': '4',
                'docs': [
                    {
                        'titulo': '2020',
                        'data': [
                            {
                                'trimestre': 'Cuarto Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2020/Ejercicio%20del%20Gasto_Cuarto%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2020/Destino%20del%20Gasto_Cuarto%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2020/Indicadores%20de%20Ramo%2033_Cuarto%20Trimestre.rar'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Tercer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2020/Ejercicio%20del%20Gasto_Tercer%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2020/Destino%20del%20Gasto_Tercer%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2020/Indicadores%20de%20Ramo%2033_Tercer%20Trimestre.rar'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Segundo Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2020/Ejercicio%20del%20Gasto_Segundo%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2020/Destino%20del%20Gasto_Segundo%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2020/Indicadores%20de%20Ramo%2033_Segundo%20Trimestre.zip'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Primer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2020/Ejercicio%20del%20Gasto_Primer%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2020/Destino%20del%20Gasto_Primer%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2020/Indicadores%20de%20Ramo%2033_Primer%20Trimestre.rar'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Informe definitivo 2020',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2020/Ejercicio%20del%20Gasto_Informe%20Definitivo.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2020/Destino%20del%20Gasto_Informe%20Definitivo.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2020/Indicadores%20de%20Ramo%2033_Informe%20Definitivo.zip'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Formatos 2020',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Formato del Ejercicio, Destino, Resultados del Gasto Federalizado y Reintegros (FMSH) (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2020/Formato%20del%20Ejercicio,%20Destino,%20Resultados%20del%20Gasto%20Federalizado%20y%20Reintegros%20Ministracion%20de%20Recursos%20a%20traves%20de%20la%20SFP.xlsx'
                                    },
                                    {
                                        'nombre': 'Formato Destino del Gasto y Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2020/Formato%20Destino%20del%20Gasto%20y%20Ejercicio%20del%20Gasto.xlsx'
                                    },
                                    {
                                        'nombre': 'Formato del Ejercicio, Destino, Resultados del Gasto Federalizado y Reintegros (FMD) (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2020/Formato%20del%20Ejercicio,%20Destino,%20Resultados%20del%20Gasto%20Federalizado%20y%20Reintegros%20Ministracion%20de%20Recursos%20Directos.xlsx'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Calendario 2020',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Calendario de Atención a Entes Públicos para validación de información reportada en el SRFT de la SHCP',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2020/Calendario%20de%20Atencion%20a%20Entes%20Publicos%20para%20validacion%20de%20informacion%20reportada%20en%20el%20SRFT%20de%20la%20SHCP.docx'
                                    },
                                    {
                                        'nombre': 'Calendario para el Registro de Avances en el SRFT de la SHCP',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2020/Calendario%20para%20el%20Registro%20de%20Avances%20en%20el%20SRFT%20de%20la%20SHCP.docx'
                                    },
                                    {
                                        'nombre': 'Calendario para la Publicación en el Periódico Oficial del Estado de Hidalgo y en las páginas electrónicas de internet de los Entes Públicos',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2020/Calendario%20para%20la%20Publicacion%20en%20el%20Periodico%20Oficial%20del%20Estado%20de%20Hidalgo%20y%20en%20las%20p%C2%A0ginas%20electronicas%20de%20internet%20de%20los%20Entes%20Publicos.docx'
                                    }
                                ]
                            }
                        ]   
                    }
                ]
            },
            {   
                'id': '5',
                'docs': [
                    {
                        'titulo': '2019',
                        'data': [
                            {
                                'trimestre': 'Cuarto Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2019/Ejercicio%20del%20Gasto_Cuarto%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2019/Destino%20del%20Gasto_Cuarto%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2019/Indicadores%20de%20Ramo%2033_Cuarto%20Trimestre.zip'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Tercer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2019/Ejercicio%20del%20Gasto_Tercer%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2019/Destino%20del%20Gasto_Tercer%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2019/Indicadores%20de%20Ramo%2033_Tercer%20Trimestre.zip'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Segundo Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2019/Ejercicio%20del%20Gasto_Segundo%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2019/Destino%20del%20Gasto_Segundo%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2019/Indicadores%20de%20Ramo%2033_Segundo%20Trimestre.zip'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Primer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2019/Ejercicio%20del%20Gasto_Primer%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2019/Destino%20del%20Gasto_Primer%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2019/Indicadores%20de%20Ramo%2033_Primer%20Trimestre.zip'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Informe definitivo 2019',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2019/Ejercicio%20del%20Gasto_Informe%20Definitivo.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2019/Destino%20del%20Gasto_Informe%20Definitivo.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2019/Indicadores%20de%20Ramo%2033_Informe%20Definitivo.zip'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Formatos 2019',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Formato del Ejercicio Destino Resultados del Gasto Federalizado y Reintegros (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2019/Formato%20del%20Ejercicio%20Destino%20Resultados%20del%20Gasto%20Federalizado%20y%20Reintegros.xlsx'
                                    },
                                    {
                                        'nombre': 'Formato del Ejercicio Destino Resultados del Gasto Federalizado y Reintegros (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2019/Formato%20Destino%20del%20Gasto%20y%20Ejercicio%20del%20Gasto.xlsx'
                                    },
                                    {
                                        'nombre': 'Formato Ministración de Recursos Directos de la SHCP (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2019/Formato%20Ministracion%20de%20Recursos%20Directos%20de%20la%20SHCP.xlsx'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Calendario 2019',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Atención a Entes Públicos para validación de información reportada en el SRFT (descargable word)',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2019/Calendario%20de%20Atencion%20a%20Entes%20Publicos%20para%20validacion%20de%20informacion%20reportada%20en%20el%20SRFT%20de%20la%20SHCP.docx'
                                    },
                                    {
                                        'nombre': 'Registro de Avances en el Sistema de Recursos Federales Transferidos (SRFT) de la S.H.C.P. (descargable word)',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2019/Calendario%20para%20el%20Registro%20de%20Avances%20en%20el%20SRFT%20de%20la%20SHCP.docx'
                                    },
                                    {
                                        'nombre': 'Publicación en el Periódico Oficial del Estado de Hidalgo (descargable word)',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2019/Calendario%20para%20la%20Publicacion%20en%20el%20Periodico%20Oficial%20del%20Estado%20de%20Hidalgo%20y%20en%20las%20Paginas%20Electronicas%20de%20Internet%20de%20los%20Entes%20Publicos.docx'
                                    }
                                ]
                            }
                        ]   
                    }
                ]
            },
            {   
                'id': '6',
                'docs': [
                    {
                        'titulo': '2018',
                        'data': [
                            {
                                'trimestre': 'Cuarto Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2018/Ejercicio%20del%20Gasto_Cuarto%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2018/Destino%20del%20Gasto_Cuarto%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2018/Indicadores%20de%20Ramo%2033_Cuarto%20Trimestre.zip'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Tercer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2018/Ejercicio%20del%20Gasto_Tercer%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2018/Destino%20del%20Gasto_Tercer%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2018/Indicadores%20de%20Ramo%2033_Tercer%20Trimestre.zip'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Informe definitivo 2018',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2018/Ejercicio%20del%20Gasto_Informe%20Definitivo.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2018/Destino%20del%20Gasto_Informe%20Definitivo.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2018/Indicadores%20de%20Ramo%2033_Informe%20Definitivo.zip'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Formatos 2018',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Formato del Ejercicio Destino Resultados del Gasto Federalizado y Reintegros (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2018/Formato%20del%20Ejercicio,%20Destino%20y%20Resultado%20del%20Gasto%20Federalizado%20y%20Reintegros.xlsx'
                                    },
                                    {
                                        'nombre': 'Formato Destino del Gasto y Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2018/Formato%20Destino%20del%20Gasto%20y%20Ejercicio%20del%20Gasto.xlsx'
                                    },
                                    {
                                        'nombre': 'Formato Ministración de Recursos Directos de la SHCP (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/Formatos/2018/Formato%20Ministracion%20de%20Recursos%20Directos%20de%20la%20S.H.C.P.xlsx'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Calendario 2018',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Atención a Entes Públicos para validación de información reportada en el SRFT (descargable word)',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2018/Calendario%20de%20Atencion%20a%20Entes%20Publicos%20para%20validacion%20de%20informacion%20reportada%20en%20el%20SRFT%20de%20la%20SHCP.docx'
                                    },
                                    {
                                        'nombre': 'Registro de Avances en el Sistema de Recursos Federales Transferidos (SRFT) de la S.H.C.P. (descargable word)',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2018/Calendario%20para%20el%20Registro%20de%20Avances%20en%20el%20SRFT%20de%20la%20SHCP..docx'
                                    },
                                    {
                                        'nombre': 'Publicación en el Periódico Oficial del Estado de Hidalgo (descargable word)',
                                        'url': '../files/servicios/docx/InformacionFinancieraSRFT/Calendarios/2018/Calendario%20para%20la%20Publicacion%20en%20el%20Periodico%20Oficial%20del%20Estado%20de%20Hidalgo%20y%20en%20las%20Paginas%20Electronicas%20de%20Internet%20de%20los%20Entes%20Publicos.docx'
                                    }
                                ]
                            }
                        ]   
                    }
                ]
            }
        ]
    }