import React from 'react';
import { Breadcrumb } from 'react-bootstrap';

const DynamicBreadcrumb = ({ items }) => {
  return (
        <Breadcrumb>
        {items.map((item, index) => (
            
                <Breadcrumb.Item
                key={index}
                active={index === items.length - 1} 
                >
                {index === items.length - 1 ? (
                    item.label 
                ) : (
                    <a href={item.link}>{item.label}</a>
                )}
                </Breadcrumb.Item>
            
        ))}
        </Breadcrumb>
  );
};

export default DynamicBreadcrumb;