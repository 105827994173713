import React from 'react';
import { formatosDisciplinaFin } from '../../../json/Transparencia/formatosDisciplinaFinanciera';

const FormatosDisciplinaFinanciera = () => {
  return (
    <>
    <div className='container pt-5'>
      <div className='row'>
        {
          formatosDisciplinaFin.map((detail, l) => (
            <>
              <div key={l}>
                  <h3 className='title mt-5 pt-5'>{ detail.nombre }</h3>
                  <hr className='hr-gob'/>
              </div>
              
              <div>
                <div className="accordion accordion-flush px-0 mb-5" id="accordionExample">
                  {
                    detail.anio.map((age, e) => (
                      <div className="accordion-item" key={e}>
                        <h4 className="accordion-header py-1" id="headingOne">
                          <button className="accordion-button collapsed py-3 fs-6 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse_" + e + "_" + l} aria-expanded="true" aria-controls={"#collapse_" + e + "_" + l}>
                            { age.ejercicio }
                          </button>
                        </h4>
                        {
                          age.data.map((data, a) => (
                            <div id={"collapse_" + e + "_" + l} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample" key={a}>
                              <div className="accordion-body">

                                <div className="card mx-2">
                                  <div className="card-header fs-5 fw-semibold d-flex justify-content-center">{ data.trimestre }</div>
                                  {
                                    data.detalle.map((item, m) => (
                                      <div className="card-body" key={m}>
                                        <ul className="list-group list-group-flush">
                                          <>
                                            {
                                              item ?
                                              <li className="list-group-item d-flex align-items-center ps-2 pe-2 m-0 p-0">
                                                <span className="flex-grow-1">{ item.titulo }</span>
                                                <a href={ item.urlxlsx }><span className="badge bg-success me-1">.xlsx</span></a>
                                                <a href={ item.urlpdf } target='_blank'><span className="badge bg-danger">.pdf</span></a>
                                              </li>
                                              :
                                              item.link ?
                                              <li className="list-group-item d-flex align-items-center ps-2 pe-2 m-0 p-0">
                                                <a href={ item.link } target='_blank'><span className="flex-grow-1">{ item.titulo }</span></a>
                                              </li> : null
                                            }
                                          </>
                                        </ul>
                                      </div>
                                    ))
                                  }
                                </div>
                              </div>
                            </div>
                          ))
                        }
                      </div>
                    ))
                  }
                </div>
              </div>
            </>
          ))
        }
      </div>
    </div>
  </>
  )
}

export default FormatosDisciplinaFinanciera