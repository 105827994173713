export const paqueteHacendario23Object = {
    'nombre': 'Paquete Hacendario 2023',
    'tipo': '2',
    'servidor': '1',
    'seccion': [
        {
            'titulo': '',
            'subtitulo': '',
            'docs': [
                {
                    'nombreLink':'Criterios para la Integración del Paquete Hacendario 2023',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2023/Criterios%20para%20la%20Integracion%20del%20Paquete%20Hacendario%202023%20-%2031mayo2022.pdf'
                },
                {
                    'nombreLink':'Catálogos de la Clave Presupuestaria 2023',
                    'urltres': 'ServiciosInternos/CatalogosdelaClavePresupuestaria2023'
                },
                {
                    'nombreLink':'Manual de Formulación Presupuestal para el Ejercicio Fiscal 2023',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2023/Manual%20de%20Formulacion%20Presupuestal%20para%20el%20Ejercicio%20Fiscal%202023%20-%2031mayo2022.pdf'
                },
                {
                    'nombreLink':'Iniciativa de Miscelánea Fiscal',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2023/Iniciativa%20de%20Miscelanea%20Fiscal%202023.pdf'
                },
                {
                    'nombreLink':'Iniciativa de Ley de Ingresos',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2023/Iniciativa%20de%20Ley%20de%20Ingresos%202023.pdf'
                },
                {
                    'nombreLink':'Proyecto de Decreto de Presupuesto de Egresos',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2023/Proyecto%20de%20Decreto%20de%20Presupuesto%20de%20Egresos%202023.pdf'
                },
                {
                    'nombreLink':'Anexos Proyecto de Presupuesto de Egresos',
                    'urltres': 'ServiciosInternos/AnexosdelProyectodeDecretodePresupuestodeEgresos2023'
                }
            ]
        }
    ]
}