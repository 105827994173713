import React from 'react';
import './btnMicrisitios.css';
import { linkMicro } from '../../json/Micrositios/UnidadInversiones/subMenu';

const BotonMicrositios = () => {

  return (
    <>
       <div class="row row-cols-2 row-cols-lg-2 g-2 g-lg-3">
       {
            
            linkMicro.map((post, site)=>(          
            <div className="dropdown" key={site}>
                <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {post.nombre}
                </button>
                <>
                    <ul className="dropdown-menu">
                    {
                        post.submenu.map((drop, sub)=>(
                            <li key={sub}><a class="dropdown-item" href={drop.subMenuUrl}>{drop.subMenuNombre}</a></li>
                        )) 
                    }
                    </ul>
                </>
            </div>
            ))
        }
       </div>
    </>
  )
}

export default BotonMicrositios