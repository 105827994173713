export const aRegionalObject = {
    // 'titulo': 'ARegional',
    // 'nombre': 'ARegional',
    'tipo': '5',
    'articulo': [
        {
            'titulo': '',
            'descripcion': '',
            'link': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Aregional'
        }
    ]
}