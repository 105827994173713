import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import ListLink from '../../components/ReusableComponents/ListLink';
import AccordionServicios from '../../components/ReusableComponents/AccordionServicios';
import { catalogosClavePrep2024Object } from '../../json/Servicios/ServiciosInternos/CatalogosClavePresupuestaria2024';
import { catalogosClavePrep2023Object } from '../../json/Servicios/ServiciosInternos/CatalogosClavePresupuestaria2023';
import { catalogosClavePrep2022Object } from '../../json/Servicios/ServiciosInternos/CatalogosClavePresupuestaria2022';
import { catalogosClavePrep2021Object } from '../../json/Servicios/ServiciosInternos/CatalogosClavePresupuestaria2021';
import { catalogosClavePrep2020Object } from '../../json/Servicios/ServiciosInternos/CatalogosClavePresupuestaria2020';
import { catalogosClavePrep2019Object } from '../../json/Servicios/ServiciosInternos/CatalogosClavePresupuestaria2019';
import { anteproyectoPrepEgre18Object } from '../../json/Servicios/ServiciosInternos/AnteproyectoPresupuestodeEgresos18';
import { anexosProyectoDecretoPrepEgre24Object } from '../../json/Servicios/ServiciosInternos/AnexosProyectoDecretoPresupuestoEgresos24';
import { anexosProyectoDecretoPrepEgre23Object } from '../../json/Servicios/ServiciosInternos/AnexosProyectoDecretoPresupuestoEgresos23';
import { anexosProyectoDecretoPrepEgre22Object } from '../../json/Servicios/ServiciosInternos/AnexosProyectoDecretoPresupuestoEgresos22';
import { anexosProyectoDecretoPrepEgre21Object } from '../../json/Servicios/ServiciosInternos/AnexosProyectoDecretoPresupuestoEgresos21';
import { anexosProyectoDecretoPrepEgre20Object } from '../../json/Servicios/ServiciosInternos/AnexosProyectoDecretoPresupuestoEgresos20';
import { anexosProyectoDecretoPrepEgre18Object } from '../../json/Servicios/ServiciosInternos/AnexosProyectoDecretoPresupuestoEgresos18';



const DetalleServiciosInternosScreen = () => {

    const { nombre } = useParams();
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

      const changeObject = (n) => {
        switch(n){
            //ic
            case 'CatalogosdelaClavePresupuestaria2024':
                return catalogosClavePrep2024Object;
            case 'CatalogosdelaClavePresupuestaria2023':
                return catalogosClavePrep2023Object;
            case 'CatalogosdelaClavePresupuestaria2022':
                return catalogosClavePrep2022Object;
            case 'CatalogosdelaClavePresupuestaria2021':
                return catalogosClavePrep2021Object;
            case 'CatalogosdelaClavePresupuestaria2020':
                return catalogosClavePrep2020Object; 
            case 'CatalogosdelaClavePresupuestaria2019':
                return catalogosClavePrep2019Object;
            case 'AnteproyectodePresupuestodeEgresos2018':
                return anteproyectoPrepEgre18Object;
            case 'AnexosdelProyectodeDecretodePresupuestodeEgresos2024':
                return anexosProyectoDecretoPrepEgre24Object;
            case 'AnexosdelProyectodeDecretodePresupuestodeEgresos2023':
                return anexosProyectoDecretoPrepEgre23Object;
            case 'AnexosdelProyectodeDecretodePresupuestodeEgresos2022':
                return anexosProyectoDecretoPrepEgre22Object;
            case 'AnexosdelProyectodeDecretodePresupuestodeEgresos2021':
                return anexosProyectoDecretoPrepEgre21Object;
            case 'AnexosdelProyectodeDecretodePresupuestodeEgresos2020':
                return anexosProyectoDecretoPrepEgre20Object;
            case 'AnexosdelProyectodeDecretodePresupuestodeEgresos2018':
                return anexosProyectoDecretoPrepEgre18Object;
            default:
                return []
            }
        }

  return (
    <div className='container'>
        <div className='pt-5 col-12'>
            <div className='servicio_title'>
                <h3 className='mt-5'>{ changeObject(nombre).nombre }</h3>
                <hr className='hr-gob' />
            </div>
            {
                changeObject(nombre).tipo == "1" ?
                <AccordionServicios object={ changeObject(nombre) } /> : 
                changeObject(nombre).tipo == "2" ?
                <ListLink object={ changeObject(nombre) } /> : null
            }
        </div>
    </div>
  )
}

export default DetalleServiciosInternosScreen