export const transparenciaSecretariaArray = [
    {
        "titulo": 'Transparencia Financiera',
        "rubro":[
            {
                "subtitulo": 'Transparencia Financiera',
                "data":[
                    {
                        "url":"/Secretaría/TransparenciaFinancieraporTema",
                        "icono":"fa-solid fa-window-restore",
                        "nombre":"Transparencia Financiera por Tema",
                        "descripcion":"Información relativa a la Secretaría de Hacienda"
                    },
                    {
                        "url":"/Secretaría/ARegional",
                        "icono":"fa-solid fa-window-restore",
                        "nombre":"ARegional",
                        "descripcion":"Índice de Transparencia y Disponibilidad de la Información Fiscal de las Entidades Federativas"
                    }
                ]
            }
        ]
    }
]