import React from 'react';
import { procuFiscalObject } from '../../json/Servicios/ServiciosInternos/ProcuraduriaFiscal';
import DataTable from 'react-data-table-component';
import './table.css';

const Tabla = ({object}) => {

    const columnas = [
        {
            width: "60px",
            targets: 0,
            name: 'NO.',
            selector: row => <p className="content-table">{row.id}</p>
        },
        {
            width: "515px",
            targets: 1,
            name: 'ORDENAMIENTO JURÍDICO',
            selector: row => <p className="content-table"><a href={row.url} target='_blank'>{row.name}</a></p>
        },
        {
            width: "90px",
            targets: 2,
            name: 'ART.',
            selector: row => <p className="content-table">{row.article}</p>
        },
        {
            width: "280px",
            targets: 3,
            name: 'FECHA DE PUBLICACIÓN',
            selector: row => <p className="content-table">{row.release}</p>
        },
        {
            width: "320px",
            targets: 3,
            name: 'FECHA DE ÚLTIMA REFORMA / FE DE ERRATAS',
            selector: row => <p className="content-table">{row.date}</p>
        }
    ]

  return (
    <>
        {
        object &&
            <div className='my-5'>
                {
                   <DataTable
                   columns={columnas}
                   data={procuFiscalObject.data}
                   striped responsive
                   />
                }
            </div>
        }
    </>
  )
}

export default Tabla