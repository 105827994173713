export const catalogosClavePrep2023Object = {
    'nombre': 'Catálogos de la Clave Presupuestaria 2023',
    'tipo': '2',
    'servidor': '1',
    'seccion': [
        {
            'titulo': '',
            'subtitulo': '',
            'docs': [
                {
                    'nombreLink':'Clasificación por Ramo',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2023/CatalogosdelaClavePresupuestaria/ClasificaciOn%20por%20Ramo.pdf'
                },
                {
                    'nombreLink':'Catálogo de Entes',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2023/CatalogosdelaClavePresupuestaria/Catalogo%20de%20Entes.pdf'
                },
                {
                    'nombreLink':'Clasificación por Tipo de Gasto',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2023/CatalogosdelaClavePresupuestaria/Clasificacion%20por%20Tipo%20de%20Gasto.pdf'
                },
                {
                    'nombreLink':'Clasificación por Objeto de Gasto',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2023/CatalogosdelaClavePresupuestaria/Clasificador%20por%20Objeto%20del%20Gasto.pdf'
                },
                {
                    'nombreLink':'Objetivos de Desarrollo Sostenible',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2023/CatalogosdelaClavePresupuestaria/Objetivos%20de%20Desarrollo%20Sostenible%20(ODS).pdf'
                },
                {
                    'nombreLink':'Clasificación Funcional del Gasto',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2023/CatalogosdelaClavePresupuestaria/Clasificacion%20Funcional%20del%20Gasto.pdf'
                },
                {
                    'nombreLink':'Catálogo de Ejes (Actualización PED 2016-2023)',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2023/CatalogosdelaClavePresupuestaria/Catalogo%20de%20Ejes%20(Actualizacion%20PED%202016-2022).pdf'
                },
                {
                    'nombreLink':'Clasificación Programática',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2023/CatalogosdelaClavePresupuestaria/Catalogos%202023%20-%20Clasificacion%20Programatica%202023%20cambios%20en%20el%20ejercicio.pdf'
                },
                {
                    'nombreLink':'Catálogo de Beneficiarios',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2023/CatalogosdelaClavePresupuestaria/Catalogo%20de%20Beneficiarios.pdf'
                },
                {
                    'nombreLink':'Clasificación por Espacio Geográfico',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2023/CatalogosdelaClavePresupuestaria/Clasificacion%20por%20Espacio%20Geografico.pdf'
                },
                {
                    'nombreLink':'Clasificador por rubro de ingresos (descargable)',
                    'url': '../../../files/servicios/xls/PaqueteHacendario/2023/Formato%20Catalogo%20CRI%202023%20-%20Publicar.xlsx'
                },
                {
                    'nombreLink':'Políticas Sectoriales',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2023/CatalogosdelaClavePresupuestaria/Politicas%20Sectoriales.pdf'
                },
                {
                    'nombreLink':'Indicadores Estratégicos',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2023/CatalogosdelaClavePresupuestaria/Indicadores%20Estrategicos.pdf'
                },
                {
                    'nombreLink':'Objetivos Estratégicos Transversales',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2023/CatalogosdelaClavePresupuestaria/Objetivos%20Estrategicos%20Transversales.pdf'
                }
            ]
        }
    ]
}