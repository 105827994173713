export const paqueteHacendario22Object = {
    'nombre': 'Paquete Hacendario 2022',
    'tipo': '2',
    'servidor': '1',
    'seccion': [
        {
            'titulo': '',
            'subtitulo': '',
            'docs': [
                {
                    'nombreLink':'Criterios para la Integración del Paquete Hacendario 2022',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2022/Criterios%20para%20la%20Integracion%20del%20Paquete%20Hacendario%202022%20-%20final.pdf'
                },
                {
                    'nombreLink':'Catálogos de la Clave Presupuestaria 2022',
                    'urltres': 'ServiciosInternos/CatalogosdelaClavePresupuestaria2022'
                },
                {
                    'nombreLink':'Manual de Formulación Presupuestal para el Ejercicio Fiscal 2022',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2022/Manual%20de%20Formulacion%20Presupuestal%20para%20el%20Ejercicio%20Fiscal%202022%20-%2016%20julio%202021.pdf'
                },
                {
                    'nombreLink':'Iniciativa de Miscelánea Fiscal',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2022/Iniciativa%20de%20Miscelanea%20Fiscal%202022%20firmada.pdf'
                },
                {
                    'nombreLink':'Iniciativa de Ley de Ingresos',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2022/Iniciativa%20de%20Ley%20de%20Ingresos%202022%20firmada.pdf'
                },
                {
                    'nombreLink':'Proyecto de Decreto de Presupuesto de Egresos',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2022/Proyecto%20de%20Decreto%20de%20Presupuesto%20de%20Egresos%202022%20firmado.pdf'
                },
                {
                    'nombreLink':'Anexos Proyecto de Presupuesto de Egresos',
                    'urltres': 'ServiciosInternos/AnexosdelProyectodeDecretodePresupuestodeEgresos2022'
                }
            ]
        }
    ]
}