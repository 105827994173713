export const paqueteHacendario19Object = {
    'nombre': 'Paquete Hacendario 2019',
    'tipo': '2',
    'servidor': '1',
    'seccion': [
        {
            'titulo': '',
            'subtitulo': '',
            'docs': [
                {
                    'nombreLink':'Catálogos de la Clave Presupuestaria 2019',
                    'urltres': 'ServiciosInternos/CatalogosdelaClavePresupuestaria2019'
                },
                {
                    'nombreLink':'Criterios para la Integración del Paquete Hacendario 2019',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2019/Criterios%20para%20la%20Integracion%20del%20Paquete%20Hacendario%202019.pdf'
                },
                {
                    'nombreLink':'Manual de Formulación Presupuestal 2019',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2019/Manual%20de%20Formulacion%20Presupuestal%202019.pdf'
                },
                {
                    'nombreLink':'Iniciativa de Miscelánea Fiscal 2019',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2019/Iniciativa%20de%20Miscelanea%20Fiscal%202019.pdf'
                },
                {
                    'nombreLink':'Iniciativa de Ley de Ingresos 2019',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2019/Iniciativa%20de%20Ley%20de%20Ingresos%202019.pdf'
                },
                {
                    'nombreLink':'Proyecto de Decreto de Presupuesto de Egresos 2019 y Anexos',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2019/Proyecto%20de%20Decreto%20de%20Presupuesto%20de%20Egresos%202019%20y%20Anexos.pdf'
                },
                {
                    'nombreLink':'Proyecto de Decreto de Presupuesto de Egresos 2019',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2019/Proyecto%20de%20Decreto%20de%20Presupuesto%20de%20Egresos%202019.pdf'
                },
                {
                    'nombreLink':'Presupuesto de Egresos del Estado de Hidalgo 2019',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2019/Presupuesto%20de%20Egresos%20del%20Estado%20de%20Hidalgo%202019.pdf'
                }
            ]
        }
    ]
}