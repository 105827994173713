export const ejercicioPrepEgre24Object = {
    'nombre': 'Ejercicio del Presupuesto 2024',
        'tipo': '2',
        'servidor': '1',
        'seccion': [
            {
                'titulo': '',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Manual de Normas y Lineamientos para el Ejercicio de los Recursos del Gasto de Operación 2024',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2024/Manual%20de%20Normas%20y%20Lineamientos%20para%20el%20Ejercicio%20de%20los%20Recursos%20del%20Gasto%20de%20Operaci%C3%B3n%202024.pdf',
                        'urldos': [
                            {
                                'descripcion': 'Anexo A1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20A1.docx'
                            },
                            {
                                'descripcion': 'Anexo C1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C1.docx'
                            },
                            {
                                'descripcion': 'Anexo C2',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C2.docx'
                            },
                            {
                                'descripcion': 'Anexo C3-A',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C3-A.docx'
                            },
                            {
                                'descripcion': 'Anexo C3-B',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C3-B.docx'
                            },
                            {
                                'descripcion': 'Anexo C4-A',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C4-A.docx'
                            },
                            {
                                'descripcion': 'Anexo C4-B',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C4-B.docx'
                            },
                            {
                                'descripcion': 'Anexo C5',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C5.docx'
                            },
                            {
                                'descripcion': 'Anexo C6-A',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C6-A.docx'
                            },
                            {
                                'descripcion': 'Anexo C6-B',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C6-B.docx'
                            },
                            {
                                'descripcion': 'Anexo C7',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C7.docx'
                            },
                            {
                                'descripcion': 'Anexo C8',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C8.docx'
                            },
                            {
                                'descripcion': 'Anexo C9',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C9.docx'
                            },
                            {
                                'descripcion': 'Anexo C10',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C10.docx'
                            },
                            {
                                'descripcion': 'Anexo C11',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C11.docx'
                            },
                            {
                                'descripcion': 'Anexo C12',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C12.docx'
                            },
                            {
                                'descripcion': 'Anexo C13',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C13.docx'
                            },
                            {
                                'descripcion': 'Anexo C14',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C14.docx'
                            },
                            {
                                'descripcion': 'Anexo C15-A',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C15-A.docx'
                            },
                            {
                                'descripcion': 'Anexo C15-B',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C15-B.docx'
                            },
                            {
                                'descripcion': 'Anexo C15-C',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C15-C.docx'
                            },
                            {
                                'descripcion': 'Anexo C16',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C16.docx'
                            },
                            {
                                'descripcion': 'Anexo C17',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C17.docx'
                            },
                            {
                                'descripcion': 'Anexo C18',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C18.docx'
                            },
                            {
                                'descripcion': 'Anexo C19',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C19.docx'
                            },
                            {
                                'descripcion': 'Anexo C20',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C20.docx'
                            },
                            {
                                'descripcion': 'Anexo C20.1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C20.1.docx'
                            },
                            {
                                'descripcion': 'Anexo C21',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C21.docx'
                            },
                            {
                                'descripcion': 'Anexo C22',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C22.docx'
                            },
                            {
                                'descripcion': 'Anexo C23',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C23.docx'
                            },
                            {
                                'descripcion': 'Anexo C24',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20C24.docx'
                            },
                            {
                                'descripcion': 'Anexo E1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20E1.xlsx'
                            },
                            {
                                'descripcion': 'Anexo E2',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20E2.xlsx'
                            },
                            {
                                'descripcion': 'Anexo F',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20F.docx'
                            },
                            {
                                'descripcion': 'Anexo I',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20I.docx'
                            },
                            {
                                'descripcion': 'Anexo J',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20J.docx'
                            },
                            {
                                'descripcion': 'Anexo K',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20K.docx'
                            },
                            {
                                'descripcion': 'Anexo K1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20K1.docx'
                            },
                            {
                                'descripcion': 'Anexo K2',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20K2.docx'
                            },
                            {
                                'descripcion': 'Anexo L1',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20L1.pdf'
                            },
                            {
                                'descripcion': 'Anexo N1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20N1.docx'
                            },
                            {
                                'descripcion': 'Anexo Q',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20Q.docx'
                            },
                            {
                                'descripcion': 'Anexo R',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20R.docx'
                            },
                            {
                                'descripcion': 'Anexo T',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20T.docx'
                            },
                            {
                                'descripcion': 'Anexo U',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20U.docx'
                            },
                            {
                                'descripcion': 'Anexo W',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20W.docx'
                            },
                            {
                                'descripcion': 'GO - Anexo D',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/GO%20-%20Anexo%20D.pdf'
                            },
                            {
                                'descripcion': 'Anexo V1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20V1.xlsx'
                            },
                            {
                                'descripcion': 'Anexo A2',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/Anexo%20A2.pdf'
                            },
                            {
                                'descripcion': 'GO - Anexo N2',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/GO%20-%20Anexo%20N2.xlsx'
                            },
                            {
                                'descripcion': 'GO - Anexo H',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GO/GO%20-%20Anexo%20H.pdf'
                            }
                        ]
                    }
                ]
            },
            {
                'titulo': '',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Manual de Normas y Lineamientos para el Ejercicio de los Recursos del Gasto de Inversión 2024',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2024/Manual%20de%20Normas%20y%20Lineamientos%20para%20el%20Ejercicio%20de%20los%20Recursos%20del%20Gasto%20de%20Inversi%C3%B3n%202024.pdf',
                        'urldos': [
                            {
                                'descripcion': 'Anexo A1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20A1.docx'
                            },
                            {
                                'descripcion': 'Anexo C1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20C1.docx'
                            },
                            {
                                'descripcion': 'Anexo C2',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20C2.docx'
                            },
                            {
                                'descripcion': 'Anexo C3',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20C3.docx'
                            },
                            {
                                'descripcion': 'Anexo C4',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20C4.docx'
                            },
                            {
                                'descripcion': 'Anexo C5',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20C5.docx'
                            },
                            {
                                'descripcion': 'Anexo C6',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20C6.docx'
                            },
                            {
                                'descripcion': 'Anexo C7',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20C7.docx'
                            },
                            {
                                'descripcion': 'Anexo C8',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20C8.docx'
                            },
                            {
                                'descripcion': 'Anexo C9',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20C9.docx'
                            },
                            {
                                'descripcion': 'Anexo C10',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20C10.docx'
                            },
                            {
                                'descripcion': 'Anexo C11-A',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20C11-A.docx'
                            },
                            {
                                'descripcion': 'Anexo C11-B',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20C11-B.docx'
                            },
                            {
                                'descripcion': 'Anexo C12-A',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20C12-A.docx'
                            },
                            {
                                'descripcion': 'Anexo C12-B',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20C12-B.docx'
                            },
                            {
                                'descripcion': 'Anexo C13',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20C13.docx'
                            },
                            {
                                'descripcion': 'Anexo C14',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20C14.docx'
                            },
                            {
                                'descripcion': 'Anexo C15',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20C15.docx'
                            },
                            {
                                'descripcion': 'Anexo C16',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20C16.docx'
                            },
                            {
                                'descripcion': 'Anexo C17',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20C17.docx'
                            },
                            {
                                'descripcion': 'Anexo C18',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20C18.docx'
                            },
                            {
                                'descripcion': 'Anexo D3',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20D3.docx'
                            },
                            {
                                'descripcion': 'Anexo D4.1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20D4.1.docx'
                            },
                            {
                                'descripcion': 'Anexo D4.2',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20D4.2.docx'
                            },
                            {
                                'descripcion': 'Anexo D4',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20D4.docx'
                            },
                            {
                                'descripcion': 'Anexo D8',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20D8.docx'
                            },
                            {
                                'descripcion': 'Anexo D9',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20D9.docx'
                            },
                            {
                                'descripcion': 'Anexo D10',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20D10.docx'
                            },
                            {
                                'descripcion': 'Anexo D11',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20D11.docx'
                            },
                            {
                                'descripcion': 'Anexo F',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20F.docx'
                            },
                            {
                                'descripcion': 'GI - Anexo D1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/GI%20-%20Anexo%20D1.pdf'
                            },
                            {
                                'descripcion': 'GI - Anexo D1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/GI%20-%20Anexo%20D1.pdf'
                            },
                            {
                                'descripcion': 'Anexo D2',
                                'link': '../files/servicios/xls/EjerciciodelPresupuesto/Anexos%20GI/2024/GI%20-%20Anexo%20D2.xlsx'
                            },
                            {
                                'descripcion': 'GI - Anexo D6',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/GI%20-%20Anexo%20D6.pdf'
                            },
                            {
                                'descripcion': 'GI - Anexo D13',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/GI%20-%20Anexo%20D13.pdf'
                            },
                            {
                                'descripcion': 'Anexo V1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20V1.xlsx'
                            },
                            {
                                'descripcion': 'Anexo V2',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20V2.xlsx'
                            },
                            {
                                'descripcion': 'Anexo A2',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/Anexo%20A2.pdf'
                            },
                            {
                                'descripcion': 'GI - Anexo D7',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2024/Anexos%20GI/GI%20-%20Anexo%20D7.pdf'
                            }
                        ]
                    }
                ]
            }
        ]
    }