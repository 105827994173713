export const impuestosyDerechosObject = {
    // 'titulo': 'Impuestos y Derechos Hidalgo',
    // 'nombre': 'Trámites en Línea',
    'tipo': '5',
    'articulo': [
        {
            'titulo': '',
            'descripcion': '',
            'link': 'https://ehacienda.hidalgo.gob.mx/Transparencia/VentanillaCiudadana'
        }
    ]
}