export const informesResultadosCuentaPublicaArray = [
    {
        'anio' : '2023',
        'link' : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/Informe%20de%20Resultados%20de%20la%20Cuenta%20de%20la%20Hacienda%20Publica%202023.pdf',
        'data' : [
            {
                "titulo" : "Relación de Bienes Inmuebles que Componen el Patrimonio 2023",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2023/Relacion%20de%20Bienes%20Inmuebles.pdf',
            },
            {
                "titulo" : "Relación de Bienes Muebles que Componen el Patrimonio 2023",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2023/Relacion%20de%20Bienes%20Muebles.pdf',
            },
            {
                "titulo" : "Aumento o Creación del Gasto del Presupuesto de Egresos durante el Ejercicio",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2023/Aumento%20o%20Creacion%20del%20Gasto%20del%20PE%20durante%20el%20Ejercicio.pdf',
            },
            {
                "titulo" : "Indicadores de Resultados",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2023/Indicadores%20de%20Resultados.pdf',
            }
        ]
    },
    {
        'anio' : '2022',
        'link' : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/Informe%20de%20Resultados%20de%20la%20Cuenta%20de%20la%20Hacienda%20Publica%202022.pdf',
        'data' : [
            {
                "titulo" : "Relación de Bienes Inmuebles que Componen el Patrimonio 2022",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2022/Relacion%20de%20Bienes%20Inmuebles%20que%20Componen%20el%20Patrimonio%202022.pdf',
            },
            {
                "titulo" : "Relación de Bienes Muebles que Componen el Patrimonio 2022",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2022/Relacion%20de%20Bienes%20Muebles%20que%20Componen%20el%20Patrimonio%202022.pdf',
            },
            {
                "titulo" : "Aumento o Creación del Gasto del Presupuesto de Egresos durante el Ejercicio",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2022/Aumento%20o%20Creacion%20del%20Gasto%20del%20Presupuesto%20de%20Egresos%20durante%20el%20Ejercicio.pdf',
            },
            {
                "titulo" : "Indicadores de Resultados",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2022/Indicadores%20de%20Resultados%20(10%20Art%2014%20ID).pdf',
            }
        ]
    },
    {
        'anio' : '2021',
        'link' : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/Informe%20de%20Resultados%20de%20la%20Cuenta%20de%20la%20Hacienda%20Publica%202021.pdf',
        'data' : [
            {
                "titulo" : "Relación de Bienes Inmuebles que Componen el Patrimonio 2021",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2021/Anexo%202%20Relacion%20de%20Bienes%20Inmuebles%202021.pdf',
            },
            {
                "titulo" : "Relación de Bienes Muebles que Componen el Patrimonio 2021",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2021/Anexo%201%20Relacion%20de%20Bienes%20muebles%202021.pdf',
            },
            {
                "titulo" : "Aumento o Creación del Gasto del Presupuesto de Egresos durante el Ejercicio",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2021/VI%202%20Aumento%20o%20Creacion%20del%20Gasto%20del%20Presupuesto%20de%20Egresos%20Durante%20el%20Ejercicio%202021.pdf',
            },
            {
                "titulo" : "Indicadores de Resultados",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2021/IV%203%20Indicadores%20de%20Resultados%202021.pdf',
            }
        ]
    },
    {
        'anio' : '2020',
        'link' : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/Informe%20de%20Resultados%20de%20la%20Cuenta%20de%20la%20Hacienda%20Publica%202020.pdf',
        'data' : [
            {
                "titulo" : "Relación de Bienes Inmuebles que Componen el Patrimonio 2020",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2020/Relacion%20de%20Bienes%20Inmuebles%20que%20Componen%20el%20Patrimonio.pdf',
            },
            {
                "titulo" : "Relación de Bienes Muebles que Componen el Patrimonio 2020",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2020/Relacion%20de%20Bienes%20Muebles%20que%20Componen%20el%20Patrimonio.pdf',
            },
            {
                "titulo" : "Aumento o Creación del Gasto del Presupuesto de Egresos durante el Ejercicio",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2020/Aumento%20o%20Creacion%20del%20Gasto%20del%20Presupuesto%20de%20Egresos%20durante%20el%20Ejercicio.pdf',
            },
            {
                "titulo" : "Indicadores de Resultados",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2020/Indicadores%20de%20Resultados.pdf',
            }
        ]
    },
    {
        'anio' : '2019',
        'link' : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/Cuenta%20Publica%20del%20Poder%20Ejecutivo%20del%20Estado%20de%20Hidalgo%202019.pdf',
        'data' : [
            {
                "titulo" : "Relación de Bienes Inmuebles que Componen el Patrimonio 2019",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2019/Relacion%20de%20Bienes%20Inmuebles%20que%20Componen%20el%20Patrimonio.pdf',
            },
            {
                "titulo" : "Relación de Bienes Muebles que Componen el Patrimonio 2019",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2019/Relacion%20de%20Bienes%20Muebles%20que%20Componen%20el%20Patrimonio.pdf',
            },
            {
                "titulo" : "Aumento o Creación del Gasto del Presupuesto de Egresos durante el Ejercicio",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2019/Aumento%20o%20Creacion%20del%20Gasto%20del%20Presupuesto%20de%20Egresos%20durante%20el%20Ejercicio.pdf',
            },
            {
                "titulo" : "Indicadores de Resultados",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2019/Indicadores%20de%20Resultados.pdf',
            }
        ]
    },
    {
        'anio' : '2018',
        'link' : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/Informe%20de%20Resultados%20de%20la%20Cuenta%20de%20la%20Hacienda%20Publica%202018.pdf',
        'data' : [
            {
                "titulo" : "Relación de Bienes Inmuebles que Componen el Patrimonio 2018",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2018/Relacion%20de%20Bienes%20Inmuebles%20que%20Componen%20el%20Patrimonio.pdf',
            },
            {
                "titulo" : "Relación de Bienes Muebles que Componen el Patrimonio 2018",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2018/Relacion%20de%20Bienes%20Muebles%20que%20Componen%20el%20Patrimonio.pdf',
            },
            {
                "titulo" : "Aumento o Creación del Gasto del Presupuesto de Egresos durante el Ejercicio",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2018/Aumento%20o%20Creacion%20del%20Gasto%20del%20Presupuesto%20de%20Egresos%20durante%20el%20Ejercicio.pdf',
            },
            {
                "titulo" : "Indicadores de Resultados",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2018/Indicadores%20de%20Resultados.pdf',
            }
        ]
    },
    {
        'anio' : '2017',
        'link' : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/Cuenta%20Publica%202017%20sin%20logos.pdf',
        'data' : [
            {
                "titulo" : "Relación de Bienes Inmuebles que Componen el Patrimonio 2017",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2017/Relacion%20de%20Bienes%20Inmuebles%20que%20Componen%20el%20Patrimonio.pdf',
            },
            {
                "titulo" : "Relación de Bienes Muebles que Componen el Patrimonio 2017",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2017/Relacion%20de%20bienes%20Muebles%20que%20Componen%20el%20Patrimonio.pdf',
            },
            {
                "titulo" : "Aumento o Creación del Gasto del Presupuesto de Egresos durante el Ejercicio",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2017/Aumento%20o%20Creacion%20del%20Gasto%20del%20Presupuesto%20de%20Egresos%20durante%20el%20Ejercicio.pdf',
            }
        ]
    },
    {
        'anio' : '2016',
        'link' : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/Informe%20de%20Resultados%20de%20la%20Cuenta%20de%20la%20Hacienda%20Publica%202016.pdf',
        'data' : [
            {
                "titulo" : "Relación de Bienes Inmuebles que Componen el Patrimonio 2016",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2016/Relacion%20de%20Bienes%20Inmuebles%20que%20Componen%20el%20Patrimonio.pdf',
            },
            {
                "titulo" : "Relación de Bienes Muebles que Componen el Patrimonio 2016",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2016/Relacion%20de%20Bienes%20Muebles%20que%20Componen%20el%20Patrimonio.pdf',
            }
        ]
    },
    {
        'anio' : '2015',
        'link' : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/Informe%20de%20Resultados%20de%20la%20Cuenta%20de%20la%20Hacienda%20Publica%202015.pdf',
        'data' : [
            {
                "titulo" : "Relación de Bienes Inmuebles que Componen el Patrimonio 2015",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2015/Relacion%20de%20Bienes%20Inmuebles%20que%20Componen%20el%20Patrimonio.pdf',
            },
            {
                "titulo" : "Relación de Bienes Muebles que Componen el Patrimonio 2015",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2015/Relacion%20de%20Bienes%20Muebles%20que%20Componen%20el%20Patrimonio.pdf',
            }
        ]
    },
    {
        'anio' : '2014',
        'link' : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/Informe%20de%20la%20Cuenta%20de%20la%20Hacienda%20Publica%202014.pdf',
        'data' : [
            {
                "titulo" : "Relación de Bienes Inmuebles que Componen el Patrimonio 2014",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2014/Relacion%20de%20Bienes%20Inmuebles%20que%20Componen%20el%20Patrimonio.pdf',
            },
            {
                "titulo" : "Relación de Bienes Muebles que Componen el Patrimonio 2014",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2014/Relacion%20de%20Bienes%20Muebles%20que%20Componen%20el%20Patrimonio.pdf',
            }
        ]
    },
    {
        'anio' : '2013',
        'link' : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/Cuenta%20Publica%202013.pdf',
        'data' : [
            {
                "titulo" : "Cuentas Bancarias para la Administración de Recursos Federales en el Ejercicio 2013",
                "urlpdf" : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/2013/Cuentas%20Bancarias%20para%20la%20Administracion%20de%20Recursos%20Federales%20en%20el%20Ejercicio.pdf',
            }
        ]
    },
    {
        'anio' : '2012',
        'link' : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/Cuenta%20Publica%202012.pdf'
    },
    {
        'anio' : '2011',
        'link' : '/files/transparencia/pdf/Informes%20de%20Resultados%20de%20la%20Cuenta%20Publica/Cuenta%20Publica%202011.pdf'
    }
]