export const rematesenLineaObject = {
    'nombre': 'Remates en Línea',
    'tipo': '5',
    'articulo': [
        {
            'titulo': '',
            'descripcion': 'De conformidad con el artículo 174 del Código Fiscal de la Federación y 198 del Código Fiscal del Estado de Hidalgo, la enajenación de bienes embargados debe realizarse a través de medios electrónicos, por lo cual el Gobierno del Estado de Hidalgo implementó el servicio Remates en Línea para llevar a cabo el de bienes embargados a contribuyentes que han incumplido con el pago de sus adeudos fiscales. En este servicio se publicarán las convocatorias en las que se dará a conocer los bienes que son objeto de remate, el valor de base para su enajenación, así como los requisitos para los postores.',
            'link': 'https://efinanzas.hidalgo.gob.mx/PROE/Vistas/WebFrmPROE07.aspx'
        }
    ]
}