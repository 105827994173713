export const catalogosClavePrep2020Object = {
    'nombre': 'Catálogos de la Clave Presupuestaria 2020',
    'tipo': '2',
    'servidor': '1',
    'seccion': [
        {
            'titulo': '',
            'subtitulo': '',
            'docs': [
                {
                    'nombreLink':'Clasificación por Ramo',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2020/CatalogosdelaClavePresupuestaria/1%20Clasificacion%20por%20Ramo.pdf'
                },
                {
                    'nombreLink':'Catálogo de Entes',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2020/CatalogosdelaClavePresupuestaria/2%20Catalogo%20de%20Entes.pdf'
                },
                {
                    'nombreLink':'Catálogo por Rubro de Ingreso',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2020/CatalogosdelaClavePresupuestaria/3%20Catalogo%20por%20Rubro%20de%20Ingreso.pdf'
                },
                {
                    'nombreLink':'Clasificación por Tipo de Gasto',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2020/CatalogosdelaClavePresupuestaria/4%20Clasificacion%20por%20Tipo%20de%20Gasto.pdf'
                },
                {
                    'nombreLink':'Clasificación por Objeto del Gasto',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2020/CatalogosdelaClavePresupuestaria/5%20Clasificacio%CC%81n%20por%20Objeto%20del%20Gasto.pdf'
                },
                {
                    'nombreLink':'Clasificación Funcional',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2020/CatalogosdelaClavePresupuestaria/6%20Clasificacion%20Funcional.pdf'
                },
                {
                    'nombreLink':'Catálogo de Ejes PED 2016-2022',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2020/CatalogosdelaClavePresupuestaria/7%20Catalogo%20de%20Ejes%20PED%202016-2022.pdf'
                },
                {
                    'nombreLink':'Objetivos de Desarrollo Sostenible (ODS)',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2020/CatalogosdelaClavePresupuestaria/8%20Objetivos%20de%20Desarrollo%20Sostenible%20(ODS).pdf'
                },
                {
                    'nombreLink':'Clasificación Programática',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2020/CatalogosdelaClavePresupuestaria/9%20Clasificacion%20Programatica.pdf'
                },
                {
                    'nombreLink':'Clasificación por Beneficiario',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2020/CatalogosdelaClavePresupuestaria/10%20Clasificacion%20por%20Beneficiario.pdf'
                },
                {
                    'nombreLink':'Clasificación por Espacio Geográfico',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2020/CatalogosdelaClavePresupuestaria/11%20Clasificacion%20por%20Espacio%20Geografico.pdf'
                },
                {
                    'nombreLink':'Catálogo Indicador PED',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2020/CatalogosdelaClavePresupuestaria/12%20Catalogo%20Indicador%20PED.pdf'
                },
                {
                    'nombreLink':'Catálogo de Estrategias Transversales',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2020/CatalogosdelaClavePresupuestaria/13%20Catalogo%20de%20Estrategias%20Transversales.pdf'
                }
            ]
        }
    ]
}