export const anteproyectoPrepEgre18Object = {
    'nombre': 'Anteproyecto del Presupuesto de Egresos 2018',
    'tipo': '2',
    'servidor': '1',
    'seccion': [
        {
            'titulo': '',
            'subtitulo': '',
            'docs': [
                {
                    'nombreLink':'Catálogo de Misión (archivo descargable)',
                    'url': '../../../files/servicios/xls/PaqueteHacendario/2018/AnteproyectodelPresupuestodeEgresos/1%20Catalogos%20de%20Mision.xlsx'
                },
                {
                    'nombreLink':'Catálogo de Propósito Institucional (archivo descargable)',
                    'url': '../../../files/servicios/xls/PaqueteHacendario/2018/AnteproyectodelPresupuestodeEgresos/2%20Catalogos%20de%20Proposito%20Institucional%20Publicacion.xlsx'
                },
                {
                    'nombreLink':'Catálogo Problema',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2018/AnteproyectodelPresupuestodeEgresos/3%20Catalogo%20Problema.pdf'
                },
                {
                    'nombreLink':'Catálogo Dimensión Problema',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2018/AnteproyectodelPresupuestodeEgresos/4%20Catalogo%20Dimension%20Problema.pdf'
                },
                {
                    'nombreLink':'Catálogo de Indicador',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2018/AnteproyectodelPresupuestodeEgresos/5%20Catalogo%20de%20Indicador.pdf'
                },
                {
                    'nombreLink':'Catálogo Meta de Indicador',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2018/AnteproyectodelPresupuestodeEgresos/6%20Catalogo%20Meta%20de%20Indicador.pdf'
                },
                {
                    'nombreLink':'Manual de Formulación Presupuestal para el Ejercicio Fiscal 2018',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2018/AnteproyectodelPresupuestodeEgresos/7%20Manual%20de%20Formulacion%20Presupuestal%20para%20el%20Ejercicio%20Fiscal%202018.pdf'
                },
                {
                    'nombreLink':'Criterios para la Integración del Paquete Hacendario 2018',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2018/AnteproyectodelPresupuestodeEgresos/8%20Criterios%20para%20la%20Integracion%20del%20Paquete%20Hacendario%202018.pdf'
                },
                {
                    'nombreLink':'Clasificación por Ramo',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2018/AnteproyectodelPresupuestodeEgresos/9%20Clasificacion%20por%20Ramo.pdf'
                },
                {
                    'nombreLink':'Catálogo de Entes',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2018/AnteproyectodelPresupuestodeEgresos/10%20Catalogo%20de%20Entes.pdf'
                },
                {
                    'nombreLink':'Clasificación por Rubro de Ingresos',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2018/AnteproyectodelPresupuestodeEgresos/11%20Clasificacion%20por%20Rubro%20de%20Ingresos.pdf'
                },
                {
                    'nombreLink':'Clasificación por Tipo de Gasto',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2018/AnteproyectodelPresupuestodeEgresos/12%20Clasificacion%20por%20Tipo%20de%20Gasto.pdf'
                },
                {
                    'nombreLink':'Clasificación por Objeto del Gasto',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2018/AnteproyectodelPresupuestodeEgresos/13%20Clasificacion%20por%20Objeto%20del%20Gasto.pdf'
                },
                {
                    'nombreLink':'Clasificación Funcional',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2018/AnteproyectodelPresupuestodeEgresos/14%20Clasificacion%20Funcional.pdf'
                },
                {
                    'nombreLink':'Catálogo de Ejes PED 2016-2022',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2018/AnteproyectodelPresupuestodeEgresos/15%20Catalogo%20de%20Ejes%20PED%202016-2022.pdf'
                },
                {
                    'nombreLink':'Objetivos de Desarrollo Sostenible (ODS)',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2018/AnteproyectodelPresupuestodeEgresos/16%20Objetivos%20de%20Desarrollo%20Sostenible%20(ODS).pdf'
                },
                {
                    'nombreLink':'Clasificación Programática',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2018/AnteproyectodelPresupuestodeEgresos/17%20Clasificacion%20Programatica.pdf'
                },
                {
                    'nombreLink':'Clasificación por Beneficiario',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2018/AnteproyectodelPresupuestodeEgresos/18%20Clasificacion%20por%20Beneficiario.pdf'
                },
                {
                    'nombreLink':'Clasificación por Espacio Geográfico',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2018/AnteproyectodelPresupuestodeEgresos/19%20Clasificacion%20por%20Espacio%20Geografico.pdf'
                }
            ]
        }
    ]
}