export const asociacionesPubPrivArray = [
    {
        'name' : 'Eficientización del Sistema de Alumbrado Público del municipio de San Agustín Tlaxiaca',
        'data' : [
            {
                "subtitulo": "Información relevante",
                "titulo" : "Convocatorias Luminarias",
                "urlpdf" : '/files/inversiones/pdf/AsociacionesPublicoPrivadas/SanAgustinTlaxiaca/Convocatorias%20Luminarias.pdf',
            },
            {
                "subtitulo": "",
                "titulo" : "Fallo de Licitación",
                "urlpdf" : '/files/inversiones/pdf/AsociacionesPublicoPrivadas/SanAgustinTlaxiaca/Fallo%20de%20Licitacio%CC%81n.pdf',
            },
            {
                "subtitulo": "",
                "titulo" : "Detalles",
                "urlpdf" : '/files/inversiones/pdf/AsociacionesPublicoPrivadas/SanAgustinTlaxiaca/Detalles.pdf',
            },
            {
                "subtitulo": "Indicadores cualitativos",
                "titulo" : "Análisis de conveniencia y transferencia de riesgos",
                "urlpdf" : '/files/inversiones/pdf/AsociacionesPublicoPrivadas/SanAgustinTlaxiaca/Ana%CC%81lisis%20de%20conveniencia%20y%20transferencia%20de%20riesgos.pdf',
            },
        ]
    },
    {
        'name' : 'Eficientización del Sistema de Alumbrado Público del municipio de Zacualtipán de Ángeles',
        'data' : [
            {
                "subtitulo": "Información relevante",
                "titulo" : "Convocatorias Luminarias",
                "urlpdf" : '',
            },
            {
                "subtitulo": "",
                "titulo" : "Fallo de Licitación",
                "urlpdf" : '',
            },
            {
                "subtitulo": "",
                "titulo" : "Detalles",
                "urlpdf" : '',
            },
            {
                "subtitulo": "Indicadores cualitativos",
                "titulo" : "Análisis de conveniencia y transferencia de riesgos",
                "urlpdf" : '',
            },
        ]
    }
]