import React from 'react';
import './frame.css';

const Frame = ({object}) => {
  
  return (
    <>
        {
        object &&
          <div>
            {
              object.articulo.map((paragraph, r) => (
                <>
                  <p key={r}>{ paragraph.descripcion }</p>
                  <div className="ratio ratio-1x1 ratio-h mb-5">
                    <iframe className="mi-iframe embed-responsive-item" src={ paragraph.link } frameborder="0" allowfullscreen></iframe>
                  </div>
                </>
              ))
            }
          </div>
        }
    </>
  )
}

export default Frame