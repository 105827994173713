import { React, useState, useRef } from 'react';
import './contenido.css';
import { servidorArray } from '../../json/servidor';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';

const Contenido = ({object}) => {
  const imgModal = useRef(null);
  
  function handleClick() {
    // setShow(true);
    // imgModal.current.focus();
  }

  const [show, setShow] = useState(false);

  var servers = JSON.parse(JSON.stringify (servidorArray));
  
  const changeServer = (n) => {
    console.log(n);
    switch(n){
        //ic
        case '1':
          return servers.servidoruno;
        case '2':
          return servers.servidordos;
        case '3':
          return servers.servidortres;
        default:
            return []
        }
    }
  
  const changeImg = (name) => {
    console.log(imgModal.current.id);
    imgModal.current.value=2;
   // imgModal.current.innerHTML = '<img src="https://images.unsplash.com/photo-1682685797736-dabb341dc7de?q=80&amp;w=5070&amp;auto=format&amp;fit=crop&amp;ixlib=rb-4.0.3&amp;ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" type="button" data-toggle="modal" data-target="#myModalUno_0" class="img-fluid shadow-1-strong rounded pt-5">';
    }
  
  

  return (
    <>
        {
        object &&
          <div>
              {
                object.articulo.map((article, j) => (
                  <div className='bg-white py-3 px-3 mb-5' key={j}>
                    {
                    article?.titulo != '' ?
                    <h4 className='fs-2 fw-medium pb-4'>{article.titulo}</h4>
                    : null
                    }
                     <p className='text-justify lh-lg ps-3 pt-3'>{article.descripcion}
                        <>
                          {
                            article.data.map((titlink, k) => (
                              <li className='list-group-item list-unstyled py-3 border-0' key={k}>
                                {
                                titlink?.titulolink != '' ?
                                <>
                                  {/* <a href={changeServer(object.servidor) + titlink.url} target="_blank">{ titlink.titulolink }</a> */}
                                  <a href={ titlink.url } target="_blank">{ titlink.titulolink }</a>
                                </> : null
                                }
                                  <div>
                                    {
                                      titlink.docs.map((subtit, s) => (
                                        <li className='list-unstyled' key={s}>
                                          {/* <a href={changeServer(object.servidor) + subtit.url} target="_blank">{ subtit.subtitulolink }</a> */}
                                          <span>
                                            <small><i className={ subtit.icono } style={{width: '1px', height: '1px', color: 'var(--wine)'}}></i></small>
                                            <a className='ps-3' href={ subtit.url } target="_blank">{ subtit.subtitulolink }</a>
                                          </span>
                                          <div className="row">
                                              {
                                                subtit.images?.map((ima, i) => (
                                                  <>
                                                    <div className='col-md-4 col-xs-12 pt-1' key={i}>
                                                      <a onClick={handleClick} type="button" data-toggle="modal" data-target={'#myModalUno' + '_' + i}>
                                                        <Image className="img-fluid shadow-1-strong rounded" src={ ima.img } />
                                                      </a>
                                                      <Modal show={show} onHide={() => setShow(false)} id={'myModalUno' + '_' + i} aria-labelledby="example-custom-modal-styling-title">
                                                        <Image className="img-fluid shadow-1-strong rounded" src={ ima.img } />
                                                      </Modal>
                                                    </div>
                                                  </>
                                                ))
                                              }
                                          </div>
                                        </li>
                                      ))
                                    }
                                  </div> 
                              </li>
                            ))
                          }
                        </>
                      </p>
                  </div>
                ))
              }
          </div>
        } 
    </>
  )
}

export default Contenido