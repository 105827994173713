import React from 'react';
import './ihdep.css';

const IHDEP = () => {
  return (
    <>
    <div className='container pt-5'>
        <div className='row'>
            <div>
                <h3 className='title mt-5 pt-5'>IHDEP · Instituto Hidalguense para devolver al Pueblo lo robado</h3>
                <hr className='hr-gob'/>
            </div>
            <div className="ratio ratio-4x3 mb-5 me-5 ms-2">
                <iframe className="mi-iframe embed-responsive-item" src="https://hacienda.hidalgo.gob.mx/files/secretaria/informacionDestacada/PID%20Instituto%20Hidalguense%20para%20Devolver%20al%20Pueblo%20lo%20Robado%20061223%20Firmado.pdf" frameborder="0" allowfullscreen></iframe>
            </div>
        </div>
    </div>
    </>
  )
}

export default IHDEP