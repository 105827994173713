export const procuFiscalObject = {
    'nombre': 'Procuraduría Fiscal',
    'tipo': '4',
    'servidor': '1',
    'data': [
        {
            'id': '1',
            'name': 'Constitución Política del Estado de Hidalgo',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/2024/1%20Constitucion%20Politica%20del%20Estado%20de%20Hidalgo.pdf',
            'article': '159',
            'release': 'P.O.E. - 01/10/1920',
            'date': 'P.O.E. Alcance Dos - 04/12/2023'
        },
        {
            'id': '2',
            'name': 'Código Fiscal del Estado de Hidalgo',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/2024/2%20Codigo%20Fiscal%20del%20Estado%20de%20Hidalgo.pdf',
            'article': '268',
            'release': 'P.O.E. - 30/12/2000',
            'date': 'P.O.E. Alcance Ocho - 29/12/2023'
        },
        {
            'id': '3',
            'name': 'Código Fiscal Municipal para el Estado de Hidalgo',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/Codigo%20Fiscal%20Municipal%20para%20el%20Estado%20de%20Hidalgo.pdf',
            'article': '284',
            'release': 'P.O.E. - 21/11/2011',
            'date': 'P.O.E. Alcance Dos - 30/05/2023'
        },
        {
            'id': '4',
            'name': 'Ley de Catastro del Estado de Hidalgo',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/4%20Ley%20de%20Catastro%20del%20Estado%20de%20Hidalgo.pdf',
            'article': '89',
            'release': 'P.O.E. - 02/09/2013',
            'date': 'P.O.E. Alcance Volumen II - 31/12/2016'
        },
        {
            'id': '5',
            'name': 'Ley de Coordinación Fiscal para el Estado de Hidalgo',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/2024/5%20Ley%20de%20Coordinacion%20Fiscal%20para%20el%20Estado%20de%20Hidalgo.pdf',
            'article': '27',
            'release': 'P.O.E. - 30/12/1989',
            'date': 'P.O.E. Alcance Ocho - 29/12/2023'
        },
        {
            'id': '6',
            'name': 'Ley de Deuda Pública para el Estado de Hidalgo',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/6%20-%20Ley%20de%20Deuda%20Publica%20para%20el%20Estado%20de%20Hidalgo.pdf',
            'article': '90',
            'release': 'P.O.E. Alcance - 18/06/2018',
            'date': 'P.O.E. 13/02/2023'
        },
        {
            'id': '7',
            'name': 'Ley de Hacienda del Estado de Hidalgo',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/2024/7%20Ley%20de%20Hacienda%20del%20Estado%20de%20Hidalgo.pdf',
            'article': '116',
            'release': 'P.O.E. - 31/12/2003 (F.E. 03/05/2004)',
            'date': 'P.O.E. Alcance Ocho - 29/12/2023'
        },
        {
            'id': '8',
            'name': 'Ley de Hacienda para los Municipios del Estado de Hidalgo',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/Ley%20de%20Hacienda%20para%20los%20Municipios%20del%20Estado%20de%20Hidalgo.pdf',
            'article': '194',
            'release': 'P.O.E. Alcance - 21/11/2011',
            'date': 'P.O.E. Alcance Dos - 30/05/2023'
        },
        {
            'id': '9',
            'name': 'Ley de Ingresos del Estado Libre y Soberano de Hidalgo para el ejercicio fiscal del año 2024',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/2024/9%20Ley_Ingresos_Ejercicio_Fiscal_2024.pdf',
            'article': '16',
            'release': 'P.O.E. Alcance Nueve 29/12/2023',
            'date': ''
        },
        {
            'id': '10',
            'name': 'Ley de Presupuesto y Contabilidad Gubernamental del Estado de Hidalgo',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/Ley%20de%20Presupuesto%20y%20Contabilidad%20Gubernamental%20del%20Estado%20de%20Hidalgo.pdf',
            'article': '106',
            'release': 'P.O.E. - 27/10/2014',
            'date': 'P.O.E. Alcance Dos 05/10/2023'
        },
        {
            'id': '11',
            'name': 'Ley Orgánica de la Administración Pública para el Estado de Hidalgo',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/11%20-%20Ley%20Organica%20de%20la%20Administracion%20Publica%20para%20el%20Estado%20de%20Hidalgo.pdf',
            'article': '42',
            'release': 'P.O.E. Alcance tres - 31/03/2023',
            'date': ''
        },
        {
            'id': '12',
            'name': 'Ley de Control Vehicular para el Estado de Hidalgo',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/2024/12%20Ley%20de%20Control%20Vehicular%20para%20el%20Estado%20de%20Hidalgo.pdf',
            'article': '41',
            'release': 'P.O.E. Alcance - 31/12/2013 (F.E. 06/04/2015)',
            'date': 'P.O.E. Alcance Ocho - 29/12/2023'
        },
        {
            'id': '13',
            'name': 'Ley Estatal de Derechos',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/2024/13%20Ley%20Estatal%20de%20Derechos.pdf',
            'article': '41',
            'release': 'P.O.E. - 31/12/2015 (VOLUMEN 1)',
            'date': 'P.O.E. Alcance Trece - 29/12/2023'
        },
        {
            'id': '14',
            'name': 'Presupuesto de Egresos del Estado de Hidalgo para el ejercicio 2024',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/2024/14%20Presupuesto_Egresos_2024.pdf',
            'article': '77',
            'release': 'P.O.E. Alcance Once 29/12/2023',
            'date': ''
        },
        {
            'id': '15',
            'name': 'Reglamento interior de la Secretaría de Hacienda',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/2024/RISH%2024-04-24.pdf',
            'article': '50',
            'release': 'P.O.E. Alcance Siete - 28/07/2023',
            'date': 'P.O.E. 28/02/2024'
        },
        {
            'id': '16',
            'name': 'Convenio de Adhesión al Sistema Nacional de Coordinación Fiscal',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/16%20Convenio%20de%20Adhesion%20al%20Sistema%20Nacional%20de%20Coordinacion%20Fiscal.pdf',
            'article': 'C-12',
            'release': 'D.O.F. 28/12/1979',
            'date': ''
        },
        {
            'id': '17',
            'name': 'Anexo Núm. 1 al Convenio de Adhesión al Sistema Nacional de Coordinación Fiscal',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/17%20Anexo%20Num%201%20al%20Convenio%20de%20Adhesion%20al%20Sistema%20Nacional%20de%20Coordinacion%20Fiscal.pdf',
            'article': 'S/C',
            'release': 'D.O.F. 28/12/1979',
            'date': ''
        },
        {
            'id': '18',
            'name': 'Anexo Núm. 2 al Convenio de Adhesión al Sistema Nacional de Coordinación Fiscal',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/18%20Anexo%20Num%202%20al%20Convenio%20de%20Adhesion%20al%20Sistema%20Nacional%20de%20Coordinacion%20Fiscal.pdf',
            'article': 'C-2',
            'release': 'D.O.F. 04/02/1982',
            'date': ''
        },
        {
            'id': '19',
            'name': 'Anexo Núm. 3 al Convenio de Adhesión al Sistema Nacional de Coordinación Fiscal',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/19%20Anexo%20Num%203%20al%20Convenio%20de%20Adhesion%20al%20Sistema%20Nacional%20de%20Coordinacion%20Fiscal.pdf',
            'article': 'C-6',
            'release': 'D.O.F. 02/12/1980',
            'date': ''
        },
        {
            'id': '20',
            'name': 'Anexo Núm. 4 al Convenio de Adhesión al Sistema Nacional de Coordinación Fiscal',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/20%20Anexo%20Num%204%20al%20Convenio%20de%20Adhesion%20al%20Sistema%20Nacional%20de%20Coordinacion%20Fiscal.pdf',
            'article': 'C-9',
            'release': 'D.O.F. 13/10/1981',
            'date': ''
        },
        {
            'id': '21',
            'name': 'Anexo Núm. 5 al Convenio de Adhesión al Sistema Nacional de Coordinación Fiscal',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/21%20Anexo%20Num%205%20al%20Convenio%20de%20Adhesion%20al%20Sistema%20Nacional%20de%20Coordinacion%20Fiscal.pdf',
            'article': 'C-3',
            'release': 'D.O.F. 12/07/1993',
            'date': ''
        },
        {
            'id': '22',
            'name': 'Anexo Núm. 6 al Convenio de Adhesión al Sistema Nacional de Coordinación Fiscal',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/22%20Anexo%20Num%206%20al%20Convenio%20de%20Adhesion%20al%20Sistema%20Nacional%20de%20Coordinacion%20Fiscal.pdf',
            'article': 'C-7',
            'release': 'D.O.F. 05/03/1996',
            'date': ''
        },
        {
            'id': '23',
            'name': 'Convenio de colaboración administrativa en Materia Fiscal Federal',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/23%20Convenio%20de%20colaboracion%20administrativa%20en%20Materia%20Fiscal%20Federal.pdf',
            'article': 'C-36',
            'release': 'D.O.F. 28/07/2015 - P.O.E. 03/08/2015',
            'date': ''
        },
        {
            'id': '24',
            'name': 'Acuerdo por el que se modifica el Convenio de Colaboración Administrativa en Materia Fiscal Federal',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/24%20ACUERDO%20por%20el%20que%20se%20modifica%20el%20Convenio%20de%20Colaboracion%20Administrativa%20en%20Materia%20Fiscal%20Federal%20con%20Hidalgo.pdf',
            'article': '',
            'release': 'D.O.F. 30/04/2020 - P.O.E. Alcance Dos 08/05/2020',
            'date': ''
        },
        {
            'id': '25',
            'name': 'Anexo 5 al Convenio de Colaboración Administrativa en Materia Fiscal Federal',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/24%20Anexo%205%20al%20Convenio%20de%20Colaboracion%20Administrativa%20en%20Materia%20Fiscal%20Federal.pdf',
            'article': 'C-8',
            'release': 'D.O.F. 20/05/2004',
            'date': ''
        },
        {
            'id': '26',
            'name': 'Anexo 8 al Convenio de Colaboración Administrativa en Materia Fiscal Federal',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/25%20Anexo%208%20al%20Convenio%20de%20Colaboracion%20Administrativa%20en%20Materia%20Fiscal%20Federal.pdf',
            'article': 'C-19',
            'release': 'D.O.F. 26/05/2017 - P.O.E. 05/06/2017',
            'date': ''
        },
        {
            'id': '27',
            'name': 'Anexo 19 al Convenio de Colaboración Administrativa en Materia Fiscal Federal',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/26%20Anexo%2019%20al%20Convenio%20de%20Colaboracion%20Administrativa%20en%20Materia%20Fiscal%20Federal.pdf',
            'article': 'C-24',
            'release': 'D.O.F. 15/04/2014 - P.O.E. 21/04/2014',
            'date': ''
        },
        {
            'id': '28',
            'name': 'Acuerdo que modifica el Anexo 19 al Convenio de Colaboración Administrativa en Materia Fiscal Federal',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/28%20ACUERDO%20por%20el%20que%20se%20modifica%20el%20Anexo%2019%20al%20Convenio%20de%20Colaboracion%20Administrativa%20en%20Materia%20Fiscal%20Federal%20con%20Hidalgo.pdf',
            'article': '',
            'release': 'D.O.F. 30/04/2020 - P.O.E. Alcance Dos 08/05/2020',
            'date': ''
        },
        {
            'id': '29',
            'name': 'Coordinación en Materia de Derechos',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/27%20Coordinacion%20en%20Materia%20de%20Derechos.pdf',
            'article': '5',
            'release': 'P.O.E. - 04/07/1983',
            'date': ''
        },
        {
            'id': '30',
            'name': 'Declaratoria de Coordinación en Materia Federal de Derechos',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/28%20Declaratoria%20de%20Coordinacion%20en%20Materia%20Federal%20de%20Derechos.pdf',
            'article': 'D-3',
            'release': '',
            'date': ''
        },
        {
            'id': '31',
            'name': 'Manual de Contabilidad Gubernamental',
            'url': '../files/servicios/pdf/ProcuraduriaFiscal/31%20Manual%20de%20Contabilidad%20Gubernamental.pdf',
            'article': '',
            'release': '',
            'date': ''
        }
    ]
}