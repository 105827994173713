export const ejercicioPrepEgre18Object = {
    'nombre': 'Ejercicio del Presupuesto 2018',
        'tipo': '2',
        'servidor': '1',
        'seccion': [
            {
                'titulo': '',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Circular No. 055',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Circular%20No%2055.pdf'
                    },
                    {
                        'nombreLink':'Circular No. SFP-SE-02-0238/2018',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Circular%20No%20SFP%20SE%2002%200238%202018.pdf'
                    },
                    {
                        'nombreLink':'Circular No. 045',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Circular%20No%20045.pdf'
                    },
                    {
                        'nombreLink':'Circular No. C-01/2018',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Circular%20No%20C%2001%202018.pdf'
                    },
                    {
                        'nombreLink':'Circular No. 035',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Circular%20No%2035.pdf'
                    },
                    {
                        'nombreLink':'Circular No. 031',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Circular%20No%20031.pdf'
                    }
                ]
            },
            {
                'titulo': '',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Manual de Normas y Lineamientos para el Ejercicio del Gasto de Operación de los Programas y Proyectos para el ejercicio 2018',
                        'url': '#',
                        'urldos': [
                            {
                                'descripcion': 'Anexo A1',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GO/Anexo%20A1.pdf'
                            },
                            {
                                'descripcion': 'Anexo A2',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GO/Anexo%20A2.pdf'
                            },
                            {
                                'descripcion': 'Anexo B1',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GO/Anexo%20B1.pdf'
                            },
                            {
                                'descripcion': 'Anexo J',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GO/Anexo%20J.pdf'
                            },
                            {
                                'descripcion': 'Anexo K',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GO/Anexo%20K.pdf'
                            },
                            {
                                'descripcion': 'Anexo L',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GO/Anexo%20L.pdf'
                            },
                            {
                                'descripcion': 'Anexo M',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GO/Anexo%20M.pdf'
                            },
                            {
                                'descripcion': 'Anexo N',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GO/Anexo%20N.pdf'
                            },
                            {
                                'descripcion': 'Anexo 01',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GO/Anexo%20O1.pdf'
                            },
                            {
                                'descripcion': 'Instrucciones Generales Gasto de Operación',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GO/Instrucciones%20generales%20Gasto%20de%20Operacion.pdf'
                            }
                        ]
                    }
                ]
            },
            {
                'titulo': '',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Manual de Normas y Lineamientos para el Ejercicio de los Recursos del Programa de Inversión para el ejercicio 2018',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Manual%20de%20Normas%20y%20Lineamientos%20para%20el%20Ejercicio%20de%20los%20Recursos%20del%20Programa%20de%20Inversion%20para%20el%20ejercicio%202018.pdf',
                        'urldos': [
                            {
                                'descripcion': 'Anexo A1',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GI/Anexo%20A1.pdf'
                            },
                            {
                                'descripcion': 'Anexo A2',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GI/Anexo%20A2.pdf'
                            },
                            {
                                'descripcion': 'Anexo C1',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GI/Anexo%20C1.pdf'
                            },
                            {
                                'descripcion': 'Anexo C10',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GI/Anexo%20C10.pdf'
                            },
                            {
                                'descripcion': 'Anexo C11',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GI/Anexo%20C11.pdf'
                            },
                            {
                                'descripcion': 'Anexo C12',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GI/Anexo%20C12.pdf'
                            },
                            {
                                'descripcion': 'Anexo C13',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GI/Anexo%20C13.pdf'
                            },
                            {
                                'descripcion': 'Anexo C2',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GI/Anexo%20C2.pdf'
                            },
                            {
                                'descripcion': 'Anexo C3',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GI/Anexo%20C3.pdf'
                            },
                            {
                                'descripcion': 'Anexo C4',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GI/Anexo%20C4.pdf'
                            },
                            {
                                'descripcion': 'Anexo C5',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GI/Anexo%20C5.pdf'
                            },
                            {
                                'descripcion': 'Anexo C6',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GI/Anexo%20C6.pdf'
                            },
                            {
                                'descripcion': 'Anexo C7 (1 a 5 partidas)',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GI/Anexo%20C7%20(1%20a%205%20partidas).pdf'
                            },
                            {
                                'descripcion': 'Anexo C7 (17 a 26 partidas)',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GI/Anexo%20C7%20(6%20a%2016%20partidas).pdf'
                            },
                            {
                                'descripcion': 'Anexo C7 (6 a 16 partidas)',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GI/Anexo%20C7%20(17%20-%2026%20partidas).pdf'
                            },
                            {
                                'descripcion': 'Anexo C8',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GI/Anexo%20C8.pdf'
                            },
                            {
                                'descripcion': 'Anexo C9',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GI/Anexo%20C9.pdf'
                            },
                            {
                                'descripcion': 'Anexo Instrucciones generales Programa de Inversión',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2018/Anexos%20GI/Instrucciones%20generales%20Programa%20de%20Inversion.pdf'
                            }
                        ]
                    }
                ]
            }
        ]
    }