export const servicios = [
    {
        "titulo": 'Servicios',
        "servicio":[
            {
                "subtitulo": 'Servicios a la Ciudadania',
                "data":[
                    {
                        "url":"https://portaltributario.hidalgo.gob.mx/",
                        "icono":"fa-solid fa-circle-dollar-to-slot",
                        "nombre":"Portal Tributario",
                        "descripcion":"Portal de Trámites y Servicios de impuestos locales, Contribuciones Federales Coordinadas e información para contribuyentes"
                    },
                    {
                        "url":"https://ehacienda.hidalgo.gob.mx/TRANSPARENCIAFINANZAS/VentanillaCiudadana#",
                        "icono":"fa-solid fa-hand-holding-dollar",
                        "nombre":"Impuestos y Derechos Hidalgo",
                        "descripcion":"Ventanilla Ciudadana"
                    },
                    {
                        "url":"https://efinanzas.hidalgo.gob.mx/SIFACP/",
                        "icono":"fa-solid fa-file-invoice",
                        "nombre":"Consulta tu Factura",
                        "descripcion":"Servicio de consulta para que proveedores y contratistas conozcan el estatus en el que se encuentran sus facturas emitidas al Gobierno del Estado de Hidalgo"
                    },
                    {
                        "url":"https://ehacienda.hidalgo.gob.mx/DigitalSignCTI",
                        "icono":"fa-solid fa-file-shield",
                        "nombre":"Verificación de documentos",
                        "descripcion":"Verificación de integridad y autoría de documentos electrónicos"
                    },
                    {
                        "url":"RematesenLinea",
                        "icono":"fa-solid fa-gavel",
                        "nombre":"Remates en Linea",
                        "descripcion":"Servicio para llevar a cabo el remate de bienes embargados a contribuyentes que han incumplido con el pago de sus adeudos Fiscales"
                    },
                    {
                        "url":"/AsociacionesPublicoPrivadas",
                        "icono":"fa-solid fa-people-roof",
                        "nombre":"Asociaciones Público Privadas",
                        "descripcion":"Convocatorias y Licitaciones Públicas"
                    },
                    {
                        "url":"https://portaltributario.hidalgo.gob.mx/formatos-fiscales.html",
                        "icono":"fa-solid fa-file-contract",
                        "nombre":"Formatos Fiscales",
                        "descripcion":"Formatos utilizados en los diversos trámites tributarios"
                    }
                ]
            },
            {
                "subtitulo": 'Rendición de Cuentas',
                "data":[
                    {
                        //"url":"DocumentacionArchivo",
                        "url":"https://ehacienda.hidalgo.gob.mx/Transparencia/DocArchivo#",
                        "icono":"fa-solid fa-boxes-packing",
                        "nombre":"Documentación de Archivo",
                        "descripcion":""
                    },
                    {
                        "url":"InformacionFinancieraSRFT",
                        "icono":"fa-solid fa-file-zipper",
                        "nombre":"Información Financiera SRFT",
                        "descripcion":""
                    },
                    {
                        "url":"InformacionFinancieraSFU",
                        "icono":"fa-solid fa-file-lines",
                        "nombre":"Información Financiera SFU",
                        "descripcion":""
                    },
                    {
                        "url":"CuentaHaciendaPublica",
                        "icono":"fa-solid fa-file-invoice-dollar",
                        "nombre":"Cuenta de la Hacienda Pública Estatal",
                        "descripcion":""
                    },
                    {
                        "url":"Ramo23",
                        "icono":"fa-solid fa-wallet",
                        "nombre":"Ramo 23",
                        "descripcion":""
                    }
                ]
            },
            {
                "subtitulo": 'Servicios Internos, Dependencias del Poder Ejecutivo y OPDs',
                "data":[
                    {
                        "url":"QuienesSomos",
                        "icono":"fa-solid fa-address-card",
                        "nombre":"¿Quiénes somos?",
                        "descripcion":""
                    },
                    {
                        "url":"CodigodeEtica",
                        "icono":"fa-solid fa-people-group",
                        "nombre":"Comité de Ética",
                        "descripcion":""
                    },
                    {
                        "url":"https://hacienda.hidalgo.gob.mx/files/servicios/pdf/ManualdeServiciosalPublico/Manual%20de%20Servicios%20al%20Publico.pdf",
                        "icono":"fa-brands fa-readme",
                        "nombre":"Manual de Servicios",
                        "descripcion":""
                    },
                    {
                        "url":"CircularesSecretaria",
                        "icono":"fa-solid fa-copy",
                        "nombre":"Circulares Secretaría de Hacienda",
                        "descripcion":""
                    },
                    {
                        "url":"CircularesSubseEgresos",
                        "icono":"fa-solid fa-copy",
                        "nombre":"Circulares Subsecretaría de Egresos",
                        "descripcion":""
                    },
                    {
                        "url":"CircularesDireccEgresosyTesoreria",
                        "icono":"fa-solid fa-copy",
                        "nombre":"Circulares Dirección General de Egresos y/o Dirección General de Tesorería",
                        "descripcion":""
                    },
                    {
                        "url":"https://ehacienda.hidalgo.gob.mx/SIAUUV2/Login",
                        "icono":"fa-solid fa-window-restore",
                        "nombre":"Portal de Servicios para Dependencias, OPD's y Municipios",
                        "descripcion":"Ventanilla de servicios y trámites para validadores de CFDI's, enteros de deductivo y retenciones, Cuenta Pública Estatal"
                    },
                    {
                        "url":"https://ehacienda.hidalgo.gob.mx/SIAUUV2",
                        "icono":"fa-solid fa-window-restore",
                        "nombre":"Portal de Servicios Internos",
                        "descripcion":"Portal dirigido a trabajadores del Gobierno del Estado de Hidalgo"
                    },
                    // {
                    //     "url":"",
                    //     "icono":"fa-solid fa-chart-pie",
                    //     "nombre":"SIPPED",
                    //     "descripcion":""
                    // },
                    {
                        "url":"ProcuraduriaFiscal",
                        // "url":"https://s-finanzas.hidalgo.gob.mx/servicios/procuFiscal.html",
                        "icono":"fa-solid fa-landmark",
                        "nombre":"Procuraduría Fiscal",
                        "descripcion":""
                    },
                    {
                        "url":"/Secretaría/PaqueteHacendario",
                        "icono":"fa-regular fa-folder-open",
                        "nombre":"Paquete Hacendario",
                        "descripcion":""
                    },
                    // {
                    //     "url":"/Secretaría/PaqueteHacendario",
                    //     "icono":"fa-regular fa-folder-open",
                    //     "nombre":"Paquete Hacendario",
                    //     "descripcion":""
                    // },
                    // {
                    //     "url":"PaqueteHacendario2024",
                    //     "icono":"fa-regular fa-folder-open",
                    //     "nombre":"Paquete Hacendario 2024",
                    //     "descripcion":""
                    // },
                    // {
                    //     "url":"PaqueteHacendario2023",
                    //     "icono":"fa-solid fa-folder",
                    //     "nombre":"Paquete Hacendario 2023",
                    //     "descripcion":""
                    // },
                    // {
                    //     "url":"PaqueteHacendario2022",
                    //     "icono":"fa-solid fa-folder",
                    //     "nombre":"Paquete Hacendario 2022",
                    //     "descripcion":""
                    // },
                    {
                        "url":"PaqueteHacendario2021",
                        "icono":"fa-solid fa-folder",
                        "nombre":"Paquete Hacendario 2021",
                        "descripcion":""
                    },
                    {
                        "url":"PaqueteHacendario2020",
                        "icono":"fa-solid fa-folder",
                        "nombre":"Paquete Hacendario 2020",
                        "descripcion":""
                    },
                    {
                        "url":"PaqueteHacendario2019",
                        "icono":"fa-solid fa-folder",
                        "nombre":"Paquete Hacendario 2019",
                        "descripcion":""
                    },
                    {
                        "url":"PaqueteHacendario2018",
                        "icono":"fa-solid fa-folder",
                        "nombre":"Paquete Hacendario 2018",
                        "descripcion":""
                    },
                    {
                        "url":"EjerciciodelPresupuesto2024",
                        "icono":"fa-solid fa-folder",
                        "nombre":"Ejercicio del Presupuesto 2024",
                        "descripcion":""
                    },
                    {
                        "url":"EjerciciodelPresupuesto2023",
                        "icono":"fa-solid fa-folder",
                        "nombre":"Ejercicio del Presupuesto 2023",
                        "descripcion":""
                    },
                    {
                        "url":"EjerciciodelPresupuesto2022",
                        "icono":"fa-solid fa-file-circle-check",
                        "nombre":"Ejercicio del Presupuesto 2022",
                        "descripcion":""
                    },
                    {
                        "url":"EjerciciodelPresupuesto2021",
                        "icono":"fa-solid fa-file",
                        "nombre":"Ejercicio del Presupuesto 2021",
                        "descripcion":""
                    },
                    {
                        "url":"EjerciciodelPresupuesto2020",
                        "icono":"fa-solid fa-file",
                        "nombre":"Ejercicio del Presupuesto 2020",
                        "descripcion":""
                    },
                    {
                        "url":"EjerciciodelPresupuesto2019",
                        "icono":"fa-solid fa-file",
                        "nombre":"Ejercicio del Presupuesto 2019",
                        "descripcion":""
                    },
                    {
                        "url":"EjerciciodelPresupuesto2018",
                        "icono":"fa-solid fa-file",
                        "nombre":"Ejercicio del Presupuesto 2018",
                        "descripcion":""
                    },
                    {
                        "url":"EjerciciodelPresupuesto2017",
                        "icono":"fa-solid fa-file",
                        "nombre":"Ejercicio del Presupuesto 2017",
                        "descripcion":""
                    },
                    {
                        "url":"EjerciciodelPresupuesto2016",
                        "icono":"fa-solid fa-file",
                        "nombre":"Ejercicio del Presupuesto 2016",
                        "descripcion":""
                    },
                    {
                        "url":"EjerciciodelPresupuesto2015",
                        "icono":"fa-solid fa-file",
                        "nombre":"Ejercicio del Presupuesto 2015",
                        "descripcion":""
                    }
                    // {
                    //     "url":"SeguimientoaAuditorias",
                    //     "icono":"fa-solid fa-file-arrow-up",
                    //     "nombre":"Seguimiento a Auditorias",
                    //     "descripcion":""
                    // }
                ]
            }
        ]
    }
]