export const paqueteHacendArray = [
    {
        "titulo": 'Paquete Hacendario',
        "rubro":[
            {
                "subtitulo": 'Paquete Hacendario 2022 - 2028',
                "data":[
                    {
                        "url":"https://hacienda.hidalgo.gob.mx/Servicios/PaqueteHacendario2024",
                        "icono":"fa-solid fa-window-restore",
                        "nombre":"Paquete Hacendario 2024",
                        "descripcion":""
                    },
                    {
                        "url":"https://hacienda.hidalgo.gob.mx/Servicios/PaqueteHacendario2023",
                        "icono":"fa-solid fa-window-restore",
                        "nombre":"Paquete Hacendario 2023",
                        "descripcion":""
                    },
                    {
                        "url":"https://hacienda.hidalgo.gob.mx/Servicios/PaqueteHacendario2022",
                        "icono":"fa-solid fa-window-restore",
                        "nombre":"Paquete Hacendario 2022",
                        "descripcion":""
                    }
                ]
            }
        ]
    }
]