export const links = [
    {
        "nombre" : "Transparencia",
        "link" : "/Transparencia",
        "submenu" : [
            {
                "subMenuNombre" : "48 Rubros",
                "subMenuUrl" : "/Transparencia/Rubros"
            },
            {
                "subMenuNombre" : "Transparencia Financiera",
                "subMenuUrl" : "/TransparenciaSecretaria"
            },
            {
                "subMenuNombre" : "Estados Financieros",
                "subMenuUrl" : "/Secretaría/EstadosFinancieros"
            },
            {
                "subMenuNombre" : "Informes de Resultados de la Cuenta Pública del Poder Ejecutivo",
                "subMenuUrl" : "/InformedeResultadosCuentaPublica"
            },
            // {
            //     "subMenuNombre" : "Armonización Contable",
            //     "subMenuUrl" : "https://ehacienda.hidalgo.gob.mx/Transparencia/Armonizacion"
            // },
            {
                "subMenuNombre" : "Armonización Contable",
                "subMenuUrl" : "/Armonizacion/ArmonizacionContable"
            },
            {
                "subMenuNombre" : "Formatos a que hace referencia la LDFEFyM",
                "subMenuUrl" : "/FormatosDisciplinaFinanciera"
            },
            {
                "subMenuNombre" : "Informe de la Hacienda Pública",
                "subMenuUrl" : "/InformeHaciendaPublica"
            }
        ]
    },
    // {
    //     "nombre" : "Armonización Contable",
    //     "link" : "https://ehacienda.hidalgo.gob.mx/Transparencia/Armonizacion"
    // },
    {
        "nombre" : "Armonización Contable",
        "link" : "/Armonizacion/ArmonizacionContable"
    },
    {
        "nombre" : "Servicios",
        "link" : "/Servicios",
    },
    {
        "nombre" : "Notificaciones",
        "link" : "https://portaltributario.hidalgo.gob.mx/notificaci%c3%b3n-por-estrados.html",
    },
    {
        "nombre" : "IHDEP",
        "link" : "/IHDEP",
    },
]