export const rubrosHistoricos = [
    {
        "anio" : "2024",
        "descripcion" : "Disponibilidad de los trimestres anteriores",
        "trimestres" : [
            {
                "trimestre" : "Primer Trimestre",
                "url" : "../../../../files/transparencia/zip/Art 69 primer trimestre 2024.zip"
            },
            {
                "trimestre" : "Segundo Trimestre",
                "url" : "../../../../files/transparencia/zip/Art 69 segundo trimestre 2024.zip"
            }
        ]
    },
    {
        "anio" : "2023",
        "descripcion" : "Disponibilidad de los trimestres anteriores",
        "trimestres" : [
            {
                "trimestre" : "Primer Trimestre",
                "url" : "../../../../files/transparencia/zip/Art%2069%20primer%20trimestre%202023.zip"
            },
            {
                "trimestre" : "Segundo Trimestre",
                "url" : "../../../../files/transparencia/zip/Art%2069%20segundo%20trimestre%202023.zip"
            },
            {
                "trimestre" : "Tercer Trimestre",
                "url" : "../../../../files/transparencia/zip/Art%2069%20tercer%20trimestre%202023.zip"
            },
            {
                "trimestre" : "Cuarto Trimestre",
                "url" : "../../../../files/transparencia/zip/Art%2069%20cuarto%20trimestre%202023.zip"
            }
        ]
    },
    {
        "anio" : "2022",
        "descripcion" : "Disponibilidad de los trimestres anteriores",
        "trimestres" : [
            {
                "trimestre" : "Primer Trimestre",
                "url" : "../../../../files/transparencia/zip/Art%2069%20primer%20trimestre%202022.zip"
            },
            {
                "trimestre" : "Segundo Trimestre",
                "url" : "../../../../files/transparencia/zip/Art%2069%20segundo%20trimestre%202022.zip"
            },
            {
                "trimestre" : "Tercer Trimestre",
                "url" : "../../../../files/transparencia/zip/Art%2069%20tercer%20trimestre%202022.rar"
            },
            {
                "trimestre" : "Cuarto Trimestre",
                "url" : "../../../../files/transparencia/zip/Art%2069%20tercer%20trimestre%202022.zip"
            }
        ]
    },
    {
        "anio" : "2021",
        "descripcion" : "Disponibilidad de los trimestres anteriores",
        "trimestres" : [
            {
                "trimestre" : "Primer Trimestre",
                "url" : "../../../../files/transparencia/zip/Art%2069%20primer%20trimestre%202021.zip"
            },
            {
                "trimestre" : "Segundo Trimestre",
                "url" : "../../../../files/transparencia/zip/Art%2069%20segundo%20trimestre%202021.zip"
            },
            {
                "trimestre" : "Tercer Trimestre",
                "url" : "../../../../files/transparencia/zip/Art%2069%20tercer%20trimestre%202021.zip"
            },
            {
                "trimestre" : "Cuarto Trimestre",
                "url" : "../../../../files/transparencia/zip/Art%2069%20cuarto%20trimestre%202021.zip"
            }
        ]
    },
    {
        "anio" : "2020",
        "descripcion" : "Disponibilidad de los trimestres anteriores",
        "trimestres" : [
            {
                "trimestre" : "Primer Trimestre",
                "url" : "../../../../files/transparencia/zip/Art%2069%20primer%20trimestre%202020.zip"
            },
            {
                "trimestre" : "Segundo Trimestre",
                "url" : "../../../../files/transparencia/zip/Art%2069%20segundo%20trimestre%202020.zip"
            },
            {
                "trimestre" : "Tercer Trimestre",
                "url" : "../../../../files/transparencia/zip/Art%2069%20tercer%20trimestre%202020.zip"
            },
            {
                "trimestre" : "Cuarto Trimestre",
                "url" : "../../../../files/transparencia/zip/Art%2069%20cuarto%20trimestre%202020.zip"
            }
        ]
    },
    {
        "anio" : "2019",
        "descripcion" : "Disponibilidad de los trimestres anteriores",
        "trimestres" : [
            {
                "trimestre" : "Primer Trimestre",
                "url" : "../../../../files/transparencia/zip/Art%2069%20primer%20trimestre%202019.zip"
            },
            {
                "trimestre" : "Segundo Trimestre",
                "url" : "../../../../files/transparencia/zip/Art%2069%20segundo%20trimestre%202019.zip"
            },
            {
                "trimestre" : "Tercer Trimestre",
                "url" : "../../../../files/transparencia/zip/Art%2069%20tercer%20trimestre%202019.zip"
            },
            {
                "trimestre" : "Cuarto Trimestre",
                "url" : "../../../../files/transparencia/zip/Art%2069%20cuarto%20trimestre%202019.zip"
            }
        ]
    },
    {
        "anio" : "2018",
        "descripcion" : "Disponibilidad de los trimestres anteriores",
        "trimestres" : [
            {
                "trimestre" : "Primer Trimestre",
                "url" : "../../../../files/transparencia/zip/primer%20trimestre%20art69%20SFP%20FINAL.zip"
            },
            {
                "trimestre" : "Segundo Trimestre",
                "url" : "../../../../files/transparencia/zip/Art%2069%20SEGUNDO%20TRIMESTRE%202018%20SFP.zip"
            },
            {
                "trimestre" : "Tercer Trimestre",
                "url" : "../../../../files/transparencia/zip/Art%2069%20TERCER%20TRIMESTRE%202018%20SFP%20FINAL.zip"
            },
            {
                "trimestre" : "Cuarto Trimestre",
                "url" : "../../../../files/transparencia/zip/Art%2069%20CUARTO%20TRIMESTRE%202018%20SFP.zip"
            }
        ]
    },
    {
        "anio" : "2015 - 2017",
        "descripcion" : "Ejercicios Anteriores",
        "trimestres" : [
            {
                "trimestre" : "Plataforma Nacional",
                "url" : "https://consultapublicamx.inai.org.mx/vut-web/?idSujetoObigadoParametro=2131&idEntidadParametro=13&idSectorParametro=21"
            }
        ]
    }
    // {
    //     "anio" : "Información Histórica 21 Rubros",
    //     "descripcion" : "Ejercicios Anteriores",
    //     "trimestres" : [
    //         {
    //             "trimestre" : "Descargar",
    //             "url" : ""
    //         }
    //     ]
    // }
]