export const documentacionArchivoObject = {
    'nombre': 'Documentación aArchivo',
    'tipo': '2',
    'servidor': '1',
    'seccion': [
        {
            'titulo': '',
            'subtitulo': '',
            'docs': [
                {
                    'nombreLink':'Cuadro General de Clasificación Archivística',
                    'url': 'servicios/pdf/DocumentacionArchivo/Cuadro%20General%20de%20Clasificacion%20Archivistica_2019.pdf',
                    'data': {
                        'urlxlsx': '',
                        'urlpdf': ''
                    }
                },
            ]
        }
    ]
}