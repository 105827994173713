export const anexosProyectoDecretoPrepEgre24Object = 
    {
        'nombre': 'Anexos del Proyecto de Decreto de Presupuesto de Egresos 2024',
        'tipo': '1',
        'servidor': '1',
        'seccion': [
            {   
                'id': '0',
                'docs': [
                    {
                        'titulo': 'Anexos del presupuesto',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Anexo 1. Gasto neto total por ramo',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_001_Anexo%20%201%20-%20Gasto%20neto%20total%20por%20ramo.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 2. Presupuesto por ente público',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_002_Anexo%20%202%20-%20Presupuesto%20por%20ente%20publico.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 3. Poder Legislativo por capítulo de gasto',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_003_Anexo%20%203%20-%20Poder%20Legislativo%20por%20capitulo%20de%20gasto.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 4. Poder Judicial por capítulo de gasto',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_004_Anexo%20%204%20-%20Poder%20Judicial%20por%20capitulo%20de%20gasto.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 5. Presupuesto por ente autónomo',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_005_Anexo%20%205%20-%20Presupuesto%20por%20ente%20autonomo.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 6. Instituto Estatal Electoral por capítulo de gasto',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_006_Anexo%20%206%20-%20Instituto%20Estatal%20Electoral%20por%20capitulo%20de%20gasto.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 7. Gasto previsto para el financiamiento de los partidos políticos',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_007_Anexo%20%207%20-%20Gasto%20previsto%20para%20el%20financiamiento%20de%20los%20partidos%20politicos.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 8. Comisión de Derechos Humanos por capítulo de gasto',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_008_Anexo%20%208%20-%20Comision%20de%20Derechos%20Humanos%20por%20capitulo%20de%20gasto.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 9. Instituto de Transparencia, Acceso a la Información Pública Gubernamental y Protección de Datos Personales del Estado de Hidalgo por capítulo de gasto',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_009_Anexo%20%209%20-%20Instituto%20de%20Transparencia,%20Acceso%20a%20la%20Informacion%20Publica%20Gubernamental%20y%20Proteccion%20de%20Datos%20Personales%20del%20Estado%20de%20Hidalgo%20por%20capitulo%20de%20gasto.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 10. Tribunal Electoral del Estado de Hidalgo por capítulo de gasto',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_010_Anexo%2010%20-%20Tribunal%20Electoral%20del%20Estado%20de%20Hidalgo%20por%20capitulo%20de%20gasto.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 11. Administración centralizada del Poder Ejecutivo por capítulo de gasto',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_011_Anexo%2011%20-%20Administracion%20centralizada%20del%20Poder%20Ejecutivo%20por%20capitulo%20de%20gasto.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 12. Administración centralizada y administración pública paraestatal',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_012_Anexo%2012%20-%20Administracion%20centralizada%20y%20administracion%20publica%20paraestatal.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 13. Administración descentralizada por capítulo de gasto',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_013_Anexo%2013%20-%20Administracion%20descentralizada%20por%20capitulo%20de%20gasto.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 14. Entidades sujetas a control presupuestal directo y entidades sujetas a control presupuestal indirecto',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_014_Anexo%2014%20-%20Entidades%20sujetas%20a%20control%20presupuestal%20directo%20y%20entidades%20sujetas%20a%20control%20presupuestal%20indirecto.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 15. Poder Ejecutivo prioridades de gasto',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_015_Anexo%2015%20-%20Poder%20Ejecutivo%20prioridades%20de%20gasto.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 16. Gastos obligatorios del Poder Ejecutivo',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_016_Anexo%2016%20-%20Gastos%20obligatorios%20del%20Poder%20Ejecutivo.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 17. Poder Ejecutivo por capítulo y concepto de gasto',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_017_Anexo%2017%20-%20Poder%20Ejecutivo%20por%20capitulo%20y%20concepto%20de%20gasto.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 18. Recursos del Ramo federal 33, aportaciones federales a entidades federativas y municipios',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_018_Anexo%2018%20-%20Recursos%20del%20ramo%20federal%2033,%20aportaciones%20federales%20a%20entidades%20federativas%20y%20municipios.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 19. Aplicación y destino de los fondos de recursos federalizados provenientes del ramo 33',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_019_Anexo%2019%20-%20Aplicacion%20y%20destino%20de%20los%20fondos%20de%20recursos%20federalizados%20provenientes%20del%20ramo%2033.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 20. Transferencias totales de recursos a municipios por fondo',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_020_Anexo%2020%20-%20Transferencias%20totales%20de%20recursos%20a%20municipios%20por%20fondo.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 21. Participaciones a municipios por fondo',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_021_Anexo%2021%20-%20Participaciones%20a%20municipios%20por%20fondo.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 22. Aportaciones a municipios por fondo',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_022_Anexo%2022%20-%20Aportaciones%20a%20municipios%20por%20fondo.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 23. Presupuesto para la educación',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_023_Anexo%2023%20-%20Presupuesto%20para%20la%20educacion.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 24. Presupuesto para la salud',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_024_Anexo%2024%20-%20Presupuesto%20para%20la%20salud.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 25. Presupuesto para la asistencia social',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_025_Anexo%2025%20-%20Presupuesto%20para%20la%20asistencia%20social.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 26. Presupuesto para la seguridad pública',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_026_Anexo%2026%20-%20Presupuesto%20para%20la%20seguridad%20publica.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 27. Presupuesto para la comunicación social',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_027_Anexo%2027%20-%20Presupuesto%20para%20la%20comunicacion%20social.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 28. Subsidios y subvenciones',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_028_Anexo%2028%20-%20Subsidios%20y%20subvenciones.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 29. Ayudas sociales a personas',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_029_Anexo%2029%20-%20Ayudas%20sociales%20a%20personas.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 30. Transferencias a programas de capacitación',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_030_Anexo%2030%20-%20Transferencias%20a%20programas%20de%20capacitacion.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 31. Erogaciones a Instituciones de enseñanza',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_031_Anexo%2031%20-%20Erogaciones%20a%20Instituciones%20de%20ensen%CC%83anza.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 32. Ayudas sociales a instituciones sin fines de lucro',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_032_Anexo%2032%20-%20Ayudas%20sociales%20a%20instituciones%20sin%20fines%20de%20lucro.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 33. Ayudas por desastres naturales y otros siniestros',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_033_Anexo%2033%20-%20Ayudas%20por%20desastres%20naturales%20y%20otros%20siniestros.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 34. Pensiones y jubilaciones',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_034_Anexo%2034%20-%20Pensiones%20y%20jubilaciones.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 35. Límites de percepción ordinaria de los funcionarios del Poder Ejecutivo',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_035_Anexo%2035%20-%20Limites%20de%20percepcion%20ordinaria%20de%20los%20funcionarios%20del%20Poder%20Ejecutivo.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 36. Desglose de gasto en remuneraciones fijas',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_036_Anexo%2036%20-%20Desglose%20de%20gasto%20en%20remuneraciones%20fijas.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 37. Analítico de plazas con desglose en remuneraciones a funcionarios',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_037_Anexo%2037%20-%20Anali%CC%81tico%20de%20plazas%20con%20desglose%20en%20remuneraciones%20a%20funcionarios.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 38. Poder Ejecutivo analítico de plazas',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_038_Anexo%2038%20-%20Poder%20Ejecutivo%20analitico%20de%20plazas.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 39. Analítico de plazas de cuerpos de seguridad',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_039_Anexo%2039%20-%20Analitico%20de%20plazas%20de%20cuerpos%20de%20seguridad.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 40. Previsiones salariales y económicas',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_040_Anexo%2040%20-%20Provisiones%20salariales%20y%20economicas.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 41. Montos máximos y modo de adjudicación de adquisiciones, arrendamientos y prestación de servicios',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_041_Anexo%2041%20-%20Montos%20maximos%20y%20modo%20de%20adjudicacion%20de%20adquisiciones,%20arrendamientos%20y%20prestacion%20de%20servicios.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 42. Montos máximos y modo de adjudicación de obras publicas y servicios relacionados con las mismas',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_042_Anexo%2042%20-%20Montos%20maximos%20y%20modo%20de%20adjudicacion%20de%20obras%20publicas%20y%20servicios%20relacionados%20con%20las%20mismas.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 43. Endeudamiento neto',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_043_Anexo%2043%20-%20Endeudamiento%20neto.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 44. Asignaciones por servicio anual de deuda',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_044_Anexo%2044%20-%20Asignaciones%20por%20servicio%20anual%20de%20deuda.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 45. Obligaciones garantizadas con fondos federales',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_045_Anexo%2045%20-%20Obligaciones%20garantizadas%20con%20fondos%20federales.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 46. Deuda pública directa por decreto de autorización',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_046_Anexo%2046%20-%20Deuda%20publica%20directa%20por%20decreto%20de%20autorizacion.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 47. Deuda pública directa',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_047_Anexo%2047%20-%20Deuda%20publica%20directa.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 48. Inversión pública directa',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_048_Anexo%2048%20-%20Inversion%20publica%20directa.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 49. Contratos de Obra Pública, Adquisiciones, Arrendamientos y Servicios',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_049_Anexo%2049%20-%20Contratos%20de%20Obra%20Publica,%20Adquisiciones,%20Arrendamientos%20y%20Servicios.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 50. Proyectos de Arrendamientos, Adquisiciones y Prestación de Servicios',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_050_Anexo%2050%20-%20Proyectos%20de%20Arrendamientos,%20Adquisiciones%20y%20Prestacion%20de%20Servicios.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 51. Descripción de riesgos relevantes para las finanzas públicas y propuestas de acción para enfrentarlos',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_051_Anexo%2051%20-%20Descripcion%20de%20riesgos%20relevantes%20para%20las%20finanzas%20publicas%20y%20propuestas%20de%20accion%20para%20enfrentarlos.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 52. Erogaciones plurianuales para proyectos de inversión en infraestructura conforme a lo dispuesto en la Ley reglamentaria',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_052_Anexo%2052%20-%20Erogaciones%20plurianuales%20para%20proyectos%20de%20inversion%20en%20infraestructura%20conforme%20a%20lo%20dispuesto%20en%20la%20Ley%20reglamentaria.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 53. Presupuesto para mujeres y equidad de genero',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_053_Anexo%2053%20-%20Presupuesto%20para%20mujeres%20y%20equidad%C2%A0de%C2%A0genero.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 54. Erogaciones previstas para dar atención a niñas, niños y adolescentes',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_054_Anexo%2054%20-%20Erogaciones%20previstas%20para%20dar%20atencion%20a%20nin%CC%83as,%20nin%CC%83os%20y%20adolescentes.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 55. Previsiones de gasto para la atención de la población indígena',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_055_Anexo%2055%20-%20Previsiones%20de%20gasto%20para%20la%20atencion%20de%20la%20poblacion%20indigena.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 56. Erogaciones previstas para el Desarrollo de los Jovenes',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_056_Anexo%2056%20-%20Erogaciones%20previstas%20para%20el%20Desarrollo%20de%20los%20Jovenes.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 57. Previsiones de gasto para el Desarrollo Agropecuario Sustentable',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_057_Anexo%2057%20-%20Previsiones%20de%20gasto%20para%20el%20Desarrollo%20Agropecuario%20Sustentable.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 58. Previsiones de gasto para la atención de grupos vulnerables',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_058_Anexo%2058%20-%20Previsiones%20de%20gasto%20para%20la%20atencion%20de%20grupos%20vulnerables.pdf'
                                    },
                                    {
                                        'nombre': 'Anexo 59. Previsiones de gasto para la mitigación de los efectos del cambio climático',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/AnexosdelPresupuesto/2024_059_Anexo%2059%20-%20Previsiones%20de%20gasto%20para%20la%20mitigacion%20de%20los%20efectos%20del%20cambio%20climatico.pdf'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Reportes armonizados',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Reporte armonizado 1. Clasificación administrativa',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesArmonizados/2024_060_Reporte%20armonizado%201%20-%20Clasificacion%20administrativa.pdf'
                                    },
                                    {
                                        'nombre': 'Reporte armonizado 2. Clasificación funcional (finalidad, función y subfunción)',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesArmonizados/2024_061_Reporte%20armonizado%202%20-%20Clasificacion%20funcional%20(finalidad,%20funcion%20y%20subfuncion).pdf'
                                    },
                                    {
                                        'nombre': 'Reporte armonizado 3. Gasto programable y no programable',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesArmonizados/2024_062_Reporte%20armonizado%203%20-%20Gasto%20programable%20y%20no%20programable.pdf'
                                    },
                                    {
                                        'nombre': 'Reporte armonizado 4. Clasificación programática',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesArmonizados/2024_063_Reporte%20armonizado%204%20-%20Clasificacion%20programatica.pdf'
                                    },
                                    {
                                        'nombre': 'Reporte armonizado 5. Clasificación económica por tipo de gasto',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesArmonizados/2024_064_Reporte%20armonizado%205%20-%20Clasificacion%20economica%20por%20tipo%20de%20gasto.pdf'
                                    },
                                    {
                                        'nombre': 'Reporte armonizado 6. Clasificación geográfica, recursos identificados por municipio',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesArmonizados/2024_065_Reporte%20armonizado%206%20-%20Clasificacion%20geografica,%20recursos%20identificados%20por%20municipio.pdf'
                                    },
                                    {
                                        'nombre': 'Reporte armonizado 7. Clasificación por objeto del gasto (capítulo, concepto y partida específica)',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesArmonizados/2024_066_Reporte%20armonizado%207%20-%20Clasificacion%20por%20objeto%20del%20gasto%20(capitulo,%20concepto%20y%20partida%20especifica).pdf'
                                    },
                                    {
                                        'nombre': 'Reporte armonizado 8. Programas con recursos concurrentes por orden de gobierno',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesArmonizados/2024_067_Reporte%20armonizado%208%20-%20Programas%20con%20recursos%20concurrentes%20por%20orden%20de%20gobierno.pdf'
                                    },
                                    {
                                        'nombre': 'Reporte armonizado 9. Proyecciones de ingresos 2024-2029',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesArmonizados/2024_068_Reporte%20armonizado%209%20-%20Proyecciones%20de%20ingresos%202024-2029.pdf'
                                    },
                                    {
                                        'nombre': 'Reporte armonizado 10. Proyecciones de egresos 2024-2029',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesArmonizados/2024_069_Reporte%20armonizado%2010%20-%20Proyecciones%20de%20egresos%202024-2029.pdf'
                                    },
                                    {
                                        'nombre': 'Reporte armonizado 11. Resultados de ingresos 2018-2023',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesArmonizados/2024_070_Reporte%20armonizado%2011%20-%20Resultados%20de%20ingresos%202018-2023.pdf'
                                    },
                                    {
                                        'nombre': 'Reporte armonizado 12. Resultados de egresos 2018-2023',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesArmonizados/2024_071_Reporte%20armonizado%2012%20-%20Resultados%20de%20egresos%202018-2023.pdf'
                                    },
                                    {
                                        'nombre': 'Reporte armonizado 13. Informe sobre estudios actuariales',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesArmonizados/2024_072_Reporte%20armonizado%2013%20-%20Informe%20sobre%20estudios%20actuariales.pdf'
                                    }
                                    
                                ]
                            }
                        ]
                    },
                    {
                        'titulo': 'Transversales',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Transversal 1. Erogaciones para la ciencia y tecnología',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/Transversales/2024_073_Transversal%201%20-%20Erogaciones%20para%20la%20ciencia%20y%20tecnologia.pdf'
                                    },
                                    {
                                        'nombre': 'Transversal 2. Derechos Humanos',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/Transversales/2024_074_Transversal%202%20-%20Derechos%20Humanos.pdf'
                                    },
                                    {
                                        'nombre': 'Transversal 3. Transparencia y Rendición de Cuentas',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/Transversales/2024_075_Transversal%203%20-%20Transparencia%20y%20Rendicion%20de%20Cuentas.pdf'
                                    },
                                    {
                                        'nombre': 'Transversal 4. Anexo Transversal Anticorrupción (ATA)',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/Transversales/2024_076_Transversal%204%20-%20Anexo%20Transversal%20Anticorrupcion%20(ATA).pdf'
                                    },
                                    {
                                        'nombre': 'Transversal 5. Indicadores de desempeño (Estratégicos y de Gestión)',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/Transversales/2024_077_Transversal%205%20-%20Indicadores%20de%20desempen%CC%83o%20(Estrategicos%20y%20de%20Gestion).pdf'
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        'titulo': 'Reportes complementarios',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Complementario 1. Presupuesto del Órgano Superior de Fiscalización (OSF) Estatal',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_078_Complementario%201%20-%20Presupuesto%20del%20Organo%20Superior%20de%20Fiscalizacion%20(OSF)%20Estatal.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 2. Entidades apoyadas presupuestalmente y entidades no apoyadas presupuestalmente',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_079_Complementario%202%20-%20Entidades%20apoyadas%20presupuestalmente%20y%20entidades%20no%20apoyadas%20presupuestalmente.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 3. Participaciones por fondo y municipio',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_080_Complementario%203%20-%20Participaciones%20por%20fondo%20y%20municipio.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 4. Aportaciones por fondo y municipio',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_081_Complementario%204%20-%20Aportaciones%20por%20fondo%20y%20municipio.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 5. Monto integrador de participaciones y aportaciones por municipio',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_082_Complementario%205%20-%20Monto%20integrador%20de%20participaciones%20y%20aportaciones%20por%20municipio.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 6. Fórmulas de distribución a municipios por fondo',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_083_Complementario%206%20-%20Formulas%20de%20distribucion%20a%20municipios%20por%20fondo.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 7. Presupuesto a universidades públicas',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_084_Complementario%207%20-%20Presupuesto%20a%20universidades%20publicas.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 8. Presupuesto de inversión por capítulo de gasto',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_085_Complementario%208%20-%20Presupuesto%20de%20inversion%20por%20capitulo%20de%20gasto.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 9. Presupuesto por capítulo de gasto, resumen general',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_086_Complementario%209%20-%20Presupuesto%20por%20capitulo%20de%20gasto,%20resumen%20general.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 10. Analítico de plazas del Poder Ejecutivo',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_087_Complementario%2010%20-%20Analitico%20de%20plazas%20del%20Poder%20Ejecutivo.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 11. Analítico de plazas del Poder Ejecutivo con desglose de remuneraciones',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_088_Complementario%2011%20-%20Analitico%20de%20plazas%20del%20Poder%20Ejecutivo%20con%20desglose%20de%20remuneraciones.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 12. Cuerpos de seguridad, resumen de plazas',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_089_Complementario%2012%20-%20Cuerpos%20de%20seguridad,%20resumen%20de%20plazas.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 13. Recursos para atender a la población afectada y los daños causados a la infraestructura pública estatal ocasionados por desastres naturales',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_090_Complementario%2013%20-%20Recursos%20para%20atender%20a%20la%20poblacion%20afectada%20y%20los%20dan%CC%83os%20causados%20a%20la%20infraestructura%20publica%20estatal%20ocasionados%20por%20desastres%20naturales.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 14. Objetivos de Desarrollo Sostenible',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_091_Complementario%2014%20-%20Objetivos%20de%20Desarrollo%20Sostenible.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 15. Objetivos de Desarrollo Sostenible y sus metas',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_092_Complementario%2015%20-%20Objetivos%20de%20Desarrollo%20Sostenible%20y%20sus%20metas.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 16. Desglose de los Montos Asignados a Deuda Pública',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_093_Complementario%2016%20-%20Desglose%20de%20los%20Montos%20Asignados%20a%20Deuda%20Publica.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 17. Fideicomisos Públicos del Estado',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_094_Complementario%2017%20-%20Fideicomisos%20Publicos%20del%20Estado.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 18. Analítico de plazas magisterio',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_095_Complementario%2018%20-%20Analitico%20de%20plazas%20magisterio.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 19. Analítico de plazas magisterio, estatal y federal',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_096_Complementario%2019%20-%20Analitico%20de%20plazas%20magisterio,%20estatal%20y%20federal.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 20. Analítico de plazas magisterio por categoría',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_097_Complementario%2020%20-%20Analitico%20de%20plazas%20magisterio%20por%20categoria.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 21. Tabulador de salarios del personal docente del magisterio',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_098_Complementario%2021%20-%20Tabulador%20de%20salarios%20del%20personal%20docente%20del%20magisterio%20(federal).pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 22. Tabulador de salarios del personal docente del magisterio estatal',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_099_Complementario%2022%20-%20Tabulador%20de%20salarios%20del%20personal%20docente%20del%20magisterio%20(estatal).pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 23. Tabulador Estructura',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_100_Complementario%2023%20-%20Tabulador%20Estructura.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 24. Tabulador Honorarios',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_101_Complementario%2024%20-%20Tabulador%20Honorarios.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 25. Inventario de puestos',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_102_Complementario%2025%20-%20Inventario%20de%20puestos.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 26. Tabulador de salarios de personal administrativo de Instituciones de Educación Media Superior y Superior',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/2024_103_Complementario%2026%20-%20Tabulador%20de%20salarios%20de%20personal%20administrativo%20de%20Instituciones%20de%20Educacio%CC%81n%20Media%20Superior%20y%20Superior.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 27. Recursos estatales y federales para educación',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_104_Complementario%2027%20-%20Recursos%20estatales%20y%20federales%20para%20educacion.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 28. Clasificación por Fuentes de Financiamiento',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_105_Complementario%2028%20-%20Clasificacion%20por%20Fuentes%20de%20Financiamiento.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 29. Programas por Fuente de Financiamiento',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_106_Complementario%2029%20-%20Programas%20por%20Fuente%20de%20Financiamiento.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 30. Cálculo del límite de asignación global para servicios personales',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_107_Complementario%2030%20-%20Ca%CC%81lculo%20del%20limite%20de%20asignacion%20global%20para%20servicios%20personales.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 31. Proyección de pagos de la Deuda Pública con Gasto No Etiquetado y Gasto Etiquetado',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_108_Complementario%2031%20-%20Proyeccion%20de%20pagos%20de%20la%20Deuda%20Publica%20con%20Gasto%20No%20Etiquetado%20y%20Gasto%20Etiquetado.pdf'
                                    },
                                    {
                                        'nombre': 'Complementario 32. Clasificación Institucional por objeto del gasto',
                                        'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/AnexosProyectodePresupuestodeEgresos/ReportesComplementarios/2024_109_Complementario%2032%20-%20Clasificacion%20Institucional%20por%20objeto%20del%20gasto.pdf'
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    }