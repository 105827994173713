import React, { useEffect } from 'react';
import './DetalleServicios.css';
import { useParams } from 'react-router-dom';
import { rematesenLineaObject } from '../../json/Servicios/ServiciosalaCiudadania/RematesenLinea';
import { informacionFinsrftObject } from '../../json/Servicios/RendiciondeCuentas/InformacionFinSRFT';
import { informacionFinsfuObject } from '../../json/Servicios/RendiciondeCuentas/InformacionFinSFU';
import { documentacionArchivoObject } from '../../json/Servicios/RendiciondeCuentas/DocumentacionArchivo';
import { cuentaHaciendaPubObject } from '../../json/Servicios/RendiciondeCuentas/CuentaHaciendaPublica';
import { ramo23Object } from '../../json/Servicios/RendiciondeCuentas/Ramo23';
import { circularesSecretariaObject } from '../../json/Servicios/ServiciosInternos/CircularesSecretaria';
import { circularesSubseEgreObject } from '../../json/Servicios/ServiciosInternos/CircularesSubsecretariaEgresos';
import { informacionCircularesDireccTesoObject } from '../../json/Servicios/ServiciosInternos/CircularesDireccTesoreria';
import { paqueteHacendario24Object } from '../../json/Servicios/ServiciosInternos/PaqueteHacendario2024';
import { paqueteHacendario23Object } from '../../json/Servicios/ServiciosInternos/PaqueteHacendario2023';
import { paqueteHacendario22Object } from '../../json/Servicios/ServiciosInternos/PaqueteHacendario2022';
import { paqueteHacendario21Object } from '../../json/Servicios/ServiciosInternos/PaqueteHacendario2021';
import { paqueteHacendario20Object } from '../../json/Servicios/ServiciosInternos/PaqueteHacendario2020';
import { paqueteHacendario19Object } from '../../json/Servicios/ServiciosInternos/PaqueteHacendario2019';
import { paqueteHacendario18Object } from '../../json/Servicios/ServiciosInternos/PaqueteHacendario2018';
import { ejercicioPrepEgre24Object } from '../../json/Servicios/ServiciosInternos/EjerciciodelPresupuesto2024';
import { ejercicioPrepEgre23Object } from '../../json/Servicios/ServiciosInternos/EjerciciodelPresupuesto2023';
import { ejercicioPrepEgre22Object } from '../../json/Servicios/ServiciosInternos/EjerciciodelPresupuesto2022';
import { ejercicioPrepEgre21Object } from '../../json/Servicios/ServiciosInternos/EjerciciodelPresupuesto2021';
import { ejercicioPrepEgre20Object } from '../../json/Servicios/ServiciosInternos/EjerciciodelPresupuesto2020';
import { ejercicioPrepEgre19Object } from '../../json/Servicios/ServiciosInternos/EjerciciodelPresupuesto2019';
import { ejercicioPrepEgre18Object } from '../../json/Servicios/ServiciosInternos/EjerciciodelPresupuesto2018';
import { ejercicioPrepEgre17Object } from '../../json/Servicios/ServiciosInternos/EjerciciodelPresupuesto2017';
import { ejercicioPrepEgre16Object } from '../../json/Servicios/ServiciosInternos/EjerciciodelPresupuesto2016';
import { ejercicioPrepEgre15Object } from '../../json/Servicios/ServiciosInternos/EjerciciodelPresupuesto2015';
import { seguimientoAuditoriasObject } from '../../json/Servicios/ServiciosInternos/SeguimientoaAuditorias';
import { quienesSomosObject } from '../../json/Servicios/ServiciosInternos/QuienesSomos';
import { codigodeEticaObject } from '../../json/Servicios/ServiciosInternos/CodigodeEtica';
import { protocoloCeroObject } from '../../json/Servicios/ServiciosInternos/ProtocoloCero';
import { procuFiscalObject } from '../../json/Servicios/ServiciosInternos/ProcuraduriaFiscal';
// import DataTable from 'react-data-table-component';
import AccordionServicios from '../../components/ReusableComponents/AccordionServicios';
import ListLink from '../../components/ReusableComponents/ListLink';
import Contenido from '../../components/ReusableComponents/Contenido';
import Tabla from '../../components/ReusableComponents/Tabla';
import Frame2 from '../../components/ReusableComponents/Frame2';
import { unidadInversionesObject } from '../../json/Micrositios/UnidadInversiones/unidadInversiones';

const DetalleServiciosScreen = () => {

const { nombre } = useParams();
useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const changeObject = (n) => {
    switch(n){
        //ic
        case 'InformacionFinancieraSRFT':
            return informacionFinsrftObject;
        case 'InformacionFinancieraSFU':
            return informacionFinsfuObject;
        case 'RematesenLinea':
            return rematesenLineaObject;
        //list
        case 'DocumentacionArchivo':
            return documentacionArchivoObject;
        case 'CuentaHaciendaPublica':
            return cuentaHaciendaPubObject;
        case 'Ramo23':
            return ramo23Object;
        //si
        case 'CircularesSecretaria':
            return circularesSecretariaObject;
        case 'CircularesSubseEgresos':
            return circularesSubseEgreObject;
        case 'CircularesDireccEgresosyTesoreria':
            return informacionCircularesDireccTesoObject;
        case 'PaqueteHacendario2024':
            return paqueteHacendario24Object;
        case 'PaqueteHacendario2023':
            return paqueteHacendario23Object;
        case 'PaqueteHacendario2022':
            return paqueteHacendario22Object;
        case 'PaqueteHacendario2021':
            return paqueteHacendario21Object;
        case 'PaqueteHacendario2020':
            return paqueteHacendario20Object;
        case 'PaqueteHacendario2019':
            return paqueteHacendario19Object;
        case 'PaqueteHacendario2018':
            return paqueteHacendario18Object;
        case 'EjerciciodelPresupuesto2024':
            return ejercicioPrepEgre24Object;
        case 'EjerciciodelPresupuesto2023':
            return ejercicioPrepEgre23Object;
        case 'EjerciciodelPresupuesto2022':
            return ejercicioPrepEgre22Object;
        case 'EjerciciodelPresupuesto2021':
            return ejercicioPrepEgre21Object;
        case 'EjerciciodelPresupuesto2020':
            return ejercicioPrepEgre20Object;
        case 'EjerciciodelPresupuesto2019':
            return ejercicioPrepEgre19Object;
        case 'EjerciciodelPresupuesto2018':
            return ejercicioPrepEgre18Object;
        case 'EjerciciodelPresupuesto2017':
            return ejercicioPrepEgre17Object;
        case 'EjerciciodelPresupuesto2016':
            return ejercicioPrepEgre16Object;
        case 'EjerciciodelPresupuesto2015':
            return ejercicioPrepEgre15Object;
        case 'SeguimientoaAuditorias':
            return seguimientoAuditoriasObject;
        case 'QuienesSomos':
            return quienesSomosObject;
        case 'CodigodeEtica':
            return codigodeEticaObject;
        case 'ProtocoloCero':
            return protocoloCeroObject;
        case 'ProcuraduriaFiscal':
            return procuFiscalObject;
        case 'UnidaddeInversiones':
            return unidadInversionesObject;
        default:
            return []
        }
    }
  return (
    <div className='container'>
        <div className='pt-5 col-12'>
            <div className='servicio_title'>
                <h3 className='title mt-5'>{ changeObject(nombre).nombre }</h3>
                <hr className='hr-gob' />
            </div>
            {
                changeObject(nombre).tipo == "1" ?
                <AccordionServicios object={ changeObject(nombre) } /> : 
                changeObject(nombre).tipo == "2" ?
                <ListLink object={ changeObject(nombre) } /> : 
                changeObject(nombre).tipo == "3" ?
                <Contenido object={ changeObject(nombre) } /> :
                changeObject(nombre).tipo == "4" ?
                <Tabla object={ changeObject(nombre) } /> : 
                changeObject(nombre).tipo == "5" ?
                <Frame2 object={ changeObject(nombre) } /> :null
            }
        </div>
    </div>
  )
}

export default DetalleServiciosScreen