import React from 'react';
import { informesResultadosCuentaPublicaArray } from '../../../json/Transparencia/informesResultadosCuentaPublica';

const InformedeResultadosCuentaPublica = () => {

  return (
    <div className='container pt-5'>
        <div className='row'>
            <div>
                <h3 className='title mt-5 pt-5'>Informes de Resultados de la Cuenta Pública del Poder Ejecutivo</h3>
                <hr className='hr-gob'/>
            </div>
            {
                informesResultadosCuentaPublicaArray.map((item,i) => (
                    <div key={i}>
                        <h3 className='ps-3 mt-5'><a href={ item.link } target="_blank">{ item.anio }</a></h3>
                        <hr className='hr'/>
                        {
                            item.data?
                            item.data.map((data, t)=>(
                                <div key={t}>
                                    <a className='ps-4' href={data.urlpdf} target="_blank">{ data.titulo }</a>
                                </div>
                            ))
                            : null
                        }
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default InformedeResultadosCuentaPublica