import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './servicios.css';
import { servicios } from '../../json/servicios';

const Servicios = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='container servicios-container'>
        <div className='row'>
            {
                servicios.map((item, index) => (
                    <>
                        <div key={ index } className='col-12'>
                            <div className='servicios_title'>
                                <h1 className='my-5'>{item.titulo}</h1>
                            </div>
                        </div>
                        {
                            item.servicio.map((item, s) => (
                                <div key={ s }>
                                    <h3 className='title'>{item.subtitulo}</h3>
                                    <hr className='hr-gob'/>
                                    <div className='row'>
                                        {
                                            item.data.map((item, a) => (
                                                <>
                                                    <div className="col-md-3 css-02 css-contenido" key={a}>
                                                        <div className="css-02a css-1f">
                                                            <Link className="text-decoration-none" to={ item.url }>
                                                                <button className="css-02a1-0 btn btn-outline-primary" tabindex="0" type="button">
                                                                    <div className="css-02b"><i className={item.icono}></i></div>
                                                                    <p className="css-02text text-wrap text-bk">{item.nombre}</p>
                                                                </button>
                                                            </Link>
                                                        </div>
                                                    </div>    
                                                </>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </>
                ))
            }
        </div>
    </div>
  )
}

export default Servicios