export const codigodeEticaObject = {
    'nombre': 'Comité de Ética y Prevención de Conflictos de Interés',
    'tipo': '3',
    'servidor': '1',
    'articulo': [
        {
            'titulo': '',
            'descripcion': '',
            'data': [
                {
                    'titulolink': '',
                    'url': '',
                    'docs': [
                        {
                            "icono":"fa-solid fa-chevron-right",
                            'subtitulolink': 'Código de Ética',
                            'url': '../files/servicios/pdf/CodigodeEtica/Codigo%20de%20Etica.pdf'
                        },
                        {
                            "icono":"fa-solid fa-chevron-right",
                            'subtitulolink': 'Código de Conducta',
                            'url': '../files/servicios/pdf/CodigodeEtica/Codigo%20de%20Conducta.pdf'
                        },
                        {
                            "icono":"fa-solid fa-chevron-right",
                            'subtitulolink': 'Fe de Erratas (Alcance al Código de Ética)',
                            'url': '../files/servicios/pdf/CodigodeEtica/Fe%20de%20Erratas%20(alcance%20al%20codigo%20de%20etica).pdf'
                        },
                        {
                            "icono":"fa-solid fa-chevron-right",
                            'subtitulolink': 'Acuerdo de Creación del Comité de Ética y Prevención de Conflictos de Interés',
                            'url': '../files/servicios/pdf/CodigodeEtica/Acuerdo%20de%20Creacion%20del%20Comite%20de%20Etica%20y%20Prevencion%20de%20Conflictos%20de%20Interes.pdf'
                        },
                        {
                            "icono":"fa-solid fa-chevron-right",
                            'subtitulolink': 'Formato de denuncia',
                            'url': '../files/servicios/pdf/CodigodeEtica/Formato_de_Primer_Contacto.pdf'
                        },
                        {
                            "icono":"fa-solid fa-chevron-right",
                            'subtitulolink': 'Procedimiento y protocolo de recepción y atención a denuncias',
                            'url': '../files/servicios/pdf/CodigodeEtica/PROCEDIMIENTO_Y_PROTOCOLO_DE_RECEPCION_Y_ATENCION_A_DENUNCIAS_4_SEP_2024_APROBADO.pdf'
                        },
                        {
                            'subtitulolink': '',
                            'url': '',
                            'images': [
                                {
                                    'img': '../assets/imgs/Comite de Etica/Codigo_de_conducta.png',
                                },
                                {
                                    'img': '../assets/imgs/Comite de Etica/Codigo_de_etica.png',
                                },
                                {
                                    'img': '../assets/imgs/Comite de Etica/Codigo_de_etica_-_Regla_de_integridad.png',
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
}
