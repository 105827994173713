export const paqueteHacendario18Object = {
    'nombre': 'Paquete Hacendario 2018',
    'tipo': '2',
    'servidor': '1',
    'seccion': [
        {
            'titulo': '',
            'subtitulo': '',
            'docs': [
                {
                    'nombreLink':'Exposición de motivos de la Iniciativa de Ley de Ingresos y del Proyecto de Presupuesto de Egresos 2018',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2018/Exposicion%20de%20motivos%20de%20la%20Iniciativa%20de%20Ley%20de%20Ingresos%20y%20del%20Proyecto%20de%20Presupuesto%20de%20Egresos%202018.pdf'
                },
                {
                    'nombreLink':'Iniciativa de Ley de Ingresos 2018',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2018/Iniciativa%20de%20Ley%20de%20Ingresos%202018.pdf'
                },
                {
                    'nombreLink':'Proyecto de Decreto de Presupuesto de Egresos 2018',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2018/Proyecto%20de%20Decreto%20de%20Presupuesto%20de%20Egresos%202018.pdf'
                },
                {
                    'nombreLink':'Iniciativa de Miscelánea Fiscal 2018',
                    'url': '../files/servicios/Iniciativa%20de%20Miscelanea%20Fiscal%202018.pdf'
                },
                {
                    'nombreLink':'Anexos del Proyecto de Decreto de Presupuesto de Egresos 2018',
                    'urltres': 'ServiciosInternos/AnexosdelProyectodeDecretodePresupuestodeEgresos2018'
                },
                {
                    'nombreLink':'Anteproyecto del Presupuesto de Egresos 2018',
                    'urltres': 'ServiciosInternos/AnteproyectodePresupuestodeEgresos2018'
                }
            ]
        }
    ]
}