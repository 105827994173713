export const ejercicioPrepEgre23Object = {
    'nombre': 'Ejercicio del Presupuesto 2023',
        'tipo': '2',
        'servidor': '1',
        'seccion': [
            {
                'titulo': '',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Manual de Normas y Lineamientos para el Ejercicio de los Recursos del Gasto de Operación 2023',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2023/Manual%20Gasto%20de%20Operacion%20modificado.pdf',
                        'urldos': [
                            {
                                'descripcion': 'Anexo A1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20A1.docx'
                            },
                            {
                                'descripcion': 'Anexo A2',
                                'link': '../files/servicios/xls/EjerciciodelPresupuesto/Anexos GO/Anexo A2.xlsx'
                            },
                            {
                                'descripcion': 'Anexo C1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20C1.docx'
                            },
                            {
                                'descripcion': 'Anexo C2',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20C2.docx'
                            },
                            {
                                'descripcion': 'Anexo C3',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20C3.docx'
                            },
                            {
                                'descripcion': 'Anexo C4',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20C4.docx'
                            },
                            {
                                'descripcion': 'Anexo C5',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20C5.docx'
                            },
                            {
                                'descripcion': 'Anexo C6',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20C6.docx'
                            },
                            {
                                'descripcion': 'Anexo C7',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20C7.docx'
                            },
                            {
                                'descripcion': 'Anexo C8',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20C8.docx'
                            },
                            {
                                'descripcion': 'Anexo C9-A',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20C9%20A.docx'
                            },
                            {
                                'descripcion': 'Anexo C9-B',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20C9%20B.docx'
                            },
                            {
                                'descripcion': 'Anexo C9-C',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20C9%20C.docx'
                            },
                            {
                                'descripcion': 'Anexo C10',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20C10.docx'
                            },
                            {
                                'descripcion': 'Anexo C11',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20C11.docx'
                            },
                            {
                                'descripcion': 'Anexo C12',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20C12.docx'
                            },
                            {
                                'descripcion': 'Anexo C13',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20C13.docx'
                            },
                            {
                                'descripcion': 'Anexo C14.1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20C14.1.docx'
                            },
                            {
                                'descripcion': 'Anexo C14',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20C14.docx'
                            },
                            {
                                'descripcion': 'Anexo C15',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20C15.docx'
                            },
                            {
                                'descripcion': 'Anexo C16',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20C16.docx'
                            },
                            {
                                'descripcion': 'Anexo D1',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2023/Anexos%20GO/GO%20-%20Anexo%20D1.pdf'
                            },
                            {
                                'descripcion': 'Anexo E',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2023/Anexos%20GO/GO%20-%20Anexo%20E.pdf'
                            },
                            {
                                'descripcion': 'Anexo F',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexos/Anexo%20F.docx'
                            },
                            {
                                'descripcion': 'Anexo H',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20H.pdf'
                            },
                            {
                                'descripcion': 'Anexo I',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20I.docx'
                            },
                            {
                                'descripcion': 'Anexo J',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20J.docx'
                            },
                            {
                                'descripcion': 'Anexo K',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20K.docx'
                            },
                            {
                                'descripcion': 'Anexo K1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20K1.docx'
                            },
                            {
                                'descripcion': 'Anexo K2',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20K2.docx'
                            },
                            {
                                'descripcion': 'Anexo L1',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20L1.pdf'
                            },
                            {
                                'descripcion': 'Anexo N1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20N1.docx'
                            },
                            {
                                'descripcion': 'Anexo N2',
                                'link': '../files/servicios/xls/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20N2.xlsx'
                            },
                            {
                                'descripcion': 'Anexo Q',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20Q.docx'
                            },
                            {
                                'descripcion': 'Anexo R',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20R.docx'
                            }
                        ]
                    }
                ]
            },
            {
                'titulo': '',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Manual de Normas y Lineamientos para el Ejercicio de los Recursos del Gasto de Operación 2023',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2023/Manual%20Gasto%20de%20Operacion%20modificado.pdf',
                        'urldos': [
                            {
                                'descripcion': 'Anexo A1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GO/Anexo%20A1.docx'
                            },
                            {
                                'descripcion': 'Anexo A2',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexos/Anexo%20A2.xlsx'
                            },
                            {
                                'descripcion': 'Anexo C1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20C1.docx'
                            },
                            {
                                'descripcion': 'Anexo C2',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20C2.docx'
                            },
                            {
                                'descripcion': 'Anexo C3',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20C3.docx'
                            },
                            {
                                'descripcion': 'Anexo C4',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20C4.docx'
                            },
                            {
                                'descripcion': 'Anexo C5',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20C5.docx'
                            },
                            {
                                'descripcion': 'Anexo C6',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20C6.docx'
                            },
                            {
                                'descripcion': 'Anexo C7',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20C7.docx'
                            },
                            {
                                'descripcion': 'Anexo C8',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20C8.docx'
                            },
                            {
                                'descripcion': 'Anexo C9',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20C9.docx'
                            },
                            {
                                'descripcion': 'Anexo C10',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20C10.docx'
                            },
                            {
                                'descripcion': 'Anexo C11',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20C11.docx'
                            },
                            {
                                'descripcion': 'Anexo C12',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20C12.docx'
                            },
                            {
                                'descripcion': 'Anexo C13',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20C13.docx'
                            },
                            {
                                'descripcion': 'Anexo C14',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20C14.docx'
                            },
                            {
                                'descripcion': 'Anexo C15',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20C15.docx'
                            },
                            {
                                'descripcion': 'Anexo C16',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20C16.docx'
                            },
                            {
                                'descripcion': 'Anexo C17',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20C17.docx'
                            },
                            {
                                'descripcion': 'Anexo D3',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20D3.docx'
                            },
                            {
                                'descripcion': 'Anexo D4_1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20D4_1.docx'
                            },
                            {
                                'descripcion': 'Anexo D4_2',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20D4_2.docx'
                            },
                            {
                                'descripcion': 'Anexo D4',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20D4.docx'
                            },
                            {
                                'descripcion': 'Anexo D8',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20D8.docx'
                            },
                            {
                                'descripcion': 'Anexo D9',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20D9.docx'
                            },
                            {
                                'descripcion': 'Anexo D10',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20D10.docx'
                            },
                            {
                                'descripcion': 'Anexo D11',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20D11.docx'
                            },
                            {
                                'descripcion': 'Anexo F',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20F.docx'
                            },
                            {
                                'descripcion': 'Anexo GI - D1',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20D1.pdf'
                            },
                            {
                                'descripcion': 'Anexo GI - D2',
                                'link': '../files/servicios/xls/EjerciciodelPresupuesto/Anexos GO/GI_D2.xlsx'
                            },
                            {
                                'descripcion': 'Anexo GI - D5',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20D5.pdf'
                            },
                            {
                                'descripcion': 'Anexo GI - D6',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20D6.pdf'
                            },
                            {
                                'descripcion': 'Anexo GI - D7',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20D7.pdf'
                            },
                            {
                                'descripcion': 'Anexo GI - D13',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2023/Anexos%20GI/Anexo%20D13.pdf'
                            }
                        ]
                    }
                ]
            }
        ]
    }