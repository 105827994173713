export const ejercicioPrepEgre17Object = {
    'nombre': 'Ejercicio del Presupuesto 2017',
        'tipo': '2',
        'servidor': '1',
        'seccion': [
            {
                'titulo': '',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Circular No. 24',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2017/Circular%20No%2024.pdf'
                    },
                    {
                        'nombreLink':'Circular No. 16',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2017/Circular%20No%2016.pdf'
                    },
                    {
                        'nombreLink':'Circular No. 13',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2017/Circular%20No%2013.pdf'
                    },
                    {
                        'nombreLink':'Circular No. 12',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2017/Circular%20No%2012.pdf'
                    },
                    {
                        'nombreLink':'Circular No. 1',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2017/Circular%20No%201.pdf'
                    },
                    {
                        'nombreLink':'Decreto de Presupuesto de Egresos 2017',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2017/Decreto%20de%20Presupuesto%20de%20Egresos%202017.pdf'
                    },
                    {
                        'nombreLink':'Anexos del Presupuesto de Egresos',
                        'url': '../files/servicios/anexosPresupuestoEgresos2017.html'
                    },
                    {
                        'nombreLink':'Manual de Normas y Lineamientos para el Ejercicio del Gasto de Operación de los Programas y Proyectos 2017',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2017/Manual%20de%20Gasto%20de%20Operacion%202017%202o%20Semestre%20Diciembre.pdf'
                    },
                    {
                        'nombreLink':'Manual de Normas y Lineamientos para el Ejercicio de los Recursos del Programa de Inversión 2017',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2017/Manual%20de%20Gasto%20de%20Inversion%202017%202o%20Semestre%20Diciembre.pdf'
                    }
                ]
            }
        ]
    }