export const linkMicro = [
    {
        "nombre" : "De Interés",
        "submenu" : [
            {
                "subMenuNombre" : "Asociaciones Público Privadas",
                "subMenuUrl" : "/AsociacionesPublicoPrivadas"
            },
            {
                "subMenuNombre" : "Registro de Proyectos de Inversión",
                "subMenuUrl" : "/RegistrodeProyectosdeInversion"
            },
        ]
    }
]