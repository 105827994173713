export const ejercicioPrepEgre22Object = {
    'nombre': 'Ejercicio del Presupuesto 2022',
        'tipo': '2',
        'servidor': '1',
        'seccion': [
            {
                'titulo': '',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Manual de Lineamientos y Operación del Sistema de Validación de CFDIs',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2022/Manual%20de%20Lineamientos%20y%20Operacion%20del%20Sistema%20de%20Validacion%20de%20CFDIs.pdf',
                        'urldos': [
                            {
                                'descripcion': 'Anexo SVA1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos/Anexo_SVA1.docx'
                            },
                            {
                                'descripcion': 'Anexo SVA2',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos/Anexo_SVA2.docx'
                            },
                            {
                                'descripcion': 'Anexo SVA3',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos/Anexo_SVA3.docx'
                            },
                            {
                                'descripcion': 'Anexo SVB',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos/Anexo_SVB.docx'
                            }
                        ]
                    }
                ]
            },
            {
                'titulo': '',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Manual de Normas y Lineamientos para el Ejercicio de los Recursos del Gasto de Operación 2022',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2022/Manual%20de%20Normas%20y%20Lineamientos%20para%20el%20Ejercicio%20de%20los%20Recursos%20del%20Gasto%20de%20Operacion%202022.pdf',
                        'urldos': [
                            {
                                'descripcion': 'Anexo A1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GO/Anexo%20A1_22.docx'
                            },
                            {
                                'descripcion': 'Anexo A2',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2022/Anexos%20GO/Anexo%20A2_22.pdf'
                            },
                            {
                                'descripcion': 'Anexo D1',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2022/Anexos%20GO/Anexo%20D1%20-%202022.pdf'
                            },
                            {
                                'descripcion': 'Anexo E',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2022/Anexos%20GO/Anexo%20E_2022.pdf'
                            },
                            {
                                'descripcion': 'Anexo F',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GO/Anexo%20F_22.docx'
                            },
                            {
                                'descripcion': 'Anexo H',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2022/Anexos%20GO/Anexo%20H_22.pdf'
                            },
                            {
                                'descripcion': 'Anexo I',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2022/Anexos%20GO/Anexo%20I_22.pdf'
                            },
                            {
                                'descripcion': 'Anexo J',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GO/Anexo%20J_22.docx'
                            },
                            {
                                'descripcion': 'Anexo K',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2022/Anexos%20GO/Anexo%20K_22.pdf'
                            },
                            {
                                'descripcion': 'Anexo L1',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2022/Anexos%20GO/Anexo%20L1%20-%202022.pdf'
                            },
                            {
                                'descripcion': 'Anexo C1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GO/C1.docx'
                            },
                            {
                                'descripcion': 'Anexo C2',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GO/C2.docx'
                            },
                            {
                                'descripcion': 'Anexo C3',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GO/C3.docx'
                            },
                            {
                                'descripcion': 'Anexo C4',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GO/C4.docx'
                            },
                            {
                                'descripcion': 'Anexo C5',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GO/C5.docx'
                            },
                            {
                                'descripcion': 'Anexo C6',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GO/C6.docx'
                            },
                            {
                                'descripcion': 'Anexo C7',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GO/C7.docx'
                            },
                            {
                                'descripcion': 'Anexo C8',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GO/C8.docx'
                            },
                            {
                                'descripcion': 'Anexo C9-A',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GO/C9-A.docx'
                            },
                            {
                                'descripcion': 'Anexo C9-B',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GO/C9-B.docx'
                            },
                            {
                                'descripcion': 'Anexo C9-C',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GO/C9-C.docx'
                            },
                            {
                                'descripcion': 'Anexo C10',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GO/C10.docx'
                            },
                            {
                                'descripcion': 'Anexo C11',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GO/C11.docx'
                            },
                            {
                                'descripcion': 'Anexo C12',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GO/C12.docx'
                            },
                            {
                                'descripcion': 'Anexo C13',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GO/C13.docx'
                            }
                        ]
                    }
                ]
            },
            {
                'titulo': '',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Manual de Normas y Lineamientos para el Ejercicio de los Recursos del Gasto de Inversión 2022',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2022/Manual%20de%20Normas%20y%20Lineamientos%20para%20el%20Ejercicio%20de%20los%20Recursos%20del%20Gasto%20de%20Inversion%202022.pdf',
                        'urldos': [
                            {
                                'descripcion': 'Anexo A1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20A1_22.docx'
                            },
                            {
                                'descripcion': 'Anexo A2',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20A2_22.pdf'
                            },
                            {
                                'descripcion': 'Anexo C1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20C1_22.docx'
                            },
                            {
                                'descripcion': 'Anexo C2',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20C2_22.docx'
                            },
                            {
                                'descripcion': 'Anexo C3',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20C3_22.docx'
                            },
                            {
                                'descripcion': 'Anexo C4',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20C4_22.docx'
                            },
                            {
                                'descripcion': 'Anexo C5',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20C5_22.docx'
                            },
                            {
                                'descripcion': 'Anexo C6',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20C6_22.docx'
                            },
                            {
                                'descripcion': 'Anexo C7',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20C7_22.docx'
                            },
                            {
                                'descripcion': 'Anexo C8',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20C8_22.docx'
                            },
                            {
                                'descripcion': 'Anexo C9',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20C9_22.docx'
                            },
                            {
                                'descripcion': 'Anexo C10',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20C10_22.docx'
                            },
                            {
                                'descripcion': 'Anexo C11',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20C11_22.docx'
                            },
                            {
                                'descripcion': 'Anexo C12',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20C12_22.docx'
                            },
                            {
                                'descripcion': 'Anexo D1',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20D1_22.pdf'
                            },
                            {
                                'descripcion': 'Anexo D2',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20D2_22.pdf'
                            },
                            {
                                'descripcion': 'Anexo D3 (1 a 5 partidas)',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20D3_22%20(1%20a%205%20partidas).pdf'
                            },
                            {
                                'descripcion': 'Anexo D3 (6 a 16 partidas)',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20D3_22%20(6%20a%2016%20partidas).pdf'
                            },
                            {
                                'descripcion': 'Anexo D3 (17 a 26 partidas)',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20D3_22%20(17%20a%2026%20partidas).pdf'
                            },
                            {
                                'descripcion': 'Anexo D4',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20D4_22.pdf'
                            },
                            {
                                'descripcion': 'Anexo D5',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20D5_22.docx'
                            },
                            {
                                'descripcion': 'Anexo D6',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20D6_22.pdf'
                            },
                            {
                                'descripcion': 'Anexo D7',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20D7_22.pdf'
                            },
                            {
                                'descripcion': 'Anexo D8',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20D8_22.pdf'
                            },
                            {
                                'descripcion': 'Anexo D9',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20D9_22.pdf'
                            },
                            {
                                'descripcion': 'Anexo D10',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20D10_22.docx'
                            },
                            {
                                'descripcion': 'Anexo D11',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20D11_22.docx'
                            },
                            {
                                'descripcion': 'Anexo D12',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2022/Anexos%20GI/Anexo%20D12_22.docx'
                            }
                        ]
                    }
                ]
            }
        ]
    }