export const seguimientoAuditoriasObject = {
    'nombre': 'Seguimiento a Auditorias',
    'tipo': '2',
    'servidor': '1',
    'seccion': [
        {
            'titulo': '',
            'subtitulo': '',
            'docs': [
                {
                    'nombreLink':'Circular No. 17',
                    'url': '../files/servicios/pdf/SeguimientoAuditorias/Circular%20No%2017.pdff'
                },
                {
                    'nombreLink':'Guía de integración de avance de gestión Financiera y Cuenta Públicas del poder Ejecutivo para el Ejercicio Fiscal 2017 (descargable)',
                    'url': '../files/servicios/docx/SeguimientoAuditorias/01%20Finanzas%20Guias.doc',
                    'urldos': [
                        {
                            'descripcion': 'A3 ESF EF Periodo ejercicio (descargable)',
                            'link': '../files/servicios/xls/SeguimientoAuditorias/A3%20ESF%20EF%20PERIODO%20EJERCICIO.xls'
                        },
                        {
                            'descripcion': 'A4 EA EF Periodo ejercicio (descargable)',
                            'link': '../files/servicios/xls/SeguimientoAuditorias/A4%20EA%20EF%20PERIODO%20EJERCICIO.xlsx'
                        },
                        {
                            'descripcion': 'A5 ANC EF Periodo ejercicio (descargable)',
                            'link': '../files/servicios/xls/SeguimientoAuditorias/A5%20ANC%20EF%20PERIODO%20EJERCICIO.xlsx'
                        },
                        {
                            'descripcion': 'A6 REA EF Periodo ejercicio (descargable)',
                            'link': '../files/servicios/xls/SeguimientoAuditorias/A6%20REA%20EF%20PERIODO%20EJERCICIO.xlsx'
                        },
                        {
                            'descripcion': 'A7 EAI EF Periodo ejercicio (descargable)',
                            'link': '../files/servicios/xls/SeguimientoAuditorias/A7%20EAI%20EF%20PERIODO%20EJERCICIO.xlsx'
                        },
                        {
                            'descripcion': 'A8 EAE EF Periodo ejercicio (descargable)',
                            'link': '../files/servicios/xls/SeguimientoAuditorias/A8%20EAE%20EF%20PERIODO%20EJERCICIO.xlsx'
                        },
                        {
                            'descripcion': 'A19 IPC EF Periodo ejercicio (descargable)',
                            'link': '../files/servicios/xls/SeguimientoAuditorias/A19%20IPC%20EF%20PERIODO%20EJERCICIO.xlsx'
                        },
                        {
                            'descripcion': 'A19A IPC EF Periodo ejercicio (descargable)',
                            'link': '../files/servicios/xls/SeguimientoAuditorias/A19A%20IPC%20EF%20PERIODO%20EJERCICIO.xlsx'
                        },
                        {
                            'descripcion': 'A20 IPA EF Periodo ejercicio (descargable)',
                            'link': '../files/servicios/xls/SeguimientoAuditorias/A20%20IPA%20EF%20PERIODO%20EJERCICIO.xlsx'
                        },
                        {
                            'descripcion': 'A20A IPA EF Periodo ejercicio (descargable)',
                            'link': '../files/servicios/xls/SeguimientoAuditorias/A20A%20IPA%20EF%20PERIODO%20EJERCICIO.xlsx'
                        },
                        {
                            'descripcion': 'A21 IPG EF Periodo ejercicio (descargable)',
                            'link': '../files/servicios/xls/SeguimientoAuditorias/A21%20IPG%20EF%20PERIODO%20EJERCICIO.xlsx'
                        },
                        {
                            'descripcion': 'A22 Deudores diversos (descargable)',
                            'link': '../files/servicios/xls/SeguimientoAuditorias/A22%20DEUDORES%20DIVERSOS.xls'
                        },
                        {
                            'descripcion': 'A23 Acredores diversos (descargable)',
                            'link': '../files/servicios/xls/SeguimientoAuditorias/A23%20ACREDORES%20DIVERSOS.xls'
                        },
                        {
                            'descripcion': 'A24 Pedidos, contratos u ordenes de compra (descargable)',
                            'link': '../files/servicios/xls/SeguimientoAuditorias/A24%20PEDIDOS%20CONTRATOS%20U%20ORDENES%20DE%20COMPRA.xls'
                        },
                        {
                            'descripcion': 'A25 Personal comisionado (descargable)',
                            'link': '../files/servicios/xls/SeguimientoAuditorias/A25%20PERSONAL%20COMISIONADO.xls'
                        },
                        {
                            'descripcion': 'A26 Altas y bajas de bienes muebles, inmuebles e intangibles (descargable)',
                            'link': '../files/servicios/xls/SeguimientoAuditorias/A26%20ALTAS%20Y%20BAJAS%20DE%20BIENES%20MUEBLES%20INMUEBLES%20E%20INTANGIBLES.xls'
                        },
                        {
                            'descripcion': 'A27 Altas y bajas de personal (descargable)',
                            'link': '../files/servicios/xls/SeguimientoAuditorias/A27%20ALTAS%20Y%20BAJAS%20DE%20PERSONAL.xls'
                        },
                        {
                            'descripcion': 'A28 Cuentas bancarias (descargable)',
                            'link': '../files/servicios/xls/SeguimientoAuditorias/A28%20CUENTAS%20BANCARIAS.xls'
                        },
                        {
                            'descripcion': 'A29 Inmuebles arrendados (descargable)',
                            'link': '../files/servicios/xls/SeguimientoAuditorias/A29%20INMUEBLES%20ARRENDADOS.xls'
                        },
                        {
                            'descripcion': 'A30 Formato del ejercicio y destino del gasto federalizado y reintegros (descargable)',
                            'link': '../files/servicios/xls/SeguimientoAuditorias/A30%20FORMATO%20DEL%20EJERCICIO%20Y%20DESTINO%20DEL%20GASTO%20FEDERALIZADO%20Y%20REINTEGROS.xlsx'
                        },
                        {
                            'descripcion': 'Anexo AECT-01, Ejecutivo (descargable)',
                            'link': '../files/servicios/docx/SeguimientoAuditorias/Anexo%20AECT%2001%20Ejecutivo.docx'
                        },
                        {
                            'descripcion': 'Anexo F, Catálogo de fondos y Programas (descargable)',
                            'link': '../files/servicios/docx/SeguimientoAuditorias/Anexo%20F%20Catalogo%20de%20fondos%20y%20Programas.docx'
                        },
                        {
                            'descripcion': 'D-1 (descargable)',
                            'link': '../files/servicios/xls/SeguimientoAuditorias/D%201.xlsx'
                        }
                    ]
                }
            ]
        }
    ]
}
