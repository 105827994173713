import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { unidadInversionesObject } from '../../json/Micrositios/UnidadInversiones/unidadInversiones';
import Micrositios from '../../components/ReusableComponents/Micrositios';

const DetalleMicrositiosScreen = () => {

    const { nombre } = useParams();
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
      const changeObject = (n) => {
        switch(n){
            //micrositios
            case 'UnidaddeInversiones':
                return unidadInversionesObject;
            default:
                return []
            }
        }
      return (
        <div className='container'>
            <div className='pt-5 col-12'>
                <div className='servicio_title'>
                    <h3 className='title mt-5'>{ changeObject(nombre).nombre }</h3>
                    <hr className='hr-gob' />
                </div>
                {
                    changeObject(nombre).tipo == "1" ?
                    <Micrositios object={ changeObject(nombre) } /> : null
                }
            </div>
        </div>
      )
    }

export default DetalleMicrositiosScreen