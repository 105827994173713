export const ejercicioPrepEgre20Object = {
    'nombre': 'Ejercicio del Presupuesto 2020',
        'tipo': '2',
        'servidor': '1',
        'seccion': [
            {
                'titulo': '',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Circular No. 108',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Circular%20No%20108.pdf'
                    },
                    {
                        'nombreLink':'Circular No. 003',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Circular%20No%20003.pdf'
                    }
                ]
            },
            {
                'titulo': '',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Manual de Normas y Lineamientos para el Ejercicio de los Recursos del Gasto de Operación 2020',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Manual%20de%20Normas%20y%20Lineamientos%20para%20el%20Ejercicio%20de%20los%20Recursos%20del%20Gasto%20de%20Operacion%202020.pdf',
                        'urldos': [
                            {
                                'descripcion': 'Anexo A1',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20GO/Anexo%20A1.pdf'
                            },
                            {
                                'descripcion': 'Anexo A2',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20GO/Anexo%20A2.pdf'
                            },
                            {
                                'descripcion': 'Anexo C1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2020/Anexos%20GO/Anexo%20C1.docx'
                            },
                            {
                                'descripcion': 'Anexo C2',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2020/Anexos%20GO/Anexo%20C2.docx'
                            },
                            {
                                'descripcion': 'Anexo C3',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2020/Anexos%20GO/Anexo%20C3.docx'
                            },
                            {
                                'descripcion': 'Anexo C4',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2020/Anexos%20GO/Anexo%20C4.docx'
                            },
                            {
                                'descripcion': 'Anexo C5',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2020/Anexos%20GO/Anexo%20C5.docx'
                            },
                            {
                                'descripcion': 'Anexo C6',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2020/Anexos%20GO/Anexo%20C6.docx'
                            },
                            {
                                'descripcion': 'Anexo C7',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2020/Anexos%20GO/Anexo%20C7.docx'
                            },
                            {
                                'descripcion': 'Anexo C8',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2020/Anexos%20GO/Anexo%20C8.docx'
                            },
                            {
                                'descripcion': 'Anexo C9-A',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2020/Anexos%20GO/Anexo%20C9-A.docx'
                            },
                            {
                                'descripcion': 'Anexo C9-B',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2020/Anexos%20GO/Anexo%20C9-B.docx'
                            },
                            {
                                'descripcion': 'Anexo C10',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2020/Anexos%20GO/Anexo%20C10.docx'
                            },
                            {
                                'descripcion': 'Anexo C11',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2020/Anexos%20GO/Anexo%20C11.docx'
                            },
                            {
                                'descripcion': 'Anexo C12',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2020/Anexos%20GO/Anexo%20C12.docx'
                            },
                            {
                                'descripcion': 'Anexo C13',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2020/Anexos%20GO/Anexo%20C13.docx'
                            },
                            {
                                'descripcion': 'Anexo D1',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20GO/Anexo%20D1.pdf'
                            },
                            {
                                'descripcion': 'Anexo E',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20GO/Anexo%20E.pdf'
                            },
                            {
                                'descripcion': 'Anexo H',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20GO/Anexo%20H.pdf'
                            },
                            {
                                'descripcion': 'Anexo I',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20GO/Anexo%20I.pdf'
                            },
                            {
                                'descripcion': 'Anexo J',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20GO/Anexo%20J.pdf'
                            },
                            {
                                'descripcion': 'Anexo K',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20GO/Anexo%20K.pdf'
                            },
                            {
                                'descripcion': 'Anexo L1',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20GO/Anexo%20L1.pdf'
                            },
                            {
                                'descripcion': 'Instrucciones Generales para el llenado de los Anexos de Gasto de Operación',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20GO/Instrucciones%20generales%20para%20el%20llenado%20de%20los%20anexos%20de%20gasto%20de%20operacion.pdf'
                            },
                            {
                                'descripcion': 'Resguardo interno para herramientas menores',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20GO/Resguardo%20interno%20para%20herramientas%20menores.pdf'
                            }
                        ]
                    }
                ]
            },
            {
                'titulo': '',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Manual de Normas y Lineamientos para el Ejercicio de los Recursos del Gasto de Inversión 2020',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Manual%20de%20Normas%20y%20Lineamientos%20para%20el%20Ejercicio%20de%20los%20Recursos%20del%20Programa%20de%20Inversion%202020.pdf',
                        'urldos': [
                            {
                                'descripcion': 'Anexo A1',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20GI/Anexo%20A1.pdf'
                            },
                            {
                                'descripcion': 'Anexo A2',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20GI/Anexo%20A2.pdf'
                            },
                            {
                                'descripcion': 'Anexo C1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2020/Anexos%20GI/Anexo%20C1.docx'
                            },
                            {
                                'descripcion': 'Anexo C2',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2020/Anexos%20GI/Anexo%20C2.docx'
                            },
                            {
                                'descripcion': 'Anexo C3',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2020/Anexos%20GI/Anexo%20C3.docx'
                            },
                            {
                                'descripcion': 'Anexo C4',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2020/Anexos%20GI/Anexo%20C4.docx'
                            },
                            {
                                'descripcion': 'Anexo C5',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2020/Anexos%20GI/Anexo%20C5.docx'
                            },
                            {
                                'descripcion': 'Anexo C6',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2020/Anexos%20GI/Anexo%20C6.docx'
                            },
                            {
                                'descripcion': 'Anexo C7',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2020/Anexos%20GI/Anexo%20C7.docx'
                            },
                            {
                                'descripcion': 'Anexo C8',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20GI/Anexo%20C8.pdf'
                            },
                            {
                                'descripcion': 'Anexo C9',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20GI/Anexo%20C9.pdf'
                            },
                            {
                                'descripcion': 'Anexo C10 (1 a 5 partidas)',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20GI/Anexo%20C10%20(1%20a%205%20partidas)%20-%20Verificar.pdf'
                            },
                            {
                                'descripcion': 'Anexo C10 (6 a 16 partidas)',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20GI/Anexo%20C10%20(6%20a%2016%20partidas)%20-%20Sin%20cambios.pdf'
                            },
                            {
                                'descripcion': 'Anexo C10 (17 a 26 partidas)',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20GI/Anexo%20C10%20(17%20-%2026%20partidas)%20-%20Sin%20cambios.pdf'
                            },
                            {
                                'descripcion': 'Anexo C11',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20GI/Anexo%20C11.pdf'
                            },
                            {
                                'descripcion': 'Anexo C12',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20GI/Anexo%20C12%20-%20En%20word%20del%20manual.pdf'
                            },
                            {
                                'descripcion': 'Anexo C13',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20GI/Anexo%20C13.pdf'
                            },
                            {
                                'descripcion': 'Anexo C14',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20GI/Anexo%20C14.pdf'
                            },
                            {
                                'descripcion': 'Anexo C15',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20GI/Anexo%20C15.pdf'
                            },
                            {
                                'descripcion': 'Anexo C16',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20GI/Anexo%20C16%20-%202020.pdf'
                            },
                            {
                                'descripcion': 'Instrucciones Generales para el llenado de los Anexos de Gasto de Inversión',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20GI/Instrucciones%20generales%20para%20el%20llenado%20de%20los%20anexos%20de%20gasto%20de%20inversion.pdf'
                            }
                        ]
                    }
                ]
            },
            {
                'titulo': '',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Manual de Lineamientos y Operación del Sistema de Validación CFDI',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Manual%20de%20Lineamientos%20y%20Operacion%20del%20Sistema%20Validador%20de%20CFDI.pdf',
                        'urldos': [
                            {
                                'descripcion': 'Anexo SVA1',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2020/Anexos%20Validador/Anexo%20SVA1.docx'
                            },
                            {
                                'descripcion': 'Anexo SVA2',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2020/Anexos%20Validador/Anexo%20SVA2.docx'
                            },
                            {
                                'descripcion': 'Anexo SVA3',
                                'link': '../files/servicios/docx/EjerciciodelPresupuesto/2020/Anexos%20Validador/Anexo%20SVA3.docx'
                            },
                            {
                                'descripcion': 'Anexo SVB',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20Validador/Anexo%20SVB.pdf'
                            },
                            {
                                'descripcion': 'Instrucciones de llenado para el llenado de los Anexos del Sistema de Validación de CFDI',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2020/Anexos%20Validador/Instrucciones%20de%20llenado%20para%20el%20llenado%20de%20los%20anexos%20del%20Sistema%20de%20Validacio%CC%81n%20de%20CFDI.pdf'
                            }
                        ]
                    }
                ]
            }
        ]
    }