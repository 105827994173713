import React from 'react';
import { deinteresArray } from '../../json/deInteres';
import './deInteres.css';

const DeInteres = () => {
  return (
    <>
      <div className='row row-cols-1 row-cols-lg-2 g-0 g-lg-5 pb-5' id='r-w'>
        {
          deinteresArray.map((item,i) => (
            <div className='col' key={i}>
            {/* <div className={"col " + item.myclass} key={i}> */}
              <div>
                <>
                {
                  item.seccion.map((item,r) => (
                    <>
                      <h3 className='title' key={r}>{item.titulo}</h3>
                      <hr className='hr-gob'/>
                      {
                        item.data.map((item,t) => (
                          <div className='list-group' key={t}>
                            <div className="list-group-item list-group-item-action list-font">
                              {
                              item.url.includes("hacienda.hidalgo.gob.mx") ?
                              <div className='py-2'>
                                <li style={{listStyle: 'none'}}>
                                  <span>
                                    <i className={item.icono}></i>
                                    <a className='ps-3' href={item.url}>{item.nombre}</a>
                                  </span>
                                </li>
                              </div>:
                              <div className='py-2'>
                                <li style={{listStyle: 'none'}}>
                                  <span>
                                    <i className={item.icono}></i>
                                    <a className='ps-3' href={item.url} target="_self">{item.nombre}</a>
                                  </span>
                                </li>
                              </div>
                            }
                            </div>
                          </div>
                          ))
                      }
                    </>
                  ))
                }
                </>
              </div>
            </div>
          ))
        }
      </div>
    </>
  )
}

export default DeInteres