export const informacionFinsfuObject = 
    {
        'nombre': 'Información Financiera SFU',
        'tipo': '1',
        'servidor': '1',
        'seccion': [
            {   
                'id': '0',
                'docs': [
                    {
                        'titulo': '2018',
                        'data': [
                            {
                                'trimestre': 'Cuarto Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Cuarto Trimestre',
                                        'url': 'RendiciondeCuentas/InformacionFinSRFT'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Tercer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Tercer Trimestre',
                                        'url': 'RendiciondeCuentas/InformacionFinSRFT'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Segundo Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2018/Gestion%20de%20Proyectos_Segundo%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2018/Avance%20Financiero_Segundo%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSFU/2018/Indicadores%20Ramo%2033_Segundo%20Trimestre.zip'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Primer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2018/Gestion%20de%20Proyectos_Primer%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2018/Avance%20Financiero_Primer%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSFU/2018/Indicadores%20Ramo%2033_Primer%20Trimestre.zip'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Informe definitivo 2018',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2018/Ejercicio%20del%20Gasto_Informe%20Definitivo.xlsx'
                                    },
                                    {
                                        'nombre': 'Destino del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSRFT/2018/Destino%20del%20Gasto_Informe%20Definitivo.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores de Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSRFT/2018/Indicadores%20de%20Ramo%2033_Informe%20Definitivo.zip'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Formatos 2018',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Formato del Ejercicio, Destino y Resultado del Gasto Federalizado y Reintegros (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/Formatos/2018/Formato%20del%20Ejercicio,%20Destino%20y%20Resultado%20del%20Gasto%20Federalizado%20y%20Reintegros.xlsx'
                                    },
                                    {
                                        'nombre': 'Formato Gestión de Proyectos y Nivel Financiero (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/Formatos/2018/Formato%20Gestio%CC%81n%20de%20Proyectos%20y%20Nivel%20Financiero.xlsx'
                                    },
                                    {
                                        'nombre': 'Formato Ministración de Recursos Directos de la S.H.C.P. (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/Formatos/2018/Formato%20Ministracio%CC%81n%20de%20Recursos%20Directos%20de%20la%20S.H.C.P.xlsx'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Calendario 2018',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Calendario de Atención a Ente Público',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/Calendarios/2018/Calendario%20de%20Atencio%CC%81n%20a%20Ente%20Pu%CC%81blico.pdf'
                                    },
                                    {
                                        'nombre': 'Calendario para Registro de Avances en S.F.U.',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/Calendarios/2018/Calendario%20para%20registro%20de%20Avances%20en%20S.F.U..pdf'
                                    },
                                    {
                                        'nombre': 'Calendario para la Publicación en el Periódico Oficial',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/Calendarios/2018/Calendario%20para%20la%20publicacio%CC%81n%20en%20el%20Perio%CC%81dico%20Oficial.pdf'
                                    }
                                ]
                            }
                        ]   
                    }
                ]
            },
            {   
                'id': '1',
                'docs': [
                    {
                        'titulo': '2017',
                        'data': [
                            {
                                'trimestre': 'Cuarto Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Nivel Financiero (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2017/Nivel%20Financiero_Cuarto%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Gestión de Proyectos (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2017/Gestio%CC%81n%20de%20Proyectos_Cuarto%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSFU/2017/Indicadores_Cuarto%20Trimestre.zip'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Tercer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Gestión de Proyectos (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2017/Gestio%CC%81n%20de%20Proyectos_Tercer%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Avance Financiero (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2017/Avance%20Financiero_Tercer%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSFU/2017/Indicadores%20Ramo%2033_Tercer%20Trimestre.zip'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Segundo Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Gestión de Proyectos (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2017/Gestio%CC%81n%20de%20Proyectos_Segundo%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Avance Financiero (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2017/Avance%20Financiero_Segundo%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSFU/2017/Indicadores%20Ramo%2033_Segundo%20Trimestre.zip'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Primer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Gestión de Proyectos (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2017/Gestio%CC%81n%20de%20Proyectos_Primer%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Avance Financiero (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2017/Avance%20Financiero_Primer%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSFU/2017/Indicadores%20Ramo%2033_Primer%20Trimestre.zip'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Informe definitivo 2017',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Gestión de Proyectos (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2017/Gestio%CC%81n%20de%20Proyectos_Informe%20Definitivo.xlsx'
                                    },
                                    {
                                        'nombre': 'Avance Financiero (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2017/Avance%20Financiero_Informe%20Definitivo.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores Informe Definitivo (descargable))',
                                        'url': '../files/servicios/rar/InformacionFinancieraSFU/2017/Indicadores%20Ramo%2033_Informe%20Definitivo.zip'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Formatos 2017',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Formato del Ejercicio, Destino y Resultado del Gasto Federalizado y Reintegros (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/Formatos/2017/Formato%20del%20Ejercicio%20Destino%20y%20Resultado%20del%20Gasto%20Federalizado%20y%20Reintegros.xlsx'
                                    },
                                    {
                                        'nombre': 'Formato Gestión de Proyectos y Nivel Financiero (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/Formatos/2017/Formatos%20Gestion%20de%20Proyectos%20y%20Avance%20Financiero.xlsx'
                                    },
                                    {
                                        'nombre': 'Formato Ministración de Recursos Directos de la S.H.C.P. (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/Formatos/2017/Formato%20de%20Ministracio%CC%81n%20de%20Recursos%20Directos%20de%20la%20S.H.C.P.xlsx'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Calendario 2017',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Calendario de Atención a Ente Público',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/Calendarios/2017/Calendario%20de%20atencion%20a%20Ente%20Publico.pdf'
                                    },
                                    {
                                        'nombre': 'Calendario para Registro de Avances en S.F.U.',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/Calendarios/2017/Calendario%20para%20registro%20de%20avances%20en%20S.F.U.pdf'
                                    },
                                    {
                                        'nombre': 'Calendario para la Publicación en el Periódico Oficial',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/Calendarios/2017/Calendario%20para%20la%20publicacion%20en%20el%20Periodico%20Oficial.pdf'
                                    }
                                ]
                            }
                        ]   
                    }
                ]
            },
            {   
                'id': '2',
                'docs': [
                    {
                        'titulo': '2016',
                        'data': [
                            {
                                'trimestre': 'Cuarto Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Gestión de Proyectos (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2016/Gestio%CC%81n%20de%20Proyectos_Cuarto%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Avance Financiero (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2016/Avance%20Financiero_Cuarto%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores Ramo 33 (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSFU/2016/Indicadores%20Ramo%2033_Cuarto%20Trimestre.zip'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Tercer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Gestión de Proyectos (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2016/Gestio%CC%81n%20de%20Proyectos_Tercer%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Avance Financiero (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2016/Avance%20Financiero_Tercer%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores Ramo 33',
                                        'url': '../files/servicios/rar/InformacionFinancieraSFU/2016/Indicadores%20Ramo%2033_Tercer%20Trimestre.zip'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Segundo Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Gestión de Proyectos (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2016/Gestio%CC%81n%20de%20Proyectos_Segundo%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Avance Financiero (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2016/Avance%20Financiero_Segundo%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores Ramo 33 (descargable excel)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSFU/2016/Indicadores%20Ramo%2033_Segundo%20Trimestre.xlsx'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Primer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Ejercicio del Gasto (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2016/Gestio%CC%81n%20de%20Proyectos_Primer%20Trimestre.xls'
                                    },
                                    {
                                        'nombre': 'Avance Financiero (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2016/Avance%20Financiero_Primer%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Gestión de Proyectos (descargable excel)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSFU/2016/Indicadores%20Ramo%2033_Primer%20Trimestre.zip'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Informe definitivo 2016',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Gestión de Proyectos (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2016/Gestio%CC%81n%20de%20Proyectos_Informe%20Definitivo.xlsx'
                                    },
                                    {
                                        'nombre': 'Avance Financiero (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2016/Avance%20Financiero_Informe%20Definitivo.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores Informe Definitivo (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSFU/2016/Indicadores_Informe%20Definitivo.zip'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Formatos 2016',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Formato del Ejercicio, Destino y Resultado del Gasto Federalizado y Reintegros (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/Formatos/2016/Formato%20del%20Ejercicio,%20Destino%20y%20Resultado%20del%20Gasto%20Federalizado%20y%20Reintegros.xlsx'
                                    },
                                    {
                                        'nombre': 'Formato Gestión de Proyectos y Nivel Financiero (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/Formatos/2016/Formatos%20Gestion%20de%20Proyectos%20y%20Avance%20Financiero.xlsx'
                                    },
                                    {
                                        'nombre': 'Formato Ministración de Recursos Directos de la S.H.C.P. (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/Formatos/2016/Formato%20Ministracio%CC%81n%20de%20Recursos%20Directos%20de%20la%20S.H.C.P.xlsx'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Calendario 2016',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Calendario de Atención a Ente Público',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/Calendarios/2016/Calendario%20de%20atencion%20a%20Ente%20Publico.pdf'
                                    },
                                    {
                                        'nombre': 'Calendario para Registro de Avances en S.F.U.',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/Calendarios/2016/Calendario%20para%20registro%20de%20avances%20en%20S.F.U.pdf'
                                    },
                                    {
                                        'nombre': 'Calendario para la Publicación en el Periódico Oficial',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/Calendarios/2016/Calendario%20para%20la%20publicacion%20en%20el%20Periodico%20Oficial.pdf'
                                    }
                                ]
                            }
                        ]   
                    }
                ]
            },
            {   
                'id': '3',
                'docs': [
                    {
                        'titulo': '2015',
                        'data': [
                            {
                                'trimestre': 'Cuarto Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Gestión de Proyectos',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2015/Gestio%CC%81n%20de%20Proyectos_Cuarto%20Trimestre.pdf'
                                    },
                                    {
                                        'nombre': 'Avance Financiero',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2015/Avance%20Financiero_Cuarto%20Trimestre.pdf'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Tercer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Gestión de Proyectos',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2015/Gestio%CC%81n%20de%20Proyectos_Tercer%20Trimestre.pdf'
                                    },
                                    {
                                        'nombre': 'Avance Financiero',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2015/Avance%20Financiero_Tercer%20Trimestre.pdf'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Segundo Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Gestión de Proyectos',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2015/Gestio%CC%81n%20de%20Proyectos_Segundo%20Trimestre.pdf'
                                    },
                                    {
                                        'nombre': 'Avance Financiero',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2015/Avance%20Financiero_Segundo%20Trimestre.pdf'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Primer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Gestión de Proyectos (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2015/Gestio%CC%81n%20de%20Proyectos_Primer%20Trimestre.xlsx'
                                    },
                                    {
                                        'nombre': 'Avance Financiero (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2015/Avance%20Financiero_Primer%20Trimestre.xlsx'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Informe definitivo 2015',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Gestión de Proyectos (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2015/Gestio%CC%81n%20de%20Proyectos_Informe%20Definitivo.xlsx'
                                    },
                                    {
                                        'nombre': 'Avance Financiero (descargable excel)',
                                        'url': '../files/servicios/xls/InformacionFinancieraSFU/2015/Avance%20Financiero_Informe%20Definitivo.xlsx'
                                    },
                                    {
                                        'nombre': 'Indicadores Informe Definitivo (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSFU/2015/Indicadores_Informe%20Definitivo.zip'
                                    }
                                ]
                            }
                        ]   
                    }
                ]
            },
            {   
                'id': '4',
                'docs': [
                    {
                        'titulo': '2014',
                        'data': [
                            {
                                'trimestre': 'Cuarto Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Gestión de Proyectos',
                                        'url': '../files/servicios/rar/InformacionFinancieraSFU/2014/Gestio%CC%81n%20de%20Proyectos_Cuarto%20Trimestre.rar'
                                    },
                                    {
                                        'nombre': 'Avance Financiero',
                                        'url': '../files/servicios/rar/InformacionFinancieraSFU/2014/Avance%20Financiero_Cuarto%20Trimestre.rar'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Tercer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Gestión de Proyectos',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2014/Gestio%CC%81n%20de%20Proyectos_Tercer%20Trimestre.pdf'
                                    },
                                    {
                                        'nombre': 'Avance Financiero',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2014/Avance%20Financiero_Tercer%20Trimestre.pdf'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Segundo Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Gestión de Proyectos',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2014/Gestio%CC%81n%20de%20Proyectos_Segundo%20Trimestre.pdf'
                                    },
                                    {
                                        'nombre': 'Avance Financiero',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2014/Avance%20Financiero_Segundo%20Trimestre.pdf'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Primer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Gestión de Proyectos',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2014/Gestio%CC%81n%20de%20Proyectos_Primer%20Trimestre.pdf'
                                    },
                                    {
                                        'nombre': 'Avance Financiero',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2014/Avance%20Financiero_Primer%20Trimestre.pdf'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Informe definitivo 2014',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Gestión de Proyectos',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2014/Gestio%CC%81n%20de%20Proyectos_Informe%20Definitivo.pdf'
                                    },
                                    {
                                        'nombre': 'Avance Financiero',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2014/Avance%20Financiero_Informe%20Definitivo.pdf'
                                    }
                                ]
                            }
                        ]   
                    }
                ]
            },
            {   
                'id': '5',
                'docs': [
                    {
                        'titulo': '2013',
                        'data': [
                            {
                                'trimestre': 'Cuarto Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Formato Único',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2013/Formato%20U%CC%81nico_Cuarto%20Trimestre.pdfNivel%20Fondo_Cuarto%20Trimestre.pdf'
                                    },
                                    {
                                        'nombre': 'Nivel Fondo',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2013/Nivel%20Fondo_Cuarto%20Trimestre.pdf'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Tercer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Formato Único (descargable)',
                                        'url': '../files/servicios/rar/InformacionFinancieraSFU/2013/Formato%20U%CC%81nico_Tercer%20Trimestre.rar'
                                    },
                                    {
                                        'nombre': 'Nivel Fondo',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2013/Nivel%20Fondo_Tercer%20Trimestre.pdf'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Segundo Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Formato Único',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2013/Formato%20U%CC%81nico_Segundo%20Trimestre.pdf'
                                    },
                                    {
                                        'nombre': 'Nivel Fondo',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2013/Nivel%20Fondo_Segundo%20Trimestre.pdf'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Primer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Formato Único',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2013/Formato%20U%CC%81nico_Primer%20Trimestre.pdf'
                                    },
                                    {
                                        'nombre': 'Nivel Fondo',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2013/Nivel%20Fondo_Primer%20Trimestre.pdf'
                                    }
                                ]
                            }
                        ]   
                    }
                ]
            },
            {   
                'id': '6',
                'docs': [
                    {
                        'titulo': '2012',
                        'data': [
                            {
                                'trimestre': 'Cuarto Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Formato Único',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2012/Formato%20U%CC%81nico_Cuarto%20Trimestre.pdf'
                                    },
                                    {
                                        'nombre': 'Nivel Fondo',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2012/Nivel%20Fondo_Cuarto%20Trimestre.pdf'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Tercer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Formato Único',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2012/Formato%20U%CC%81nico_Tercer%20Trimestre.pdf'
                                    },
                                    {
                                        'nombre': 'Nivel Fondo',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2012/Nivel%20Fondo_Tercer%20Trimestre.pdf'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Segundo Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Formato Único',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2012/Formato%20U%CC%81nico_Segundo%20Trimestre.pdf'
                                    },
                                    {
                                        'nombre': 'Nivel Fondo',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2012/Nivel%20Fondo_Segundo%20Trimestre.pdf'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Primer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Formato Único',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2012/Formato%20U%CC%81nico_Primer%20Trimestre.pdf'
                                    },
                                    {
                                        'nombre': 'Nivel Fondo',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2012/Nivel%20Fondo_Primer%20Trimestre.pdf'
                                    }
                                ]
                            }
                        ]   
                    }
                ]
            },
            {   
                'id': '6',
                'docs': [
                    {
                        'titulo': '2011',
                        'data': [
                            {
                                'trimestre': 'Cuarto Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Formato Único',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2012/Formato%20U%CC%81nico_Cuarto%20Trimestre.pdf'
                                    },
                                    {
                                        'nombre': 'Nivel Fondo',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2012/Nivel%20Fondo_Cuarto%20Trimestre.pdf'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Tercer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Formato Único',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2012/Formato%20U%CC%81nico_Tercer%20Trimestre.pdf'
                                    },
                                    {
                                        'nombre': 'Nivel Fondo',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2012/Nivel%20Fondo_Tercer%20Trimestre.pdf'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Segundo Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Formato Único',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2012/Formato%20U%CC%81nico_Segundo%20Trimestre.pdf'
                                    },
                                    {
                                        'nombre': 'Nivel Fondo',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2012/Nivel%20Fondo_Segundo%20Trimestre.pdf'
                                    }
                                ]
                            },
                            {
                                'trimestre': 'Primer Trimestre',
                                'detalle': [
                                    {
                                        'nombre': 'Formato Único',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2012/Formato%20U%CC%81nico_Primer%20Trimestre.pdf'
                                    },
                                    {
                                        'nombre': 'Nivel Fondo',
                                        'url': '../files/servicios/pdf/InformacionFinancieraSFU/2012/Nivel%20Fondo_Primer%20Trimestre.pdf'
                                    }
                                ]
                            }
                        ]   
                    }
                ]
            }
        ]
    }