export const informeHaciendaPubArray = [
    {
        'nombre': 'Cuenta de la Hacienda Pública Estatal (Integrada y consolidada - LGCG)',
        'data': [
            {
                'titulo': 'Cuenta de la Hacienda Pública Estatal 2023 (Integrada y Consolidada-LGCG)',
                'link': 'https://hacienda.hidalgo.gob.mx/files/secretaria/informacionDestacada/CuentaPublica2023.pdf'
            },
            {
                'titulo': 'Cuenta de la Hacienda Pública Estatal 2022 (Integrada y Consolidada-LGCG)',
                'link': 'https://hacienda.hidalgo.gob.mx/files/secretaria/informacionDestacada/CuentaPublica2022.pdf'
            },
            {
                'titulo': 'Cuenta de la Hacienda Pública Estatal 2021 (Integrada y Consolidada-LGCG)',
                'link': 'https://hacienda.hidalgo.gob.mx/files/secretaria/informacionDestacada/CuentaPublica2021.pdf'
            },
            {
                'titulo': 'Cuenta de la Hacienda Pública Estatal 2020 (Integrada y Consolidada-LGCG)',
                'link': 'https://hacienda.hidalgo.gob.mx/files/secretaria/informacionDestacada/CuentaPublica2020.pdf'
            },
            {
                'titulo': 'Cuenta de la Hacienda Pública Estatal 2019 (Integrada y Consolidada-LGCG)',
                'link': 'https://hacienda.hidalgo.gob.mx/files/secretaria/informacionDestacada/CuentaPublica2019.pdf'
            },
            {
                'titulo': 'Cuenta de la Hacienda Pública Estatal 2018 (Integrada y Consolidada-LGCG)',
                'link': 'https://hacienda.hidalgo.gob.mx/files/secretaria/informacionDestacada/CuentaPublica2018.pdf'
            },
            // {
            //     'titulo': 'Cuenta de la Hacienda Pública Estatal 2017 (Integrada y Consolidada-LGCG)',
            //     'link': 'https://efinanzas.hidalgo.gob.mx/WebCPublica2017/'
            // },
            // {
            //     'titulo': 'Cuenta de la Hacienda Pública Estatal 2016 (Integrada y Consolidada-LGCG)',
            //     'link': 'https://efinanzas.hidalgo.gob.mx/WebCPublica2016/'
            // },
            // {
            //     'titulo': 'Cuenta de la Hacienda Pública Estatal 2015 (Integrada y Consolidada-LGCG)',
            //     'link': 'hhttps://efinanzas.hidalgo.gob.mx/WebCPublica2015/'
            // },
            // {
            //     'titulo': 'Cuenta de la Hacienda Pública Estatal 2014 (Integrada y Consolidada-LGCG)',
            //     'link': 'https://efinanzas.hidalgo.gob.mx/WebCPublica2014/'
            // }
        ]
    }
]