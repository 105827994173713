export const ejercicioPrepEgre15Object = {
    'nombre': 'Ejercicio del Presupuesto 2015',
        'tipo': '2',
        'servidor': '1',
        'seccion': [
            {
                'titulo': 'Normatividad para el Ejercicio del Presupuesto 2015',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Decreto que autoriza el Presupuesto de Egresos del Estado de Hidalgo 2015',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2015/Decreto%20que%20autoriza%20el%20Presupuesto%20de%20Egresos%20del%20Estado%20de%20Hidalgo.pdf'
                    },
                    {
                        'nombreLink':'Manual de Normas y Lineamientos para el Ejercicio del Gasto de Operación de los Programas y Proyectos 2015',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2015/Manual%20de%20Normas%20y%20Lineamientos%20para%20el%20Ejercicio%20del%20Gasto%20de%20Operacion%20de%20los%20Programas%20y%20Proyectos.pdf'
                    },
                    {
                        'nombreLink':'Manual de Normas y Lineamientos para el Ejercicio de los Recursos del Programa de Inversion 2015',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2015/Manual%20de%20Normas%20y%20Lineamientos%20para%20el%20Ejercicio%20de%20los%20Recursos%20del%20Programa%20de%20Inversion.pdf'
                    },
                    {
                        'nombreLink':'Guia de Trámites de Reparación Mayor de Vehiculos',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2015/Guia%20de%20Tramites%20de%20Reparacion%20Mayor%20de%20Vehiculos.pdf'
                    },
                    {
                        'nombreLink':'Circular No. 1',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2015/Circular%20No%201.pdf'
                    },
                    {
                        'nombreLink':'Circular No. 2',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2015/Circular%20No%202.pdf'
                    }
                ]
            }
        ]
    }