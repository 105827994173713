import React from 'react';
import './ModalConvocatoria.css';

function CloseIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}><path fill="currentColor" d="M12 2c5.53 0 10 4.47 10 10s-4.47 10-10 10S2 17.53 2 12S6.47 2 12 2m3.59 5L12 10.59L8.41 7L7 8.41L10.59 12L7 15.59L8.41 17L12 13.41L15.59 17L17 15.59L13.41 12L17 8.41z"></path></svg>
  )
}

const ModalConvocatoria = ({ isOpen, onClose }) => {
  const data = {
    url: 'https://s-contraloria.hidalgo.gob.mx/assets/imgs/Cartel.pdf',
    img: '/assets/imgs/concurso_reels.png',
    img_mobile: '/assets/imgs/concurso_reels-mobile.png'
  };
  // const data = {
  //   url: 'https://url.hidalgo.gob.mx/SFfeAD',
  //   img: 'https://cdn.hidalgo.gob.mx/convocatorias/2024/convocatoria1.png',
  //   img_mobile: 'https://cdn.hidalgo.gob.mx/convocatorias/2024/convocatoria1-mobile.png'
  // };

  if (!isOpen) {
    return null;
  }

  const irConvocatoria = () => {
    window.open(data.url, '_blank');
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <picture
          onClick={irConvocatoria}
        >
          <source media="(max-width: 768px)" srcSet={data.img_mobile} /> 
          <source media="(min-width: 769px)" srcSet={data.img} />
          <img src={data.img} alt="Convocatoria" />
        </picture>
        <div className="bottomClose">
          <CloseIcon
            fontSize='30px'
            color='black'
            onClick={onClose}
          />
        </div>
        <div
          class="button_slide slide_left"
          onClick={irConvocatoria}
        >
          Ir a la convocatoria
        </div>
      </div>
    </div>
  );
};

export default ModalConvocatoria;
