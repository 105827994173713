export const catalogosClavePrep2024Object = {
    'nombre': 'Catálogos de la Clave Presupuestaria 2024',
    'tipo': '2',
    'servidor': '1',
    'seccion': [
        {
            'titulo': '',
            'subtitulo': '',
            'docs': [
                {
                    'nombreLink':'Catálogo de Acuerdos del Plan Estatal de Desarrollo 2022-2028',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/CatalogosdelaClavePresupuestaria/Cat_logo%20de%20Acuerdos%20PED.pdf'
                },
                {
                    'nombreLink':'Catálogo de Beneficiarios',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/CatalogosdelaClavePresupuestaria/Cat_logo%20de%20Beneficiarios.pdf'
                },
                {
                    'nombreLink':'Catálogo de Entes',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/CatalogosdelaClavePresupuestaria/Catalogo%20de%20Entes%202024,%20actualizacion%2017agosto2023.pdf'
                },
                {
                    'nombreLink':'Clasificación Funcional del Gasto',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/CatalogosdelaClavePresupuestaria/Clasificaci_n%20Funcional.pdf'
                },
                {
                    'nombreLink':'Clasificación por Espacio Geográfico',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/CatalogosdelaClavePresupuestaria/Clasificaci_n%20por%20Espacio%20Geogr_fico.pdf'
                },
                {
                    'nombreLink':'Clasificador por Objeto del Gasto',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/CatalogosdelaClavePresupuestaria/Clasificaci_n%20por%20Objeto%20del%20Gasto.pdf'
                },
                {
                    'nombreLink':'Clasificación por Ramo',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/CatalogosdelaClavePresupuestaria/Clasificaci_n%20por%20Ramo.pdf'
                },
                {
                    'nombreLink':'Clasificador por Rubro de Ingresos (descargable)',
                    'url': '../../../files/servicios/xls/PaqueteHacendario/2024/Formato%20Catalogo%20CRI%202024.xlsx'
                },
                {
                    'nombreLink':'Clasificación por Tipo de Gasto',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/CatalogosdelaClavePresupuestaria/Clasificaci_n%20por%20Tipo%20de%20Gasto.pdf'
                },
                {
                    'nombreLink':'Clasificación Programática',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/CatalogosdelaClavePresupuestaria/Clasificaci_n%20Program_tica.pdf'
                },
                {
                    'nombreLink':'Indicadores Estratégicos',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/CatalogosdelaClavePresupuestaria/Indicadores%20Estrat_gicos.pdf'
                },
                {
                    'nombreLink':'Objetivos de Desarrollo Sostenible (ODS)',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/CatalogosdelaClavePresupuestaria/Objetivos%20de%20Desarrollo%20Sostenible%20(ODS).pdf'
                },
                {
                    'nombreLink':'Objetivos Transversales',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2024/CatalogosdelaClavePresupuestaria/Objetivos%20Transversales%20.pdf'
                }
            ]
        }
    ]
}