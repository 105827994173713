import React, { useEffect, useRef } from 'react';

const TweetEmbed = ({ tweetId }) => {
    const tweetRef = useRef(null);

    useEffect(() => {
        // Cargar el script de widgets de Twitter
        const script = document.createElement('script');
        script.src = "https://platform.twitter.com/widgets.js";
        script.async = true;
        script.charset = "utf-8";
        document.body.appendChild(script);

        // Limpiar el script si el componente se desmonta
        return () => {
            document.body.removeChild(script);
        };
    }, [tweetId]);

    return (
        <div>
            <blockquote
                className="twitter-tweet"
                data-lang="es"
                ref={tweetRef}
            >
                <a href={`https://twitter.com/i/status/${tweetId}`}>Cargando tweet...</a>
            </blockquote>
        </div>
    );
};

export default TweetEmbed;
