export const quienesSomosObject = {
    'nombre': 'Secretaría de Hacienda',
    'tipo': '3',
    'articulo': [
        {
            'titulo': 'Misión',
            'descripcion': 'La Secretaría de Hacienda, es una dependencia del Poder Ejecutivo del Estado de Hidalgo a la que corresponde conforme a sus atribuciones, el despacho de los asuntos relativos a las políticas de ingresos, egresos, administración tributaria, programación y evaluación del gasto público; así mismo representar el interés del sector público en controversias fiscales y en toda clase de procedimientos administrativos, ante tribunales en los que se discuta el interés fiscal del Estado, con responsabilidad y eficiencia, respetando su marco legal; y con el firme propósito de que el Gobierno financie el desarrollo del Estado, para coadyuvar en el cumplimiento del Plan Estatal de Desarrollo 2022-2028, con lo que se precisa los servicios que debe ofrecer a la sociedad Hidalguense.',
            'data': [
                {
                    'titulolink': '',
                    'url': '',
                    'docs': [
                        {
                            'subtitulolink': '',
                            'url': ''
                        }
                    ]
                }
            ]
        },
        {
            'titulo': 'Visión',
            'descripcion': 'La política financiera del gobierno estatal busca incrementar la honestidad y transparencia de las acciones, así como la oportunidad y la eficiencia en la recaudación y el manejo de los recursos públicos que privilegien obras, proyectos y acciones prioritarias en favor de los hidalguenses, sin recurrir a mayores niveles de endeudamiento interno, en congruencia con la política de disciplina financiera que permita la sostenibilidad de las finanzas públicas.',
            'data': [
                {
                    'titulolink': '',
                    'url': '',
                    'docs': [
                        {
                            'subtitulolink': '',
                            'url': ''
                        }
                    ]
                }
            ]
        },
        {
            'titulo': 'Objetivo',
            'descripcion': 'Orientar en cada ejercicio fiscal, la política estatal sobre el manejo de las finanzas públicas, para fortalecer la capacidad de crecimiento de la economía de manera sostenida, fomentar la inversión productiva, generar empleos y, coadyuvar en la mejora de la distribución de los recursos entre los distintos grupos de la población y las regiones, garantizando a la ciudadanía y a las instituciones gubernamentales, el pleno y libre acceso a la información pública del Estado.',
            'data': [
                {
                    'titulolink': '',
                    'url': '',
                    'docs': [
                        {
                            'subtitulolink': '',
                            'url': ''
                        }
                    ]
                }
            ]
        }
    ]
}
