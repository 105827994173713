import React from 'react';
import { informeHaciendaPubArray } from '../../json/Transparencia/informeHaciendaPublica';

const InformeHaciendaPublica = () => {
  return (
    <>
    <div className='container pt-5'>
      <div className='row'>
        {
          informeHaciendaPubArray.map((item, y) => (
            <>
              <div key={y}>
                  <h3 className='title mt-5 pt-5'>{ item.nombre }</h3>
                  <hr className='hr-gob'/>
              </div>
              
              <div>
                <div className="accordion accordion-flush px-0 mb-5" id="accordionExample">
                  {
                    item.data.map((name, m) => (
                      <div className="accordion-item" key={m}>
                        <h4 className="accordion-header py-1" id="headingOne">
                          <button className="accordion-button collapsed py-3 fs-6 fw-bold" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse_" + m + "_" + y} aria-expanded="true" aria-controls={"#collapse_" + m + "_" + y}>
                            { name.titulo }
                          </button>
                        </h4>
                        <div id={"collapse_" + m + "_" + y} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            <div className="ratio ratio-4x3 mb-5 me-5 ms-2">
                                <iframe className="mi-iframe embed-responsive-item" src={ name.link } frameborder="0" allowfullscreen></iframe>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </>
          ))
        }
      </div>
    </div>
  </>
  )
}

export default InformeHaciendaPublica