export const formatosDisciplinaFin = [
    {
        'nombre': 'Formatos a que hace referencia la Ley de Disciplina Financiera de las Entidades Federativas y los Municipios',
        'anio': [
            {
                'ejercicio': 'Ejercicio 2024',
                'data': [
                    // {
                    //     'trimestre': 'Cuarto Trimestre',
                    //     'detalle': [
                    //         {
                    //             'titulo': 'Estado de Situación Financiera Detallado',
                    //             'urlxlsx': '',
                    //             'urlpdf': ''
                    //         },
                    //         {
                    //             'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                    //             'urlxlsx': '',
                    //             'urlpdf': ''
                    //         },
                    //         {
                    //             'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                    //             'urlxlsx': '',
                    //             'urlpdf': ''
                    //         },
                    //         {
                    //             'titulo': 'Balance Presupuestario',
                    //             'urlxlsx': '',
                    //             'urlpdf': ''
                    //         },
                    //         {
                    //             'titulo': 'Estado Analítico de Ingresos Detallado',
                    //             'urlxlsx': '',
                    //             'urlpdf': ''
                    //         },
                    //         {
                    //             'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                    //             'urlxlsx': '',
                    //             'urlpdf': ''
                    //         },
                    //         {
                    //             'titulo': 'EAEPE Clasificación Administrativa',
                    //             'urlxlsx': '',
                    //             'urlpdf': ''
                    //         },
                    //         {
                    //             'titulo': 'EAEPE Clasificación Funcional',
                    //             'urlxlsx': '',
                    //             'urlpdf': ''
                    //         },
                    //         {
                    //             'titulo': 'EAEPE Clasificación por Servicios Personales',
                    //             'urlxlsx': '',
                    //             'urlpdf': ''
                    //         }
                    //     ]
                    // },
                    {
                        'trimestre': 'Tercer Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3323/01_Estado_de_Situaci%C3%B3n_Financiera_Detallado_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3323/01_Estado_de_Situaci%C3%B3n_Financiera_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3323/02_Estado_Anal%C3%ADtico_de_la_Deuda_y_Otros_Pasivos_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3323/02_Informe_Anal%C3%ADtico_de_la_Deuda_y_Otros_Pasivos_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3323/03_Informe_Anal%C3%ADtico_de_obligaciones_diferentes.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3323/03_Informe_Anal%C3%ADtico_de_Obligaciones_Diferentes_de_Financiamiento_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3323/04_Balance_Presupuestario_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3323/04_Balance_Presupuestario_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3323/05_Estado_Analitico_de_Ingresos_Detallado_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3323/05_Estado_Anal%C3%ADtico_de_Ingresos_Detallado_-_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3323/06_a)EAEPED_LDF_Clasificaci%C3%B3n_por_Objeto_Gasto.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3323/06_a)EAEPED_Clasificaci%C3%B3n_por_Objeto_del_Gasto_(Cap%C3%ADtulo_y_Concepto)_-_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3323/06_b)EAEPED_Clasificaci%C3%B3n_Administrativa_-_LDF.pdf',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3323/06_b)EAEPED_Clasificaci%C3%B3n_Administrativa_-_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3323/06_c)EAEPED_LDF_Clasificaci%C3%B3n_Funcional.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3323/06_c)EAEPED_Clasificaci%C3%B3n_Funcional_(Finalidad_y_Funci%C3%B3n)_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3323/06_d)_EAEPED_LDF_Clasificaci%C3%B3n_Servicios_Personales.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3323/06_d)EAEPED_Clasificaci%C3%B3n_de_Servicios_Personales_por_Categor%C3%ADa_-_LDF.pdf'
                            }
                        ]
                    },
                    {
                        'trimestre': 'Segundo Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3232/01_Estado_de_Situaci%C3%B3n_Financiera_Detallado_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3232/01_Estado_de_Situaci%C3%B3n_Financiera_Detallado_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3232/02_Informe_Anal%C3%ADtico_de_la_Deuda_P%C3%BAblica_y_Otros_Pasivos_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3232/02_Informe_Anal%C3%ADtico_de_la_Deuda_y_Otros_Pasivos_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3232/03_Informe_Anal%C3%ADtico_de_Obligaciones_Diferentes_de_Financiamientos_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3232/03_Informe_Anal%C3%ADtico_de_Obligaciones_Diferentes_de_Financiamientos_LDF.pdf'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3232/04_Balance_Presupuestario.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3232/04_Balance_Presupuestario_LDF.pdf'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3232/05_Estado_Anal%C3%ADtico_de_Ingresos_Detallado_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3232/05_Estado_Anl%C3%ADtico_de_Ingresos_Detallado_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3232/06_a)EAEPED_Clasificaci%C3%B3n_por_Objeto_del_Gasto_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3232/06_a)EAEPED_LDF_Clasificaci%C3%B3n_por_Objeto_del_Gasto_(Cap%C3%ADtulo_y_Concepto).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3232/06_b)EAEPED_Clasificaci%C3%B3n_Administrativa_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3232/06_b)EAEPED_LDF_Clasificaci%C3%B3n_Administrativa.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3232/06_c)EAEPED_Clasificaci%C3%B3n_Funcional_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3232/06_c)EAEPED_LDF_Clasificaci%C3%B3n_Funcional_(Finalidad_y_Funci%C3%B3n).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3232/06_d)EAEPED_Clasificaci%C3%B3n_de_Servicios_Personales_por_Categoria_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/3232/06_d)EAEPED_LDF_Clasificaci%C3%B3n_de_Servicios_Personales.pdf'
                            }
                        ]
                    },
                    {
                        'trimestre': 'Primer Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2430/01_Estado_de_Situaci%C3%B3n_Financiera_Detallado.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2430/01_Estado_de_Situaci%C3%B3n_Financiera_Detallado_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2430/02_Informe_Anal%C3%ADtico_de_la_Deuda_y_Otros_Pasivo.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2430/02_Informe_Anal%C3%ADtico_de_la_Deuda_y_Otros_Pasivos.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2430/03_Informe_Anal%C3%ADtico_de_Obligaciones_Diferentes.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2430/03_Informe_Anal%C3%ADtico_de_Obligaciones_Diferentes_de_Financiamiento_LDF.pdf'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2430/04_Balance_Presupuestario.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2430/04_Balance_Presupuestario_LDF.pdf'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2430/05_Estado_Anal%C3%ADtico_de_Ingresos_Detallado.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2430/05_Estado_Anal%C3%ADtico_de_ingresos_Detallado_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2430/06_a)EAEPED_LDF_Clasificaci%C3%B3n_por_Objeto_del_Gasto.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2430/06_a)EAEPED_LDF_Clasificaci%C3%B3n_por_Objeto_del_Gasto_(Cap%C3%ADtulo_y_Concepto).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2430/06_b)EAEPED_LDF_Clasificaci%C3%B3n_Administrativa.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2430/06_b)EAEPED_LDF_Clasificaci%C3%B3n_Administrativa.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2430/06_c)EAEPED_LDF_Clasificaci%C3%B3n_Funcional_(Finalidad_y_Funci%C3%B3n).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2430/06_c)EAEPED_LDF_Clasificaci%C3%B3n_Funcional_(Finalidad_y_Funci%C3%B3n).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2430/06_d)EAEPED_LDF_Clasificaci%C3%B3n_de_Servicios_Personales_por_Categoria.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2430/06_d)EAEPED_LDF_Clasificaci%C3%B3n_Servicios_Personales.pdf'
                            }
                        ]
                    }
                ]
            },
            {
                'ejercicio': 'Ejercicio 2023',
                'data': [
                    {
                        'trimestre': 'Cuarto Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2439/01_Estado__de__Situaci%C3%B3n__Financiera_Detallado_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2439/01_Estado_de_Situaci%C3%B3n_Financiera_Detallado_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2439/02_Informe__Anal%C3%ADtico__de__la__Deuda__P%C3%BAblica__y__Otros__Pasivos_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2439/02_Informe_Anal%C3%ADtico_de_la_Deuda_P%C3%BAblica_y_Otros_Pasivos_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2439/03_Informe_Anal%C3%ADtico_de_Obligaciones_Diferentes_de_Financiamientos_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2439/03_Informe_Anal%C3%ADtico_de_Obligaciones_Diferentes_de_Financiamiento_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2439/04_Balance_Presupuestario_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2439/04_Balance_Presupuestario_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2439/05_Estado_Anal%C3%ADtico_de_Ingresos_Detallado_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2439/05_Estado_Anal%C3%ADtico_de_Ingresos_Detallado_-_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2439/06_a)EAEPED_LDF_Clasificaci%C3%B3n_por_Objeto_del_Gasto.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2439/06_a)EAEPED_LDF_Clasificaci%C3%B3n_por_Objeto_del_Gasto_(Cap%C3%ADtulo_y_Concepto).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2439/06_b)EAEPED_LDF_Clasificaci%C3%B3n_Administrativa.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2439/06_b)EAEPED_LDF_Clasificaci%C3%B3n_Administrativa.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2439/06_c)EAEPED_LDF_Servicios_Personales_Detallado.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2439/06_c)_EAEPED_LDF_Clasificaci%C3%B3n_Funcional_(Finalidad_y_Funci%C3%B3n).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2439/06_d)EAEPED_LDF_Servicios_Personales_Detallado.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2439/06_d)EAEPED_LDF_Clasificaci%C3%B3n_Servicios_Personales.pdf'
                            }
                        ]
                    },
                    {
                        'trimestre': 'Tercer Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2363/01_Estado_de_Situaci%C3%B3n_Financiera_Detallado_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2363/01_Estado_de_Situaci%C3%B3n_Financiera_Detallado_-_LDF.PDF'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2363/02_Informe_Anal%C3%ADtico_de_la_Deuda_P%C3%BAblica_y_Otros_Pasivos_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2363/02_Informe_Anal%C3%ADtico_de_la_Deuda_P%C3%BAblica_y_Otros_Pasivos_-_LDF.PDF'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2363/03_Informe_Anal%C3%ADtico_de_Obligaciones_diferentes_de_Financiamiento_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2363/03_Informe_Anal%C3%ADtico_de_Obligaciones_diferentes_de_Financiamiento_-_LDF.PDF'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2363/04_Balance_Presupuestario_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2363/04_Balance_Presupuestario_-_LDF.PDF'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2363/05_Estado_Anal%C3%ADtico_de_Ingresos_Detallado_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2363/05_Estado_Anal%C3%ADtico_de_Ingresos_Detallado_-_LDF.PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2363/06_a)EAEPED_Clasificacion_por_Objeto_del_Gasto_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2363/06_a)EAEPED_Clasificacion_por_Objeto_del_Gasto_-_LDF.PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2363/06_b)EAEPED_Clasificacion_Administrativa_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2363/06_b)EAEPED_Clasificacion_Administrativa_-_LDF.PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2363/06_c)EAEPED_Clasificaci%C3%B3n_Funcional_(Finalidad_y_Funci%C3%B3n)_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2363/06_c)EAEPED_Clasificaci%C3%B3n_Funcional_(Finalidad_y_Funci%C3%B3n)_-_LDF.PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2363/06_d)EAEPED_Clasificaci%C3%B3n_Servicios_Personales_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2363/06_d)EAEPED_Clasificaci%C3%B3n_Servicios_Personales_-_LDF.PDF'
                            }
                        ]
                    },
                    {
                        'trimestre': 'Segundo Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2323/01_Estado_de_Situaci%C3%B3n_Financiera_Detallado_LDF.xls',
                                'urlpdf': 'https://finanzas.hidalgo.gob.mx/transparenciafinanzas/Docs/EdosFin/2323/01_Estado_de_Situaci%C3%B3n_Financiera_Detallado_-_LDF.PDF'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2323/02_Informe_Analit%C3%ADco_de_la_Deuda_y_Otros_Pasivos_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2323/02_Informe_Anal%C3%ADtico_de_la_Deuda_P%C3%BAblica_y_Otros_Pasivos_-_LDF.PDF'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2323/03_Informe_Analit%C3%ADco_de_Obligaciones_Diferentes_de_Financiamiento_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2323/03_Informe_Anal%C3%ADtico_de_Obligaciones_Diferentes_de_Financiamientos_-_LDF.PDF'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2323/04_Balance_Presupuestario_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2323/04_Balance_Presupuestario_-_LDF.PDF'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2323/05_Estado_Anal%C3%ADtico_de_Ingresos_Detallado_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2323/05_Estado_Anal%C3%ADtico_de_Ingresos_Detallado_-_LDF.PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2323/06_a)_EAEPE_LDF_-_Clasificaci%C3%B3n_por_Objeto_del_gasto_(Capitulo_y_Concepto).xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2323/06_a)_EAEPE_Detallado_-_LDF_-_Clasificaci%C3%B3n_por_Objeto_del_Gasto_(Cap%C3%ADtulo_y_Concepto).PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2323/06_b)_EAEPE_LDF_-_Clasificaci%C3%B3n_Administrativa.xls.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2323/06_b)_EAEPE_Detallado_-_LDF_-_Clasificaci%C3%B3n_Administrativa.PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2323/06_c)_EAEPE_LDF_-_Clasificaci%C3%B3n_Funcional_(Finalidad_y_Funci%C3%B3n).xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2323/06_c)_EAEPE_Detallado_-_LDF_-_Clasificaci%C3%B3n_Funcional_(Finalidad_y_Funci%C3%B3n).PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2323/06_d)_EAEPE_LDF_-_Clasificaci%C3%B3n_de_Servicios_Personales_por_Categor%C3%ADa_.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2323/06_d)_EAEPE_Detallado_-_LDF_-_Clasificaci%C3%B3n_de_Servicios_Personales_por_Categor%C3%ADa.PDF'
                            }
                        ]
                    },
                    {
                        'trimestre': 'Primer Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2248/01_Estado_de_Situaci%C3%B3n_Financiera_Detallado_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2248/01_Estado_de_Situaci%C3%B3n_Financiera_Detallado_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2248/02_Informe_Anal%C3%ADtico_de_la_Deuda_y_Otros_Pasivos.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2248/02_Informe_Anal%C3%ADtico_de_la_Deuda_y_Otros_Pasivos_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2248/03_Informe_Anal%C3%ADtico_de_Obligaciones_Diferentes_de_Financiamiento.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2248/03_Informe_Anal%C3%ADtico_Diferentes_de_Financiamientos_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2248/04_Balance_Presupuestario_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2248/04_Balance_Presupuestario_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2248/05_Estado_Anl%C3%ADtico_de_Ingresos_Detallado_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2248/05_Estado_Anal%C3%ADtico_de_Ingresos_Detallado_-_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2248/06_a)_EAEPED_LDF_Clasificaci%C3%B3n_por_Objeto_del_Gasto.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2248/06_a)_EAEPED_-_LDF_Clasificaci%C3%B3n_por_Objeto_del_Gasto_(Cap%C3%ADtulo_y_Concepto).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2248/06_b)_EAEPED_LDF_Clasificaci%C3%B3n_Administrativa.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2248/06_b)_EAEPED_-_LDF_Clasificaci%C3%B3n_Administrativa.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2248/06_c)_EAEPED_LDF_Clasificaci%C3%B3n_Funcional_(Finalidad_y_Funci%C3%B3n).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2248/06_c)_EAEPED_-_LDF_Clasificaci%C3%B3n_Funcional_(Finalidad_y_Funci%C3%B3n).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2248/06_d)_EAEPED_LDf_Clasificaci%C3%B3n_Servicios_Personales.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2248/06_d)_EAEPED_-_LDF_Clasificaci%C3%B3n_Servicios_Personales_por_Categor%C3%ADa.pdf'
                            }
                        ]
                    }
                ]
            },
            {
                'ejercicio': 'Ejercicio 2022',
                'data': [
                    {
                        'trimestre': 'Cuarto Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2208/01._Estado_de_Situaci%C3%B3n_FInanciera_Detallado_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2208/01_Estado_de_Situaci%C3%B3n_Financiera_Detallado_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2208/02._Informe_Analitico_de_la_Deuda_y_Otros_Pasivos_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2208/02_Informe_Anal%C3%ADtico_de_la_Deuda_Publica_y_Otros_Pasivos_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2208/03._Informe_Analitico_de_Obligciones_Diferentes_de_Financiamiento_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2208/03_Informe_Anal%C3%ADtico_de_Obligaciones_Diferentes_de_Financiamientos_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2208/04._Balance_Presupuestario_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2208/04_Balance_Presupuestario_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2208/05._Informe_Analitico_de_Ingresos_Detallados_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2208/05_Estado_Anal%C3%ADtico_de_Ingresos_Detallado_-_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2208/06_a)_EAEPED_-_LDF_Clasificaci%C3%B3n_por_Objeto_del_Gasto_(Cap%C3%ADtulo_y_concepto).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2208/06_a)EAEPED_-_LDF_Clasificaci%C3%B3n_por_Objeto_del_Gasto_(Cap%C3%ADtulo_y_Concepto).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2208/06_b)_EAEPED_-_LDF_Clasificaci%C3%B3n_Administrativa.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2208/06_b)EAEPED_-_LDF_Clasificaci%C3%B3n_Administrativa.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2208/06_c)_EAEPED_-_LDF_Clasificaci%C3%B3n_Funcional_(Finalidad_y_Funci%C3%B3n).xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2208/06_c)EAEPED_-_LDF_Clasificaci%C3%B3n_Funcional_(Finalidad_y_Funci%C3%B3n).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2208/06_d)EAEPED_-_LDF_Clasificaci%C3%B3n_Servicios_Personales.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2208/06_d)EAEPED_-_LDF_Clasidicaci%C3%B3n_de_Servicios_por_Categor%C3%ADa.pdf'
                            }
                        ]
                    },
                    {
                        'trimestre': 'Tercer Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2173/01_Estado_de_Situacion_Financiera_Detallado_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2173/01_Estado_de_Situacion_Financiera_Detallado_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2173/02_Informe_Analitico_de_la_Deuda_Publica_y_Otros_Pasivos.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2173/02_Informe_Analitico_de_la_Deuda_Publica_y_Otros_Pasivos_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2173/03_Informe_Analitico_de_Obligaciones_Diferentes_de_Financiamiento.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2173/03_Informe_Analitico_de_Obligaciones_Diferentes_de_Financiamiento_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2173/04_Balance_Presupuestario_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2173/04_Balance_Presupuestario_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2173/05_Estado_Analitico_de_Ingresos_Detallado_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2173/05_Estado_Analitico_de_Ingresos_Detallado_-_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2173/06_a)_EAEPED-LDF_Clasificacion_por_Objeto_del_Gasto_(Capitulo_y_Concepto).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2173/06_a)_Estado_Analitico_del_Ejercicio_del_Presupuesto_de_Egresos_Detallado_-_LDF_Clasificacion_por_Objeto_del_Gasto.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2173/06_b)_EAEPED-LDF_Clasificacion_Administrativa.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2173/06_b)_Estado_Analitico_del_Ejercicio_del_Presupuesto_de_Egresos_Detallado_-_LDF_Clasificacion_Administrativa.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2173/06_c)_EAEPED-LDF_Clasificacion_Funcional_(finalidad_y_Funcion).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2173/06_c)_Estado_Analitico_del_Ejercicio_del_Presupuesto_de_Egresos_Detallado_-_LDF_Clasificaci%C3%B3n_Funcional_(Finalidad_y_Funcion).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2173/06_d)_EAEPED-LDF_Clasificacion_Servicios_Personales_por_Categoria.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2173/06_d)_Estado_Analitico_del_Ejercicio_del_Presupuesto_de_Egresos_Detallado_-_LDF_Clasificaci%C3%B3n_de_Servicios_Personales_por_Categoria.pdf'
                            }
                        ]
                    },
                    {
                        'trimestre': 'Segundo Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2158/01_Estado_de_Situaci%C3%B3n_Financiera_Detallado_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2158/01_Estado_de_Situaci%C3%B3n_Financiera_Detallado_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2158/02_Informe_Anal%C3%ADtico_de_la_Deuda_y_Otros_Pasivos_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2158/02_Informe_Anal%C3%ADtico_de_la_Deuda_P%C3%BAblica_y_Otros_Pasivos_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2158/03_Informe_Anal%C3%ADtico_de_Obligaciones_diferentes_de_Financiamiento.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2158/03_Informe_Anal%C3%ADtico_de_Obligaciones_Diferentes_de_Financiamientos_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2158/04_Balance_Presupuetario_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2158/04_Balance_Presupuestario_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2158/05_Estado_Anal%C3%ADtico_de_Ingresos_Detallado_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2158/05_Estado_Anal%C3%ADtico_de_Ingresos_Detallado_-_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2158/06_a)EAEPED_-_LDF_Clasificaci%C3%B3n_por_Objeto_del_Gasto_(Cap%C3%ADtulo_y_Concepto).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2158/06_a)_EAEPED_-_LDF_Clasificaci%C3%B3n_por_Objeto_del_Gasto_(Cap%C3%ADtulo_y_concepto).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2158/06_b)EAEPED_-_LDF_Clasificaci%C3%B3n_Administrativa.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2158/06_b)_EAEPED_-_LDF_Clasificaci%C3%B3n_Administrativa.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2158/06_c)EAEPED_-_LDF_Clasificaci%C3%B3n_Funcional_(Finalidad_y_Funci%C3%B3n).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2158/06_c)_EAEPED_-_LDF_Clasificaci%C3%B3n_Funcional_(Finalidad_y_Funci%C3%B3n).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2158/06_d)_EAEPED_-_LDF_Clasificaci%C3%B3n_de_Servicios_Personales_por_Categor%C3%ADa.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/2158/06_d)_EAEPED_-_LDF_Clasificaci%C3%B3n_de_Servicios_Personales_por_Categor%C3%ADa.pdf'
                            }
                        ]
                    },
                    {
                        'trimestre': 'Primer Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1920/01_Estado_de_Situacion_Financiera_Detallado.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1920/01_Estado_de_Situaci%C3%B3n_Financiera_Detallado_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1920/02_Informe_Anal%C3%ADtico_de_la_Deuda_y_Otros_Pasicvos_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1920/02_Informe_Anal%C3%ADtico_de_la_Deuda_P%C3%BAblica_y_Otros_Pasivos_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1920/03_Informe_Anal%C3%ADtico_de_Obligaciones_Diferentes_de_Financiamiento_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1920/03_Informe_Anal%C3%ADtico_de_Obligaciones_Diferentes_de_Financiamiento_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1920/04_Balance_Presupuestario.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1920/04_Balance_Presupuestario_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1920/05_Estado_Analitico_de_Ingresos_Detallado_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1920/05_Estado_Anal%C3%ADtico_de_Ingresos_Detallados__LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1920/06_a)EAEPEED_LDF_Clasificaci%C3%B3n_por_Objeto_del_Gasto_(Cap%C3%ADtulo_y_Concepto).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1920/06_a)_EAEPED_LDF_Clasificaci%C3%B3n_por_Objeto_del_Gasto_(Cap%C3%ADtulo_y_Concepto).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1920/06_b)EAEPEED_LDF_Clasificaci%C3%B3n_Administrativa_.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1920/06_b)_EAEPED_LDF_Clasificaci%C3%B3n_Administrativa.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1920/06_c)EAEPED_LDF_Clasificaci%C3%B3n_Funcional_(Finalidad_y_Funci%C3%B3n).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1920/06_c)_EAEPED_Clisificaci%C3%B3n_Funcional_(Finalidad_y_Funci%C3%B3n).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1920/06_d)EAEPEED_LDF_(Clasificaci%C3%B3n_Servicios_Personales_por_Categor%C3%ADa).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1920/06_d)_EAEPED_LDF_Clasificaci%C3%B3n_de_Servicios_Personales_por_Categor%C3%ADa.pdf'
                            }
                        ]
                    }
                ]
            },
            {
                'ejercicio': 'Ejercicio 2021',
                'data': [
                    {
                        'trimestre': 'Cuarto Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1871/01_Estado_de_Situacion_Financiera.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1871/01_Estado_de_Situacion_Financiera_Detallado_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1871/02_Informe_Analitico_de_la_Deuda_y_Otros_Pasivos.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1871/02_Informe_Analitico_de_la_Deuda_y_Otros_Pasivos_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1871/03_Informe_Analitico_de_Obligaciones_diferentes_de_Financiamiento.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1871/03_Informe_Analitico_de_Obligaciones_Diferentes_de_Financiamiento_LDF.pdf'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1871/04_Balance_Presupuestario.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1871/04_Balance_Presupuestario_LDF.pdf'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1871/05_Estado_Analitico_del_Ingreso_Detallado_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1871/05_Estado_Analitico_de__Ingresos_Detallado_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1871/06_a)_EAEPED_-_LDF_(Clasificacion_por_Objeto_del_Gasto).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1871/06_a)EAEPED_LDF_Clasificacion_por_Objeto_del_Gasto_(Capitulo_y_Concepto).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1871/06_b)_EAEPED_-_LDF_(Clasificacion_Administrativa).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1871/06_b)_EAEPED_LDF_Clasificacion_Administrativa.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1871/06_c)_EAEPED_-_LDF_(Clasificacion_Funcional).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1871/06_c)_EAEPED_LDF_Clasificacion_Funcional_(Finalidad_y_Funcion).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1871/06_d)_EAEPED_-_LDF_(Clasificacion_de_Servicios_Personales_por_Categoria).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1871/06_d)_EAEPED_LDF_(Clasificacion_de_Servicios_Personales_por_Categoria).pdf'
                            }
                        ]
                    },
                    {
                        'trimestre': 'Tercer Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1881/01_Estado_de_Situaci%C3%B3n_Financiera_Detallado_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1881/01_Estado_de_Situaci%C3%B3n_Financiera_Detallado-LDF.PDF'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1881/02_Informe_Anal%C3%ADtico_de_la_Deuda_P%C3%BAblica_y_Otros_Pasivos_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1881/02_Informe_Anal%C3%ADtico_de_la_Deuda_P%C3%BAblica_y_Otros_Pasivos-LDF.PDF'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1881/03_Informe_Anal%C3%ADtico_de_Obligaciones_Diferentes_de_Financiamiento_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1881/03_Informe_Anal%C3%ADtico_de_Obligaciones_Diferentes_de_Financiamientos-LDF.PDF'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1881/04_Balance_Presupuestario_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1881/04_Balance_Presupuestario-LDF.PDF'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1881/05_Estado_Anal%C3%ADtico_de_Ingresos_Detallado_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1881/05_Estado_Anal%C3%ADtico_de_Ingresos_Detallado-LDF.PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1881/06_a)_EAEPED_-_LDF_Clasificaci%C3%B3n_por_Objeto_del_Gasto_(Capitulo_y_Concepto).xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1881/06_a)EAEPED-LDF_Clasificaci%C3%B3n_por_Objeto_del_Gasto_(Cap%C3%ADtulo_y_Concepto).PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': '',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1881/07_EAEPED-LDF_Clasificacion_Administrativa.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1881/06_c)_EAEPED_-_LDF_Clasificaci%C3%B3n_Funcional_(Finalidad_y_Funci%C3%B3n).xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1881/06_c)EAEPED-LDF_Clasificaci%C3%B3n_Funcional_(Finalidad_y_Funci%C3%B3n).PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1881/06_d)_EAEPED_-_LDF_(Clasificaci%C3%B3n_de_Servicios_Personales_por_Categoria).xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1881/06_d)_EAEPED-LDF_Clasificaci%C3%B3n_de_Servicios_Personales_por_Categor%C3%ADa.PDF'
                            }
                        ]
                    },
                    {
                        'trimestre': 'Segundo Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1458/01_Estado_de_Situacion_Financiera_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1458/01_Estado_de_Situacion_Financiera_Detallado_LDF.PDF'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1458/02_Informe_Anal%C3%ADtico_de_la_Deuda_y_Otros_Pasivos_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1458/02_Informe_Analitico_de_la_Deuda_y_Otros_Pasivos_LDF.PDF'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1458/03_Informe_Analitico_de_Obligaciones_Diferentes_de_Financiamiento_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1458/03_Informe_Analitico_de_Obligaciones_Diferentes_de_Financiamiento_LDF.PDF'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1458/04_Balance_Presupuestario_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1458/04_Balance_Presupuestario_LDF.PDF'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1458/05_Estado_Anal%C3%ADtico_de_Ingresos_Detallado_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1458/05_Estado_Analitico_de_Ingresos_Detallado_LDF.PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1458/06_a)EAEPED_LDF_Clasificacion_por_Objeto_del_Gasto.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1458/06_a)_EAEPED_LDF_Clasificacion_por_Objeto_del_Gasto_(Capitulo_y_Concepto).PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1458/06_b)EAEPED_LDF_Clasificacion_Administrativa_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1458/06_b)_EAEPED_LDF_Clasificacion_Administrativa.PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1458/06_c)EAEPED_LDF_Clasificacion_Funcional_(Finalidad_y_Funcion).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1458/06_c)_EAEPED_LDF_Clasificacion_Funcional_(Finalidad_y_Funcion).PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1458/06_d)EAEPED_LDF_Clasificacion_Servicios_Personales_por_Categoria.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1458/06_d)_EAEPED_LDF_(Clasificacion_de_Servicios_Personales_por_Categoria).PDF'
                            }
                        ]
                    },
                    {
                        'trimestre': 'Primer Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1414/01_Estado_de_Situacion_Financiera_Detallado_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1414/01_Estado_de_Situacion_Financiera_Detallado_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1414/02_Informe_AnalItico_de_la_Deuda_y_Otros_Pasivos_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1414/02_Informe_Analitico_de_la_Deuda_y_Otros_Pasivos_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1414/03_Informe_Analitico_de_Obligaciones_Diferentes_de_Financiamiento_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1414/03_Informe_Analitico_de_Obligaciones_Diferentes_de_Financiamiento_LDF.pdf'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1414/04_Balance_Presupuestario__LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1414/04_Balance_Presupuestario_LDF.pdf'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1414/05_Estado_Analitico_de_Ingresos_Detallado.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1414/05_Estado_Analitico_de_Ingresos_Detallado_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1414/06_a)_EAEPED_LDF_Clasificacion_por_Objeto_del_Gasto_(Capitulo_y_Concepto).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1414/06_a)_EAEPED_LDF_Clasificacion_por_Objeto_del_Gasto_(Capitulo_y_Concepto).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1414/06_b)_EAEPED_LDF_Clasificacion_Administrativa.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1414/06_b)_EAEPED_LDF_Clasificacion_Administrativa.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1414/06_c)_EAEPED_LDF_Clasificacion_Funcional_(Finalidad_y_Funcion).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1414/06_c)_EAEPED_LDF_Clasificacion_Funcional_(Finalidad_y_Funcion).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1414/06_d)_EAEPED_LDF_(Clasificacion_de_Servicios_Personales).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1414/06_d)_EAEPED_LDF_(Clasificacion_de_Servicios_Personales_por_Categoria).pdf'
                            }
                        ]
                    }
                ]
            },
            {
                'ejercicio': 'Ejercicio 2020',
                'data': [
                    {
                        'trimestre': 'Cuarto Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1517/01_Estado_de_situacion_Financiera_Detallado_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1517/01_Estado_de_Situacion_Financiera_Detallado_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1517/02_Informe_Analitico_de_la_Deuda_y_Otros_Pasivos_-LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1517/02_Informe_Analitico_de_la_Deuda_y_Otros_Pasivos_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1517/03_Informe_Analitico_de_Obligaciones_Diferentes_de_Financiamiento_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1517/03_Informe_Analitico_de_Obligaciones_Diferentes_de_Financiamiento_LDF.pdf'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1517/04_Balance_Presupuestario.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1517/04_Balance_Presupuestario_LDF.pdf'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1517/05_Estado_Anal%C3%ADtico_de_Ingresos_Detallado.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1517/05_Estado_Analitico_de_Ingresos_Detallado_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1517/06_a)_EAEPED_-_LDF_Clasificacion_por_Objeto_del_Gasto__(Capitulo__y_Concepto).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1517/06_a)_EAEPD__LDF_Clasificacion_por_Objeto_del_Gasto_(Capitulo_y_Concepto).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1517/06_b)_EAEPED_-_LDF_Clasificacion_Administrativa.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1517/06_b)_EAEPED_LDF_Clasificacion_Administrativa.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1517/06_c)_EAEPED_-_LDF_Clasificacion_Funcional_(Finalidad_y_Funcion).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1517/06_c)_EAEPED_LDF_Clasificacion_Funcional_(Finalidad_y_Funcion).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1517/06_d)_EAEPED_-_LDF_(Clasificacion_de_Servicios_Personales).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1517/06_d)_EAEPED_LDF_Clasificacion_de_Servicios_Personales_por_Categoria.pdf'
                            }
                        ]
                    },
                    {
                        'trimestre': 'Tercer Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1502/01_Estado_de_Situacion_Financiera-LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1502/01_Estado_de_Situacion_Financiero_Detallado_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1502/02_Informe_Analitico_de_la_Deuda_y_Otros_Pasivos.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1502/02_Informe_Analitico_de_la_Deuda_Publica_y_Otros_Pasivos_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1502/03_Informe_Analitico_de_Obligaciones_Diferentes_de_Financiamiento.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1502/03_Informe_Analitico_de_Obligaciones_Diferentes_de_Financiamientos_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1502/04_Balance_Presupuestario.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1502/04_Balance_Presupuestario_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1502/05_Estado_anlitico_de_Ingresos_Detallado-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1502/05_Estado_Analitico_de_los_Ingresos_Detallado_-_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1502/06_a)EAEPED-LDF_Clasificacion_por_Objeto_del_Gasto_(Capitulo_y_Concepto).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1502/06_a)EAPE_Detallado_-_LDF_-_Clasificacion_por_Objeto_del_Gasto_(Capitulo_y_Concepto).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1502/06_b)_EAEPED-LDF_Clasificacion_Administrativa.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1502/06_b)EAEPE_Detallado_-_LDF_-_Clasificacion_Administrativa.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1502/06_c)_EAEPED-LDF_Clasificacion_Funcional_(Finalidad_y_Funcion).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1502/06_c)EAEPE_Detallado_-_LDF_-_Clasificacion_Funcional_(Finalidad_y_Funcion).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1502/06_d)_EAEPED-LDF_Clasificacion_Servicios_Personales_por_Categoria.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1502/06_d)EAEPE_Detallado_-_LDF_-_Clasificacion_de_Servicios_Personales_por_Categoria.pdf'
                            }
                        ]
                    },
                    {
                        'trimestre': 'Segundo Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1487/01_Estado_de_Situacion_Financiera_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1487/ESTADO_DE_SITUACION_FINANCIERA_DETALLADO_-_LDF_AL_31_DE_DICIEMBRE_DE_2019_Y_AL_30_DE_JUNIO_DE_2020_1.PDF'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1487/02_Informe_Analitico_de_la_Deuda_y_Otros_Pasivos_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1487/INFORME_ANALITICO_DE_LA_DEUDA_PUBLICA_Y_OTROS_PASIVOS_-_LDF_DEL_1_DE_ENERO_AL_30_DE_JUNIO_DE_2020_1.PDF'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1487/03_Informe_Analitico_de_Obligaciones_Diferentes_de_Financiamiento_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1487/INF_ANALITICO_OBLIGACIONES_DIFERENTES_DE_FINANCIAMIENTOS_-_LDF_DEL_1_DE_ENERO_AL_30_DE_JUNIO_2020_1.PDF'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1487/04_Balance_Presupuestario.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1487/BALANCE_PRESUPUESTARIO_-_LDF_DEL_1_ENERO_AL_30_DE_JUNIO_DE_2020_1.PDF'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1487/05_Estado_Analitico_de_Ingresos_Detallado-LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1487/ESTADO_ANALITICO_DE_INGRESOS_DETALLADO_-_LDF_DEL_1_DE_ENERO_AL_30_DE_JUNIO_2020_1.PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1487/06_EAEPE_Detallado-LDF_Clasif_Objeto_del_Gasto_(Cap_y_Concepto).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1487/EAEPE_DETALLADO_-_LDF_CLASIFICACION_POR_OBJETO_DEL_GASTO_(CAPITULO__CONCEPTO_1.PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1487/07_EAEPE_-_LDF_Clasificacion_Administrativa.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1487/EAEPE_DETALLADO_-_LDF_CLASIFICACION_ADMINISTRATIVA_DEL_1_DE_ENERO_1.PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1487/08_EAEPE_-_LDF_Clasificacion_Funcional_(Finalidad_y_Funcion).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1487/EAEPE_DETALLADO_-_LDF_CLASIFICACION_FUNCIONAL_(FINALIDAD_Y_FUNCION)_1.PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1487/09_EAEPE_-_LDF_(Clasificacion_de_Servicios_Personales_por_Categoria).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1487/EAEPE_DETALLADO_-_LDF_CLASIFICACION_DE_SERVICIOS_PERSONALES_POR_CATEGORIA_1.PDF'
                            }
                        ]
                    },
                    {
                        'trimestre': 'Primer Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1472/01_Estado_de_Situacion_Financiera_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1472/01_Estado_de_Situacion_Financiera_-_LDF.PDF'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1472/02_Estado_Analitico_de_la_Deuda_y_Otros_Pasivos_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1472/02_Estado_Analitico_de_la_Deuda_y_Otros_Pasivos_-_LDF.PDF'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1472/03_Informe_Analitico_Obligaciones_Diferentes_de_Financiamiento-LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1472/03_Informe_Analitico_Obligaciones_Diferentes_de_Financiamiento-LDF.PDF'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1472/04_Balance_Presupuestario_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1472/04_Balance_Presupuestario_-_LDF.PDF'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1472/05_Estado_Analitico_de_Ingresos_Detallado_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1472/05_Estado_Analitico_de_Ingresos_Detallado_-_LDF.PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1472/06_a)EAEPED_-LDF_Clasif_por_Objeto_del_Gasto_(COG).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1472/06_a)EAEPED_-LDF_Clasifi_por_Objeto_del_Gasto_(COG).PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1472/06_b)_EAEPED_-_LDF_Clasif_Administrativa.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1472/06_b)_EAEPED_-_LDF_Clasif_Administrativa.PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1472/06_c)_EAEPED_-_LDF_Clasif_Funcional_(Finalidad_y_Funcion).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1472/06_c)_EAEPED_-_LDF_Clasif_Funcional_(Finalidad_y_Funcion).PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1472/06_d)_EAEPE_-_LDF_Clasif_de_Servicios_Personales.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1472/06_d)_EAEPE_-_LDF_Clasif_de_Servicios_Personales.PDF'
                            }
                        ]
                    }
                ]
            },
            {
                'ejercicio': 'Ejercicio 2019',
                'data': [
                    {
                        'trimestre': 'Cuarto Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1578/01_Estado_de_Situacion_Financiera_Detallado_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1578/01_Estado_de_Situacion_Financiera_Detallado_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1578/02_Informe_Analitico_de_la_Deuda_y_Otros_Pasivos_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1578/02_Informe_Analitico_de_la_Deuda_y_Otros_Pasivos.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1578/03_Informe_Analitico_de_Obligaciones.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1578/03_Informe_Analitico_de_Obligaciones_Diferentes_de_Financiamiento.pdf'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1578/04_Balance_Presupuestario_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1578/04_Balance_Presupuestario.pdf'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1578/05_Estado_Analitico_del_Ingreso_Detallado_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1578/05_Estado_Analitico_de_Ingresos_Detallado.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1578/06_a)_EAEPE_Detallado_LDF_Clasificacion_por_Objeto_del_Gasto.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1578/06_a)_EAEPED_-_LDF_Clasif_por_Objeto_del_Gto_(Capitulo_y_Concepto).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1578/06_b)_EAEPE_Detallado_LDF_Clasificacion_Administrativa.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1578/06_b)_EAEPED_-_LDF_Clasif_Admva.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1578/06_c)_EAEPE_Detallado_LDF_Clasificacion_Funcional.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1578/06_c)_EAEPED_-_LDF_Clasif_(Finalidad_y_Funcion).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1578/06_d)_EAEPE_Detallado_LDF_Servicios_Personales.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1578/06_d)_EAEPED_-_LDF_Clasif_Servicios_Personales.pdf'
                            }
                        ]
                    },
                    {
                        'trimestre': 'Tercer Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1563/01_Estado_de_Situacion_Financiera_Detallado_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1563/01_Estado_de_Situacion_Financiera_Detallado-LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1563/02_Informe_Analitico_de_la_Deuda_y_Otros_Pasivos_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1563/02_Informe_Analitico_de_la_Deuda_y_Otros_Pasivos-LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1563/03_Informe_Analitico_de_Obligaciones_Diferentes_de_Financiamiento_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1563/03_Informe_Analitico_de_Obligaciones_Deferentes_de_Financiamiento-LDF.pdf'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1563/04_Balance_Presupuestario_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1563/04_Balance_Presupuestario_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1563/05_Estado_Analitico_de_Ingresos_Detallado_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1563/05_Estado_Analitico_de_Ingresos_Detallado-LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1563/06_a)_EAEPE_-_LDF_Clasificacion_por_Objeto_del_Gasto.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1563/06_a)_EAEPED-LDF_Clasificacion_por_Objeto_del_Gasto_(Capitulo_y_Concepto).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1563/06_b)_EAEPE_-_LDF_Clasificacion_Administrativa.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1563/06_b)_EAEPED-LDF_Clasificacion_Administrativa.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1563/06_c)_EAEPE_-_LDF_Clasificacion_Funcional.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1563/06_c)_EAEPED-LDF_Clasificacion_Funcional_(Finalidad_y_Funcion).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1563/06_d)_EAEPE_-_LDF_Servicios_Personales.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1563/06_d)_EAEPED-LDF_(Clasificacion_de_Servicios_Personales_por_Categoria).pdf'
                            }
                        ]
                    },
                    {
                        'trimestre': 'Segundo Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1548/01_Estado_de_Situacion_Financiera_Detallado_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1548/01_Estado_de_Situacion_Financiera_Detallado-LDF.PDF'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1548/02_Informe_Analitico_de_la_Deuda_y_Otros_Pasivos_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1548/02_Informe_Analitico_de_la_Deuda_y_Otros_Pasivos-LDF.PDF'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1548/03_Informe_Analitico_de_Obligaciones.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1548/03_Informe_Analitico_de_Obligaciones_Deferentes_de_Financiamiento-LDF.pdf'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1548/04_Balance_Presupuestario_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1548/04_Balance_Presupuestario-LDF.PDF'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1548/02_Estado_Analitico_del_Ingreso_Detallado_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1548/05_Estado_Analitico_de_Ingresos_Detallado-LDF.PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1548/06_a)_EAEPE_Detallado_LDF_Clasificacion_por_Objeto_del_Gasto.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1548/06_a)_EAEPED-LDF_Clasificacion_por_Objeto_del_Gasto_(Capitulo_y_Concepto).PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1548/06_b)_EAEPE_Detallado_LDF_Clasificacion_Administrativa.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1548/06_b)_EAEPED-LDF_Clasificacion_Administrativa.PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1548/06_c)_EAEPE_Detallado_LDF_Clasificacion_Funcional.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1548/06_c)_EAEPED-LDF_Clasificacion_Funcional_(Finalidad_y_Funcion).PDF'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1548/06_d)_EAEPE_Detallado_LDF_Servicios_Personales.xls',
                                'urlpdf': 'c'
                            }
                        ]
                    },
                    {
                        'trimestre': 'Primer Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1533/01_Estado_de_Situacion_FInanciera_Detallado_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1533/01_Estado_de_Situacion_Financiera_Detallado_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1533/02_Estado_Analitico_de_la_Deuda_y_Otros_Pasivos.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1533/02_Informe_Analitico_de_la_Deuda_Publica_y_Otros_Pasivos_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1533/03_Informe_Analitico_de_Obligaciones_Diferentes_de_Financiamientos.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1533/03_Informe_Analitico_de_Obligaciones_Diferentes_de_Financiamientos_LDF.pdf'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1533/04_Balance_Presupuestario.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1533/04_Balance_Presupuestario_LDF.pdf'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1533/05_Estado_Analitico_del_Ingresos_Detallado_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1533/05_Estado_Analitico_de_Ingresos_Detallado_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1533/06_a)_EAEPE_Detallado_LDF_Clasificacion_por_Objeto_del_Gasto.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1533/06_a)EAEPED_-_LDF_-_Clasificacion_por_Objeto_del_Gasto_(Capitulo_y_Concepto).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1533/06_b)_EAEPE_Detallado_LDF_Clasificacion_Economica.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1533/06_b)EAEPED_-_LDF_-_Clasificacion_Administrativa.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1533/06_c)_EAEPE_Detallado_LDF_Clasificacion_Funcional.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1533/06_c)EAEPED_-_LDF_-_Clasificacion_Funcional_(Finalidad_y_Funcion).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1533/06_d)_EAEP_Detallado_LDF_Clasificacion_de_Servicios_Personales.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1533/06_d)EAEPED_-_LDF_-_Clasificacion_de_Servicios_Personales_por_Categoria.pdf'
                            }
                        ]
                    }
                ]
            },
            {
                'ejercicio': 'Ejercicio 2018',
                'data': [
                    {
                        'trimestre': 'Cuarto Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1639/01_Estado_de_Situacion_Financiera_Detallado_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1639/01_Estado_de_Situacion_Financiera_Detallado_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1639/02_Informe_Analitico_de_la_Deuda_y_Otros_Pasivos.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1639/02_Informe_Analitico_de_la_Deuda_Publica_y_Otros_Pasivos_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1639/03_Informe_Analitico_de_Obligaciones_Diferentes_de_Financiamiento.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1639/03_Informe_Analitico_de_Obligaciones_Diferentes_de_Financimiento_LDF.pdf'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1639/04_Balance_Presupuestario_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1639/04_Balance_Presupuestario_LDF.pdf'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1639/05_Estado_Analitico_del_Ingreso_Detallado_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1639/05_Estado_Analitico_de_Ingresos_Detallados_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1639/06_a)_EAEPE_Detallado_LDF_Clasificacion_por_Objeto_del_Gasto.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1639/06_EAEPED_LDF_Clasificacion_por_Objeto_del_Gasto_(Capitulo_y_Concepto).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1639/06_b)_EAEPE_Detallado_LDF_Clasificacion_Administrativa.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1639/07_EAEPED_LDF_Clasificacion_Administrativa.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1639/06_c)_EAEPE_Detallado_LDF_Clasificacion_Funcional.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1639/08_EAEPED_LDF_Clasificaicon_Funcional_(Finalidad_y_Funcion).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1639/06_d)_EAEPE_Detallado_LDF_Clasificacion_Servicios_Personales.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1639/09_EAEPED_LDF_Clasificacion_de_Servicios_Personales_por_Categoria.pdf'
                            }
                        ]
                    },
                    {
                        'trimestre': 'Tercer Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1624/01_Estado_de_Situacion_Financiera_Detallado_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1624/01_Estado_de_Situacion_Financiera_Detallado_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1624/02_Estado_Analitico_de_la_Deuda_y_Otros_Pasivos_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1624/02_Informe_Analitico_de_la_Deuda_y_otros_Pasivos.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1624/03_Informe_de_Obligaciones.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1624/03_Informe_Analitico_de_Obligaciones_Diferentes_de_Financiamiento_LDF.pdf'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1624/04_Balance_Presupuestario.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1624/04_Balance_Presupuestario_LDF.pdf'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1624/05_Estado_Analitico_de_Ingresos.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1624/05_Estado_Analitico_de_Ingresos_Detallado_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1624/06_a)_EAEPED_-_LDF_Clasificacion_por_Objeto_del_Gasto.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1624/06_EAEPED_LDF_Clasificacion_por_Objeto_del_Gasto_(Capitulo_y_Concepto).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1624/06_b)_EAEPED_-_LDF_Clasificacion_Administrativa.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1624/07_EAEPED_LDF_Clasificacion_Administrativa.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1624/06_c)_EAEPED_-_LDF_Clasificacion_Funcional.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1624/08_EAEPED_LDF_Clasificacion_Funcional_(Finalidad_y_Funcion).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1624/06_d)_EAEPED_-_LDF_Clasificacion_Servicios_Personales.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1624/09_EAEPED_LDF_(Clasificacion_de_Servicios_Personales_por_Categoria).pdf'
                            }
                        ]
                    },
                    {
                        'trimestre': 'Segundo Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1609/01_Estado_de_Situacion_Financiera_Detallado_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1609/01_Estado_de_Situacion_Financiera_Detallado_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1609/02_Informe_Analitico_de_la_Deuda_y_Otros_Pasivos_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1609/02_Informe_Analitico_de_la_Detallado_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1609/03_Informe_Analitico_de_Obligaciones_Diferentes_de_Financiamiento_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1609/03_Informe_Analitico_de_Obligaciones_Diferentes_de_Financiamiento.pdf'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1609/04_Balance_Presupuestario_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1609/04_Balance_Presupuestario.pdf'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1609/05_Estado_Analitico_de_Ingresos_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1609/05_Estado_Analitico_de_Ingresos_Detallado_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1609/06_EAEPED_-_LDF_Clasificacion_por_Objeto_del_Gasto_(Capitulo_y_Concepto).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1609/06_EAEPE_Detallado_LDF_Clasificacion_por_Objeto_del_Gasto.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1609/07_EAEPED_-_LDF_Clasificacion_Administrativa.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1609/07_EAEPE_Detallado_LDF_Clasificacion_Administrativa.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1609/08_EAEPED_-_LDF_Clasificacion_Funcional_(Finalidad_y_Funcion).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1609/08_EAEPE_Detallado_LDF_Clasificacion_Funcional.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1609/09_EAEPED_-_LDF_(Clasificacion_de_Servicios_Personales_por_Categoria).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1609/09_EAEPE_Detallado_LDF_Clasificacion_de_Servicios_Personales.pdf'
                            }
                        ]
                    },
                    {
                        'trimestre': 'Primer Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1594/01_Estado_de_Situacion_Financiera_Detallado_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1594/01_Estado_de_Situacion_Financiera_Detallado_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1594/02_Informe_Analitico_de_la_Deuda_y_Otros_Pasivos_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1594/02_Informe_Analitico_de_la_Deuda_y_Otros_Pasivos_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1594/03_Informe_Analitico_de_Obligaciones_diferentes_de_Financiamientos_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1594/03_Informe_Analitico_de_Obligaciones_LDF.pdf'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1594/04_Balance_presupuestario_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1594/04_Balance_Presupuestario_LDF.pdf'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1594/05_Estado_Analitico_de_Ingresos_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1594/05_Estado_Analitico_de_Ingresos_Detallado_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1594/06_a_EAEPE_Detallado_LDF_COG.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1594/06_a_EAEPE_Detallado_LDF_COG.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1594/06_b_EAEPE_Detallado_Clasificacion_Administrativa.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1594/06_b_EAEPE_Detallado_LDF_Clasificacion_Administrativa.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1594/06_c_EAEPE_DEtallado_LDF_Clasificacion_Funcional.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1594/06_c_EAEPE_Detallado_LDF_Clasficiacion_Funcional.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1594/06_d_EAEPE_Detallado_LDF_Servicios_Personales.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1594/06_d_EAEPE_Detallado_LDF_Servicios_Personales.pdf'
                            }
                        ]
                    }
                ]
            },
            {
                'ejercicio': 'Ejercicio 2017',
                'data': [
                    {
                        'trimestre': 'Cuarto Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1700/01_Estado_de_Situacion_Financiera_Detallado_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1700/01_Estado_de_Situacion_Financiera_Detallado_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1700/02_Informe_Analitico_de_la_Deuda_y_Otros_Pasivos.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1700/02_Informe_Analitico_de_la_Deuda_Publica_y_Otros_Pasivos_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1700/03_Informe_de_Obligaciones_Diferentes_de_Financiamiento.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1700/03_Informe_Analitico_de_Obligaciones_Diferentes_de_Financiamiento_LDF.pdf'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1700/04_Balance_Presupuestario.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1700/04_Balance_Presupuestario_LDF.pdf'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1700/05_Estado_Analitico_de_Ingresos.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1700/05_Estado_Analitico_de_Ingresos_Detallado_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1700/06_a_EAEPE_Detallado_LDF_COG.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1700/06_a_EAEPED_-_Clasificacion_por_Objeto_del_Gasto_(Capitulo_y_Concepto)_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1700/06_b_EAEPE_Detallado_LDF_Administrativa.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1700/06_b_EAEPED_-_Clasificacion_Administrativa_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1700/06_c_EAEPE_Detallado_LDF_Funcional.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1700/06_c_EAEPED_-_Clasificacion_Funcional_(Finalidad_y_Funcion)_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1700/06_d_EAEPE_Detallado_LDF_Servicios_Personales.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1700/06_d_Clasificacion_de_Servicios_Personales_por_Categoria_LDF.pdf'
                            }
                        ]
                    },
                    {
                        'trimestre': 'Tercer Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1685/01_Estado_de_Situacion_Financiera.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1685/01_Estado_de_Situacion_Financiera_Detallado_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1685/02_Informe_Analitico_de_la_Deuda_y_Otros_Pasivos.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1685/02_Informe_Analitico_de_la_Deuda_y_Otros_Pasivos_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1685/03_Informe_Analitico_de_Obligaciones_Diferentes_de_Financiamiento_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1685/03_Informe_Analitico_de_Obligaciones_Deferentes_de_Financiamiento_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1685/04_Balance_Presupuestario.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1685/04_Balance_Presupuestario_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1685/05_Estado_Anali%C2%B4tico_de_Ingresos_Detallado_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1685/05_Estado_Analitico_de_Ingresos_Detallado_-_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1685/06_EAEPED_-_LDF_Clasificacion_por_Objeto_del_Gasto_(Capitulo_y_Concepto).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1685/06_EAEPED_-_LDF_Clasificacion_por_Objeto_del_Gasto_(Capitulo_y_Concepto).xls__[Modo_de_compatibilidad].pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1685/07_EAEPED_-_LDF_Clasificacion_Administrativa.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1685/07_EAEPE_Detallado-LDF_Clasificacion_Administrativa.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1685/08_EAEPED_-_LDF_Clasificacion_Funcional_(Finalidad_y__Funcion).xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1685/08_EAEPE_Detallado-LDF_Clasificacion_Funcional_(Finalidad_y_Funcion).pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1685/09_EAEPED_-_LDF_Clasificacion_de_Servicios_Personales_por_Categoria.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1685/09_EAEPE_Detallado-LDF_(Clasificacion_de_Servicios_Personales_por_Categoria).pdf'
                            }
                        ]
                    },
                    {
                        'trimestre': 'Segundo Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1670/01_Estado_de_Situacion_FInanciera_Detallado_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1670/01_ESTADO_DE_SITUACION_FINANCIERA_DETALLADO-LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1670/02_Informe_de_la_Deuda_y_Otros_Pasivos_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1670/02_INFORME_ANALITICO_DE_LA_DEUDA_PUBLICA_Y_OTRO_PASIVOS-LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1670/03_Informe_de_Obligaciones_Diferentes_de_Financiamiento_-_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1670/03_INFORME_ANALITICO_DE_OBLIGACIONES_DIFERENTES_DE_FINANCIAMIENTOS_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1670/04_Balance_Presupuestario_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1670/04_BALANCE_PRESUPUESTARIO_-LDF.pdf'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1670/05_Estado_Analitico_de_Ingresos_Detallado_-_LDF.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1670/05_ESTADO_ANALITICO_DE_INGRESOS_DETALLADO-LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1670/06_EAEPE_Detallado_-_LDF_Clasificacion_por_Objeto_del_Gasto.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1670/06_CLASIFICACION_POR_OBJETO_DEL_GASTO_(CAPITULO_Y_CONCEPTO)_-_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1670/07_EAEPE_Detallado_-_LDF_Clasificacion_Administrativa.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1670/07_CLASIFICACION_ADMINISTRATIVA_-_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1670/08_EAEPE_Detallado_-_LDF_Clasificacion_Funcional.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1670/08_CLASIFICACION_FUNCIONAL_(FINALIDAD_Y_FUNCION)_-_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1670/09_EAEPE_Detallado_-_LDF_Servicios_Personales.xls',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1670/09_CLASIFICACION_POR_SEVICIOS_PERSONALES_POR_CATEGORIA_-_LDF.pdf'
                            }
                        ]
                    },
                    {
                        'trimestre': 'Primer Trimestre',
                        'detalle': [
                            {
                                'titulo': 'Estado de Situación Financiera Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1655/01_Estado_de_Situacion_Financiera_Detallado_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1655/01_ESTADO_DE_SITUACION_FINANCIERA_DETALLADO-LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de la Deuda y Otros Pasivos',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1655/02_Estado_Analitico_de_la_Deuda_y_Otros_Pasivos_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1655/02_INFORME_ANALITICO_DE_LA_DEUDA_PUBLICA_Y_OTRO_PASIVOS-LDF.pdf'
                            },
                            {
                                'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamiento',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1655/03_Estado_Analitico_de_Obligaciones_Diferentes_de_Financiamiento_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1655/03_INFORME_ANALITICO_DE_OBLIGACIONES_DIFERENTES_DE_FINANCIAMIENTOS_-_LDF.pdf'
                            },
                            {
                                'titulo': 'Balance Presupuestario',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1655/04_Balance_Presupuestario_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1655/04_BALANCE_PRESUPUESTARIO_-LDF.pdf'
                            },
                            {
                                'titulo': 'Estado Analítico de Ingresos Detallado',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1655/05_Estado_Analitico_de_Ingresos_Detallado_LDF.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1655/05_ESTADO_ANALITICO_DE_INGRESOS_DETALLADO-LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Objeto de Gasto',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1655/06_EAEPED_LDF_Clasificacion_por_Objeto_del_Gasto_(Capitulo_y_Concepto).xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1655/06_CLASIFICACION_POR_OBJETO_DEL_GASTO_(CAPITULO_Y_CONCEPTO)_-_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Administrativa',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1655/07_EAEPED_LDF_Clasificacion_Administrativa.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1655/07_CLASIFICACION_ADMINISTRATIVA_-_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación Funcional',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1655/08_EAEPED_LDF_Clasificacion_Funcional(Finalidad_y_Funcion).xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1655/08_CLASIFICACION_FUNCIONAL_(FINALIDAD_Y_FUNCION)_-_LDF.pdf'
                            },
                            {
                                'titulo': 'EAEPE Clasificación por Servicios Personales',
                                'urlxlsx': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1655/09_EAEPED_LDF_Clasificacion_de_Servicios_Personales.xlsx',
                                'urlpdf': 'https://ehacienda.hidalgo.gob.mx/Transparencia/Docs/EdosFin/1655/09_CLASIFICACION_POR_SERVICIOS_PERSONALES_POR_CATEGORIA_-_LDF.pdf'
                            }
                        ]
                    }
                ]
            },
            // {
            //     'ejercicio': 'Ejercicio 2016',
            //     'data': [
            //         {
            //             'trimestre': 'Ejercicio 2016',
            //             'detalle': [
            //                 {
            //                     'titulo': 'Estado de Situación Financiera Detallado',
            //                     'link': 'https://finanzas.hidalgo.gob.mx/transparenciafinanzas/Docs/EdosFin/1716/01_Estado_de_Situacion_Financiera_Detallado_LDF.pdf',
            //                 },
            //                 {
            //                     'titulo': 'Informe Analítico de la Deuda Pública y Otros Pasivos',
            //                     'link': 'https://finanzas.hidalgo.gob.mx/transparenciafinanzas/Docs/EdosFin/1716/02_Informe_analitico_de_la_deuda_publica_y_otros_pasivos_LDF.pdf',
            //                 },
            //                 {
            //                     'titulo': 'Informe Analítico de Obligaciones Diferentes de Financiamientos',
            //                     'link': 'https://finanzas.hidalgo.gob.mx/transparenciafinanzas/Docs/EdosFin/1716/03_Informe_Analitico_de_Obligaciones_Diferentes_de_Financiamiento-LDF.pdf',
            //                 },
            //                 {
            //                     'titulo': 'Balance Presupuestario',
            //                     'link': 'https://finanzas.hidalgo.gob.mx/transparenciafinanzas/Docs/EdosFin/1716/04_Balance_Presupuestario-LDF.pdf',
            //                 },
            //                 {
            //                     'titulo': 'Estado Analítico de Ingresos Detallado',
            //                     'link': 'https://finanzas.hidalgo.gob.mx/transparenciafinanzas/Docs/EdosFin/1716/05_Estado_Analitico_de_Ingresos_Detallado-LDF.pdf',
            //                 },
            //                 {
            //                     'titulo': 'Estado Analítico del Ejercicio del Presupuesto de Egresos Detallado por Objeto del Gasto (Capítulo y concepto)',
            //                     'link': 'https://finanzas.hidalgo.gob.mx/transparenciafinanzas/Docs/EdosFin/1716/06_EAEPED-LDF_por_Objeto_del_Gasto.pdf',
            //                 },
            //                 {
            //                     'titulo': 'Estado Analítico del Ejercicio del Presupuesto de Egresos Detallado Clasificación Administrativa',
            //                     'link': 'https://finanzas.hidalgo.gob.mx/transparenciafinanzas/Docs/EdosFin/1716/07_EAEPED-LDF_Clasificacion_Administrativa.pdf',
            //                 },
            //                 {
            //                     'titulo': 'Estado Analítico del Ejercicio del Presupuesto de Egresos Detallado Clasificación Funcional',
            //                     'link': 'https://finanzas.hidalgo.gob.mx/transparenciafinanzas/Docs/EdosFin/1716/08_EAEPED-LDF_Clasificacion_Funcional.pdf',
            //                 },
            //                 {
            //                     'titulo': 'Estado Analítico del Ejercicio del Presupuesto de Egresos Detallado Clasificación de Servicios Personales',
            //                     'link': 'https://finanzas.hidalgo.gob.mx/transparenciafinanzas/Docs/EdosFin/1716/09_EAEPED-LDF_Clasificacion_de_Servicios_Personales.pdf',
            //                 }
            //             ]
            //         }
            //     ]
            // }
        ]
    }
]