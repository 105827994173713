export const ejercicioPrepEgre16Object = {
    'nombre': 'Ejercicio del Presupuesto 2016',
        'tipo': '2',
        'servidor': '1',
        'seccion': [
            {
                'titulo': 'Normatividad para el Ejercicio del Presupuesto 2016',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Decreto que autoriza el Presupuesto de Egresos del Estado de Hidalgo para el Ejercicio 2016',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2016/Presupuesto%20de%20Egresos%20para%20el%20Estado%20de%20Hidalgo%202016.pdf'
                    },
                    {
                        'nombreLink':'Manual de Normas y Lineamientos para el Ejercicio del Gasto de Operación de los Programas y Proyectos 2016',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2016/Manual%20Gasto%20Operacion%20Def%202016.pdf'
                    },
                    {
                        'nombreLink':'Manual de Normas y Lineamientos para el Ejercicio de los Recursos del Programa de Inversión 2016',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2016/Manual%20Gasto%20Inversion%20Def%202016.pdf'
                    },
                    {
                        'nombreLink':'Anexos y cuadros del Presupuesto',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2016/Anexos%202016.pdf'
                    },
                    {
                        'nombreLink':'Circular No. 2 Lineamientos Derivados del Cierre de Administración',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2016/Circular%20No%202.pdf'
                    },
                    {
                        'nombreLink':'Circular No. 3 No expedición del Formato 37-A Constancia de Pagos y Retenciones de IVA, ISR y IEPS',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2016/Circular%20No%203.pdf'
                    },
                    {
                        'nombreLink':'Circular No. 5 Cambio de Validador',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2016/Circular%20No%205%20Cambio%20de%20Validador.pdf'
                    },
                    {
                        'nombreLink':'Circular No. 11 Cierre de operaciones presupuestales y contables del ejercicio 2016',
                        'url': '../files/servicios/pdf/EjerciciodelPresupuesto/2016/Circular%20No%2011%20SFyA.pdf'
                    }
                ]
            },
            {
                'titulo': 'Catálogos de la Clave Presupuestal 2016',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'',
                        'url': '#',
                        'urldos': [
                            {
                                'descripcion': 'Clasificación por Ramo',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2016/Catalogos%20de%20la%20Clave%20Presupuestal/1%20Clasificacion%20por%20Ramo.pdf'
                            },
                            {
                                'descripcion': 'Catálogo de Entes',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2016/Catalogos%20de%20la%20Clave%20Presupuestal/2%20Catalogo%20Entes.pdf'
                            },
                            {
                                'descripcion': 'Clasificación por Rubro de Ingresos',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2016/Catalogos%20de%20la%20Clave%20Presupuestal/3%20Clasificacion%20por%20Rubro%20de%20Ingresos.pdf'
                            },
                            {
                                'descripcion': 'Clasificación por Tipo de Gasto',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2016/Catalogos%20de%20la%20Clave%20Presupuestal/4%20Clasificacion%20por%20Tipo%20de%20Gasto.pdf'
                            },
                            {
                                'descripcion': 'Clasificación por Objeto de Gasto',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2016/Catalogos%20de%20la%20Clave%20Presupuestal/5%20Clasificacion%20por%20Objeto%20del%20Gasto.pdf'
                            },
                            {
                                'descripcion': 'Clasificación Funcional',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2016/Catalogos%20de%20la%20Clave%20Presupuestal/6%20Clasificacion%20Funcional.pdf'
                            },
                            {
                                'descripcion': 'Catálogo de Ejes',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2016/Catalogos%20de%20la%20Clave%20Presupuestal/7%20Catalogo%20de%20Ejes.pdf'
                            },
                            {
                                'descripcion': 'Clasificación por Sector',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2016/Catalogos%20de%20la%20Clave%20Presupuestal/8%20Clasificacion%20por%20Sector.pdf'
                            },
                            {
                                'descripcion': 'Clasificación Programática',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2016/Catalogos%20de%20la%20Clave%20Presupuestal/9%20Clasificacion%20Programatica.pdf'
                            },
                            {
                                'descripcion': 'Clasificación por Beneficiario',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2016/Catalogos%20de%20la%20Clave%20Presupuestal/10%20Clasificacion%20por%20Beneficiario.pdf'
                            },
                            {
                                'descripcion': 'Clasificación por Espacio Geográfico',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2016/Catalogos%20de%20la%20Clave%20Presupuestal/11%20Clasificacion%20por%20Espacio%20Geografico.pdf'
                            },
                            {
                                'descripcion': 'Catálogo Misión y Propósito Institucional',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2016/Catalogos%20de%20la%20Clave%20Presupuestal/12%20Catalogo%20Mision%20Proposito%20Institucional.pdf'
                            },
                            {
                                'descripcion': 'Catálogo Problema',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2016/Catalogos%20de%20la%20Clave%20Presupuestal/13%20Catalogo%20Problema.pdf'
                            },
                            {
                                'descripcion': 'Catálogo Dimensión',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2016/Catalogos%20de%20la%20Clave%20Presupuestal/14%20Catalogo%20Dimension.pdf'
                            },
                            {
                                'descripcion': 'Catálogo Indicadores',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2016/Catalogos%20de%20la%20Clave%20Presupuestal/15%20Catalogo%20Indicadores.pdf'
                            },
                            {
                                'descripcion': 'Catálogo Meta del Indicador',
                                'link': '../files/servicios/pdf/EjerciciodelPresupuesto/2016/Catalogos%20de%20la%20Clave%20Presupuestal/16%20Catalogo%20meta%20del%20Indicador.pdf'
                            }
                        ]
                    }
                ]
            }
        ]
    }