export const estadosFinancierosObject = {
    // 'titulo': 'Estados Financieros',
    // 'nombre': 'Estados Financieros',
    'tipo': '5',
    'articulo': [
        {
            'titulo': '',
            'descripcion': '',
            'link': 'https://ehacienda.hidalgo.gob.mx/Transparencia/EdosFin'
        }
    ]
}