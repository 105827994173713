export const transparenciaFinporTemaObject = {
    // 'titulo': 'Transparencia Financiera por Tema',
    // 'nombre': 'Transparencia Financiera por Tema',
    'tipo': '5',
    'articulo': [
        {
            'titulo': '',
            'descripcion': '',
            'link': 'https://ehacienda.hidalgo.gob.mx/Transparencia/PorTema'
        }
    ]
}