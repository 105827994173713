export const circularesSubseEgreObject = {
    'nombre': 'Circulares Subsecretaría de Egresos',
        'tipo': '2',
        'servidor': '1',
        'seccion': [
            {
                'titulo': '2024',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Circular No. 010',
                        'url': '../files/servicios/pdf/CircularesSubsecretariadeEgresos/2024/Circular%20010.pdf'
                    },
                    {
                        'nombreLink':'Circular No. 005',
                        'url': '../files/servicios/pdf/CircularesSubsecretariadeEgresos/2024/Circular%20005.pdf'
                    }
                ]
            },
            {
                'titulo': '2023',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Circular No. 032',
                        'url': '../files/servicios/pdf/CircularesSubsecretariadeEgresos/2023/Circular%20No%2032.pdf'
                    },
                    {
                        'nombreLink':'Circular No. 0015',
                        'url': '../files/servicios/pdf/CircularesSubsecretariadeEgresos/2023/Circular%20No.%20015.PDF'
                    },
                    {
                        'nombreLink':'Circular No. 0002',
                        'url': '../files/servicios/pdf/CircularesSubsecretariadeEgresos/2023/Circular%20No%200002.pdf',
                        'urldos': [
                            {
                                'descripcion': 'Anexo I',
                                'link': '../files/servicios/pdf/CircularesSubsecretariadeEgresos/2023/Anexo%20circular%20No%200002%20030423.pdf'
                            },
                            {
                                'descripcion': 'Manual de Usuario',
                                'link': '../files/servicios/pdf/CircularesSubsecretariadeEgresos/2023/Manual%20de%20usuario%20circular%20No%200002.pdf'
                            }
                        ]
                    }
                ]
            },
            {
                'titulo': '2022',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Circular No. 0015',
                        'url': '../files/servicios/pdf/CircularesSubsecretariadeEgresos/2022/Circular%20No%200015.pdf'
                    },
                    {
                        'nombreLink':'Circular No. 0009',
                        'url': '../files/servicios/pdf/CircularesSubsecretariadeEgresos/2022/Circular%20No%200009.pdf'
                    },
                    {
                        'nombreLink':'Circular No. 0006',
                        'url': '../files/servicios/pdf/CircularesSubsecretariadeEgresos/2022/Circular%20No%200006.pdf'
                    },
                    {
                        'nombreLink':'Circular No. 0002',
                        'url': '../files/servicios/pdf/CircularesSubsecretariadeEgresos/2022/Circular%20No%200002.pdf'
                    },
                    {
                        'nombreLink':'Circular No. 0001',
                        'url': '../files/servicios/pdf/CircularesSubsecretariadeEgresos/2022/Circular%20No%200001.pdf'
                    }
                ]
            },
            {
                'titulo': '2021',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Circular No. 0010',
                        'url': '../files/servicios/pdf/CircularesSubsecretariadeEgresos/2021/Circular%200010.pdf'
                    },
                    {
                        'nombreLink':'Circular No. 005',
                        'url': '../files/servicios/pdf/CircularesSubsecretariadeEgresos/2021/Circular%200005.pdf'
                    },
                    {
                        'nombreLink':'Circular No. 004',
                        'url': '../files/servicios/pdf/CircularesSubsecretariadeEgresos/2021/Circular%200004.pdf'
                    }
                ]
            },
            {
                'titulo': '2020',
                'subtitulo': '',
                'docs': [
                    {
                        'nombreLink':'Circular No. 003',
                        'url': '../files/servicios/pdf/CircularesSubsecretariadeEgresos/2020/Circular%20003.pdf'
                    },
                    {
                        'nombreLink':'Circular No. 002',
                        'url': '../files/servicios/pdf/CircularesSubsecretariadeEgresos/2020/Circular%20002.pdf'
                    }
                ]
            }
        ]
    }