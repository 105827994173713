export const paqueteHacendario24Object = {
    'nombre': 'Paquete Hacendario 2024',
    'tipo': '2',
    'servidor': '1',
    'seccion': [
        {
            'titulo': '',
            'subtitulo': '',
            'docs': [
                {
                    'nombreLink':'Criterios para la integración del Paquete Hacendario',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2024/Criterios%20para%20la%20Integracion%20del%20Paquete%20Hacendario%202024%20V2.pdf'
                },
                {
                    'nombreLink':'Catálogos de la Clave Presupuestaria 2024',
                    'urltres': 'ServiciosInternos/CatalogosdelaClavePresupuestaria2024'
                },
                {
                    'nombreLink':'Manual de formulación presupuestal para el ejercicio fiscal 2024',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2024/Propuesta%20Manual%20de%20Formulacion%20Presupuestal%20para%20el%20Ejercicio%20Fiscal%202024%20V4%20publicacion.pdf'
                },
                {
                    'nombreLink':'Iniciativa de Miscelánea Fiscal',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2024/Iniciativa%20de%20Miscelanea%20Fiscal%202024.pdf'
                },
                {
                    'nombreLink':'Iniciativa de Ley de Ingresos',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2024/Iniciativa%20de%20Ley%20de%20Ingresos%202024.pdfservicios/pdf/PaqueteHacendario/2024/Iniciativa%20de%20Ley%20de%20Ingresos%202024.pdf'
                },
                {
                    'nombreLink':'Proyecto de Decreto de Presupuesto de Egresos',
                    'url': '../files/servicios/pdf/PaqueteHacendario/2024/Proyecto%20de%20Decreto%20de%20Presupuesto%20de%20Egresos%202024.pdf'
                },
                {
                    'nombreLink':'Anexos Proyecto de Presupuesto de Egresos',
                    'urltres': 'ServiciosInternos/AnexosdelProyectodeDecretodePresupuestodeEgresos2024'
                }
            ]
        }
    ]
}