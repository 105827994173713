export const catalogosClavePrep2021Object = {
    'nombre': 'Catálogos de la Clave Presupuestaria 2021',
    'tipo': '2',
    'servidor': '1',
    'seccion': [
        {
            'titulo': '',
            'subtitulo': '',
            'docs': [
                {
                    'nombreLink':'Clasificación por Ramo',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2021/CatalogosdelaClavePresupuestaria/1%20Clasificacion%20por%20Ramo.pdf'
                },
                {
                    'nombreLink':'Catálogo de Entes',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2021/CatalogosdelaClavePresupuestaria/2%20Catalogo%20de%20Entes.pdf'
                },
                {
                    'nombreLink':'Catálogo por Rubro de Ingreso',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2021/CatalogosdelaClavePresupuestaria/3%20Clasificador%20por%20Rubro%20de%20Ingresos.pdf'
                },
                {
                    'nombreLink':'Clasificación por Tipo de Gasto',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2021/CatalogosdelaClavePresupuestaria/4%20Clasificacion%20por%20Tipo%20de%20Gasto.pdf'
                },
                {
                    'nombreLink':'Clasificación por Objeto del Gasto',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2021/CatalogosdelaClavePresupuestaria/5%20Clasificacion%20por%20Objeto%20de%20Gasto%202021.pdf'
                },
                {
                    'nombreLink':'Clasificación Funcional del Gasto',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2021/CatalogosdelaClavePresupuestaria/6%20Clasificacion%20Funcional%20del%20Gasto.pdf'
                },
                {
                    'nombreLink':'Objetivos de Desarrollo Sostenible (ODS)',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2021/CatalogosdelaClavePresupuestaria/7%20Objetivos%20de%20Desarrollo%20Sostenible%20(ODS).pdf'
                },
                {
                    'nombreLink':'Catálogo de Ejes (Actualización PED 2016-2022)',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2021/CatalogosdelaClavePresupuestaria/8%20Catalogo%20de%20Ejes%20(Actualizacion%20PED%202016-2022).pdf'
                },
                {
                    'nombreLink':'Clasificación Programática',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2021/CatalogosdelaClavePresupuestaria/9%20Clasificacion%20Programatica.pdf'
                },
                {
                    'nombreLink':'Catálogo de Beneficiarios',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2021/CatalogosdelaClavePresupuestaria/10%20Catalogo%20de%20Beneficiarios.pdf'
                },
                {
                    'nombreLink':'Clasificación por Espacio Geográfico',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2021/CatalogosdelaClavePresupuestaria/11%20Clasificacion%20por%20Espacio%20Geografico.pdf'
                },
                {
                    'nombreLink':'Políticas Sectoriales',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2021/CatalogosdelaClavePresupuestaria/12%20Politicas%20Sectoriales.pdf'
                },
                {
                    'nombreLink':'Indicadores Estratégicos',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2021/CatalogosdelaClavePresupuestaria/13%20Indicadores%20Estrategicos.pdf'
                },
                {
                    'nombreLink':'Objetivos Estratégicos Transversales',
                    'url': '../../../files/servicios/pdf/PaqueteHacendario/2021/CatalogosdelaClavePresupuestaria/14%20Objetivos%20Estrategicos%20Transversales.pdf'
                }
            ]
        }
    ]
}