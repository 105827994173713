export const cuentaHaciendaPubObject = {
    'nombre': 'Cuenta de la Hacienda Pública Estatal (integrada y consolidada - LGCG)',
    'tipo': '2',
    'servidor': '3',
    'seccion': [
        {
            'titulo': '',
            'subtitulo': '',
            'docs': [
                {
                    'nombreLink':'Cuenta de la Hacienda Pública Estatal 2023 (Integrada y Consolidada-LGCG)',
                    'url': 'https://ehacienda.hidalgo.gob.mx/WEBCPUBLICA2023/',
                    'data': {
                        'urlxlsx': '',
                        'urlpdf': ''
                    }
                },
                {
                    'nombreLink':'Cuenta de la Hacienda Pública Estatal 2022 (Integrada y Consolidada-LGCG)',
                    'url': 'https://ehacienda.hidalgo.gob.mx/WEBCPUBLICA2022/',
                    'data': {
                        'urlxlsx': '',
                        'urlpdf': ''
                    }
                },
                {
                    'nombreLink':'Cuenta de la Hacienda Pública Estatal 2021 (Integrada y Consolidada-LGCG)',
                    'url': 'https://ehacienda.hidalgo.gob.mx/WEBCPUBLICA2021/',
                    'data': {
                        'urlxlsx': '',
                        'urlpdf': ''
                    }
                },
                {
                    'nombreLink':'Cuenta de la Hacienda Pública Estatal 2020 (Integrada y Consolidada-LGCG)',
                    'url': 'https://ehacienda.hidalgo.gob.mx/WEBCPUBLICA2020/',
                    'data': {
                        'urlxlsx': '',
                        'urlpdf': ''
                    }
                },
                {
                    'nombreLink':'Cuenta de la Hacienda Pública Estatal 2019 (Integrada y Consolidada-LGCG)',
                    'url': 'https://ehacienda.hidalgo.gob.mx/WebCPublica2019/',
                    'data': {
                        'urlxlsx': '',
                        'urlpdf': ''
                    }
                },
                {
                    'nombreLink':'Cuenta de la Hacienda Pública Estatal 2018 (Integrada y Consolidada-LGCG)',
                    'url': 'https://ehacienda.hidalgo.gob.mx/WebCPublica2018/',
                    'data': {
                        'urlxlsx': '',
                        'urlpdf': ''
                    }
                },
                {
                    'nombreLink':'Cuenta de la Hacienda Pública Estatal 2017 (Integrada y Consolidada-LGCG)',
                    'url': 'https://ehacienda.hidalgo.gob.mx/WebCPublica2017/',
                    'data': {
                        'urlxlsx': '',
                        'urlpdf': ''
                    }
                },
                {
                    'nombreLink':'Cuenta de la Hacienda Pública Estatal 2016 (Integrada y Consolidada-LGCG)',
                    'url': 'https://ehacienda.hidalgo.gob.mx/WEBCPUBLICA2016/',
                    'data': {
                        'urlxlsx': '',
                        'urlpdf': ''
                    }
                },
                {
                    'nombreLink':'Cuenta de la Hacienda Pública Estatal 2015 (Integrada y Consolidada-LGCG)',
                    'url': 'https://ehacienda.hidalgo.gob.mx/WEBCPUBLICA2015/',
                    'data': {
                        'urlxlsx': '',
                        'urlpdf': ''
                    }
                },
                {
                    'nombreLink':'Cuenta de la Hacienda Pública Estatal 2014 (Integrada y Consolidada-LGCG)',
                    'url': 'https://ehacienda.hidalgo.gob.mx/WEBCPUBLICA/',
                    'data': {
                        'urlxlsx': '',
                        'urlpdf': ''
                    }
                }
            ]
        }
    ]
}