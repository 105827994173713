import React, { useEffect } from 'react';
import { servidorArray } from '../../json/servidor';
import './accordion.css';

const AccordionServicios = ({object}) => {

var servers = JSON.parse(JSON.stringify (servidorArray));

const changeServer = (n) => {
    console.log(n);
    switch(n){
        //ic
        case '1':
          return servers.servidoruno;
        case '2':
          return servers.servidordos;
        case '3':
          return servers.servidortres;
        default:
            return []
        }
    }

useEffect(() => {
    window.scrollTo(0, 0);
}, []);
    
  return (       
    <>   
    {
        object &&
        <div className='container'>
            <div className="row">
                <div className="accordion accordion-flush px-0" id="accordionExample">
                    {
                        object.seccion.map((sec,c) => (
                            <div className="accordion-item my-3" key={c}>
                                {
                                    sec.docs.map((doc,i) => (
                                        <>
                                            <h2 className="accordion-header border-bottom" key={i}>
                                                <button className="accordion-button py-4" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse_" + i + "_" + c} aria-expanded="true" aria-controls={"#collapse_" + i + "_" + c}>
                                                    {doc.titulo}
                                                </button>
                                            </h2>
                                            <div id={"collapse_" + i + "_" + c} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                {
                                                    doc.data.map((datas,a) => (
                                                        <div className="accordion-body mb-3" key={a}>
                                                            <p className='ms-3 pt-1'>{datas.trimestre}</p>
                                                            <>
                                                            {
                                                                datas.detalle.map((detail,l) => (
                                                                    detail?.url ?
                                                                    <div className='py-1' key={l}>
                                                                        {/* <a className='ps-4' href={servers.servidoruno + detail.url} target='_blank'>{detail.nombre}</a> */}
                                                                        <a className='ps-4' href={detail.url} target='_blank'>{detail.nombre}</a>
                                                                            {
                                                                            detail.urldos ?
                                                                            <ul>
                                                                                {
                                                                                    detail.urldos.map((udos, z) => (
                                                                                        <li className='list-unstyled bg-transparent py-1 ps-3' key={z}>
                                                                                            {/* <a href={changeServer(object.servidor) + udos.link} target='_blank'>{udos.descripcion}</a> */}
                                                                                            <a href={udos.link} target='_blank'>{udos.descripcion}</a>
                                                                                        </li>
                                                                                    ))
                                                                                }
                                                                                </ul>
                                                                                : null
                                                                            }
                                                                    </div> :
                                                                    <div class="accordion accordion-flush px-0" id="accordionExample">
                                                                        <div class="accordion-item py-2 ps-1" >
                                                                            <h2 class="accordion-header">
                                                                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={"#collapsen2_" + a + "_" + l} aria-expanded="true" aria-controls={"#collapsen2_" + a + "_" + l}>
                                                                                {detail.nombre}
                                                                                </button>
                                                                            </h2>
                                                                            {
                                                                                detail.urltres.map((deta, a) => (
                                                                                    <div id={"#collapsen2_" + a + "_" + l} class="accordion-collapse collapse" data-bs-parent="#accordionExample" key={a}>
                                                                                        {/* <div class="accordion-body ps-5"><a href={changeServer(object.servidor) + deta.link} target='_blank'>{deta.nombre}</a></div> */}
                                                                                        <div class="accordion-body ps-5"><a href={deta.link} target='_blank'>{deta.nombre}</a></div>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                            </>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </>
                                    ))
                                }
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    }
    </> 
  )
}

export default AccordionServicios