export const informacionCircularesDireccTesoObject = 
    {
        'nombre': 'Circulares Dirección General de Egresos y/o Dirección General de Tesorería',
        'tipo': '1',
        'servidor': '1',
        'seccion': [
            {   
                'id': '0',
                'docs': [
                    {
                        'titulo': 'CFDI de pago',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Circular Num. 045',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/CFDIdePago/Circular%20Num%20045.pdf'
                                    },
                                    {
                                        'nombre': 'Circular Num. 070',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/CFDIdePago/Circular%20Num%20070.pdf'
                                    },
                                    {
                                        'nombre': 'Oficio Circular SFP-SE-02-0238-2018_Municipios',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/CFDIdePago/Oficio%20Circular%20SFP-SE-02-0238-2018_Municipios.pdf'
                                    },
                                    {
                                        'nombre': 'Oficio Circular SFP-SE-02-0254-2019_Municipios',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/CFDIdePago/Oficio%20Circular%20SFP-SE-02-0254-2019_Municipios.pdf'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Informes trimestrales',
                        'data': [
                            {
                                'trimestre': '2024',
                                'detalle': [
                                    {
                                        'nombre': 'Circular Núm. 023 - Tercer Trimestre 2024',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2024/Tercer Trimestre/Circular Num 023 Tercer Trimestre 2024.pdf'
                                    },
                                    {
                                        'nombre': 'Circular Núm. 015 - Segundo Trimestre 2024 e Informe Definitivo 2023',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2024/Segundo Trimestre/Circular Num  015 - Segundo Trimestre 2024 e Informe Definitivo 2023.pdf'
                                    },
                                    {
                                        'nombre': 'Circular Núm. 009 - Primer Trimestre 2024',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2024/Primer%20Trimestre/Circular%20Num%20009%20Primer%20Trimestre%202024.pdf'
                                    }
                                ]
                            },
                            {
                                'trimestre': '2023',
                                'detalle': [
                                    {
                                        'nombre': 'Circular Núm. 039 - Cuarto Trimestre 2023',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2023/Cuarto%20Trimestre/Circular%20Num%20039%20Cuarto%20Trimestre%202023.pdf'
                                    },
                                    {
                                        'nombre': 'Circular Núm. 026 - Tercer Trimestre 2023',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2023/Tercer%20Trimestre/Circular%20Num%20026_Tercer%20Trimestre%202023.pdf'
                                    },
                                    {
                                        'nombre': 'Circular Num. 0019 - Segundo Trimestre 2023 e Informe Definitivo 2022',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2023/Segundo%20Trimestre/Circular%20Num%200019.pdf'
                                    },
                                    {
                                        'nombre': 'Circular Num. 0011 - Primer Trimestre 2023',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2023/Primer%20Trimestre/Circular%20Num%200011%20Primer%20Trimestre%202023.pdf'
                                    }
                                ]
                            },
                            {
                                'trimestre': '2022',
                                'detalle': [
                                    {
                                        'nombre': 'Cuarto Trimestre',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Cuarto%20Trimestre/Circular%20Num%200016_Cuarto%20Trimestre%202022.pdf'
                                    },
                                    {
                                        'nombre': 'Tercer Trimestre',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Tercer%20Trimestre/Circular%200010_Tercer%20Trimestre%202022.pdf'
                                    },
                                    {
                                        'nombre': 'Segundo Trimestre',
                                        'url': '',
                                        'urltres': [
                                            {
                                                'nombre': 'Circular Municipios_Reporte Trimestral a la S.H.C.P. sobre el Ejercicio y Destino del Gasto de los Recursos Federales Transferidos (SRFT)',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Segundo%20Trimestre/Circular%20Municipios_Reporte%20Trimestral%20a%20la%20S.H.C.P.%20sobre%20el%20Ejercicio%20y%20Destino%20del%20Gasto%20de%20los%20Recursos%20Federales%20Transferidos%20(SRFT).pdf'
                                            },
                                            {
                                                'nombre': 'Apertura del Segundo Trimestre 2022 e Informe Definitivo 2021 en el SRFT Proceso de Captura',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Segundo%20Trimestre/Correo%20electronico%20-%20Apertura%20del%20Segundo%20Trimestre%202022%20e%20Informe%20Definitivo%202021%20en%20el%20SRFT_Proceso%20de%20Captura.pdf'
                                            },
                                            {
                                                'nombre': 'Aviso Segundo Trimestre 2022 e Informe Definitivo 2021 del SRFT_ICI',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Segundo%20Trimestre/Correo%20electronico%20-%20Aviso_Segundo%20Trimestre%202022%20e%20Informe%20Definitivo%202021%20del%20SRFT_ICI.pdf'
                                            },
                                            {
                                                'nombre': 'Requerimientos para los Reportes del Segundo Trimestre 2022 e Informe Definitivo 2021 en el SRFT',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Segundo%20Trimestre/Correo%20electronico%20-%20Requerimientos%20para%20los%20Reportes%20del%20Segundo%20Trimestre%202022%20e%20Informe%20Definitivo%202021%20en%20el%20SRFT.pdf'
                                            },
                                            {
                                                'nombre': 'Directorio de Enlaces de la SFP',
                                                'link': '../files/servicios/xls/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Segundo%20Trimestre/Directorio%20de%20Enlaces%20de%20la%20SFP.xlsx'
                                            }
                                        ]
                                    },
                                    {
                                        'nombre': 'Primer Trimestre',
                                        'url': '',
                                        'urltres': [
                                            {
                                                'nombre': 'SFP-SE-02-0184-2022_Municipio de Acatlán',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0184-2022_Municipio%20de%20Acatlan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0185-2022_Municipio de Acaxochitlán',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0185-2022_Municipio%20de%20Acaxochitlan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0186-2022_Municipio de Actopan',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0186-2022_Municipio%20de%20Actopan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0187-2022_Municipio de Agua Blanca de Iturbide',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0187-2022_Municipio%20de%20Agua%20Blanca%20de%20Iturbide.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0188-2022_Municipio de Ajacuba',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0188-2022_Municipio%20de%20Ajacuba.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0189-2022_Municipio de Alfajayucan',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0189-2022_Municipio%20de%20Alfajayucan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0190-2022_Municipio de Almoloya',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0190-2022_Municipio%20de%20Almoloya.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0191-2022_Municipio de Apan',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0191-2022_Municipio%20de%20Apan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0192-2022_Municipio de El Arenal',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0192-2022_Municipio%20de%20El%20Arenal.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0193-2022_Municipio de Atitalaquia',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0193-2022_Municipio%20de%20Atitalaquia.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0194-2022_Municipio de Atlapexco',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0194-2022_Municipio%20de%20Atlapexco.pdf4'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0195-2022_Municipio de Atotonilco el Grande',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0195-2022_Municipio%20de%20Atotonilco%20el%20Grande.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0196-2022_Municipio de Atotonilco de Tula',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0196-2022_Municipio%20de%20Atotonilco%20de%20Tula.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0197-2022_Municipio de Calnali',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0197-2022_Municipio%20de%20Calnali.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0198-2022_Municipio de Cardonal',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0198-2022_Municipio%20de%20Cardonal.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0199-2022_Municipio de Cuautepec de Hinojosa',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0199-2022_Municipio%20de%20Cuautepec%20de%20Hinojosa.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0200-2022_Municipio de Chapantongo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0200-2022_Municipio%20de%20Chapantongo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0201-2022_Municipio de Chapulhuacán',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0201-2022_Municipio%20de%20Chapulhuacan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0202-2022_Municipio de Chilcuautla',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0202-2022_Municipio%20de%20Chilcuautla.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0203-2022_Municipio de Eloxochitlán',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0203-2022_Municipio%20de%20Eloxochitlan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0204-2022_Municipio de Emiliano Zapata',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0204-2022_Municipio%20de%20Emiliano%20Zapata.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0205-2022_Municipio de Epazoyucan',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0205-2022_Municipio%20de%20Epazoyucan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0206-2022_Municipio de Francisco I. Madero',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0206-2022_Municipio%20de%20Francisco%20I.%20Madero.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0207-2022_Municipio de Huasca de Ocampo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0207-2022_Municipio%20de%20Huasca%20de%20Ocampo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0208-2022_Municipio de Huautla',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0208-2022_Municipio%20de%20Huautla.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0209-2022_Municipio de Huazalingo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0209-2022_Municipio%20de%20Huazalingo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0210-2022_Municipio de Huehuetla',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0210-2022_Municipio%20de%20Huehuetla.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0211-2022_Municipio de Huejutla de Reyes',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0211-2022_Municipio%20de%20Huejutla%20de%20Reyes.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0212-2022_Municipio de Huichapan',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0212-2022_Municipio%20de%20Huichapan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0213-2022_Municipio de Ixmiquilpan',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0213-2022_Municipio%20de%20Ixmiquilpan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0214-2022_Municipio de Jacala de Ledezma',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0214-2022_Municipio%20de%20Jacala%20de%20Ledezma.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0215-2022_Municipio de Jaltocán',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0215-2022_Municipio%20de%20Jaltocan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0216-2022_Municipio de Juárez Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0216-2022_Municipio%20de%20Juarez%20Hidalgo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0217-2022_Municipio de Lolotla',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0217-2022_Municipio%20de%20Lolotla.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0218-2022_Municipio de Metepec',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0218-2022_Municipio%20de%20Metepec.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0219-2022_Municipio de San Agustín Metzquititlán',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0219-2022_Municipio%20de%20San%20Agustin%20Metzquititlan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0220-2022_Municipio de Metztitlán',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0220-2022_Municipio%20de%20Metztitlan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0221-2022_Municipio de Mineral del Chico',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0221-2022_Municipio%20de%20Mineral%20del%20Chico.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0222-2022_Municipio de Mineral del Monte',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0222-2022_Municipio%20de%20Mineral%20del%20Monte.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0223-2022_Municipio de La Misión',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0223-2022_Municipio%20de%20La%20Mision.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0224-2022_Municipio de Mixquiahuala de Juárez',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0224-2022_Municipio%20de%20Mixquiahuala%20de%20Juarez.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0225-2022_Municipio de Molango de Escamilla',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0225-2022_Municipio%20de%20Molango%20de%20Escamilla.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0226-2022_Municipio de Nicolás Flores',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0226-2022_Municipio%20de%20Nicolas%20Flores.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0227-2022_Municipio de Nopala de Villagrán',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0227-2022_Municipio%20de%20Nopala%20de%20Villagran.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0228-2022_Municipio de Omitlán de Juárez',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0228-2022_Municipio%20de%20Omitlan%20de%20Juarez.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0229-2022_Municipio de San Felipe Orizatlán',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0229-2022_Municipio%20de%20San%20Felipe%20Orizatlan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0230-2022_Municipio de Pacula',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0230-2022_Municipio%20de%20Pacula.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0231-2022_Municipio de Pachuca de Soto',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0231-2022_Municipio%20de%20Pachuca%20de%20Soto.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0232-2022_Municipio de Pisaflores',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0232-2022_Municipio%20de%20Pisaflores.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0233-2022_Municipio de Progreso de Obregón',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0233-2022_Municipio%20de%20Progreso%20de%20Obregon.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0234-2022_Municipio de Mineral de la Reforma',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0234-2022_Municipio%20de%20Mineral%20de%20la%20Reforma.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0235-2022_Municipio de San Agustín Tlaxiaca',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0235-2022_Municipio%20de%20San%20Agusti%CC%81n%20Tlaxiaca.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0236-2022_Municipio de San Bartolo Tutotepec',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0236-2022_Municipio%20de%20San%20Bartolo%20Tutotepec.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0237-2022_Municipio de San Salvador',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0237-2022_Municipio%20de%20San%20Salvador.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0238-2022_Municipio de Santiago de Anaya',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0238-2022_Municipio%20de%20Santiago%20de%20Anaya.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0239-2022_Municipio de Santiago Tulantepec de Lugo Guerrero',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0239-2022_Municipio%20de%20Santiago%20Tulantepec%20de%20Lugo%20Guerrero.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0240-2022_Municipio de Singuilucan',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0240-2022_Municipio%20de%20Singuilucan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0241-2022_Municipio de Tasquillo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0241-2022_Municipio%20de%20Tasquillo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0242-2022_Municipio de Tecozautla',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0242-2022_Municipio%20de%20Tecozautla.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0243-2022_Municipio de Tenango de Doria',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0243-2022_Municipio%20de%20Tenango%20de%20Doria.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0244-2022_Municipio de Tepeapulco',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0244-2022_Municipio%20de%20Tepeapulco.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0245-2022_Municipio de Tepehuacán de Guerrero',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0245-2022_Municipio%20de%20Tepehuacan%20de%20Guerrero.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0246-2022_Municipio de Tepeji del Río de Ocampo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0246-2022_Municipio%20de%20Tepeji%20del%20Rio%20de%20Ocampo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0247-2022_Municipio de Tepetitlán',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0247-2022_Municipio%20de%20Tepetitlan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0248-2022_Municipio de Tetepango',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0248-2022_Municipio%20de%20Tetepango.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0249-2022_Municipio de Villa de Tezontepec',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0249-2022_Municipio%20de%20Villa%20de%20Tezontepec.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0250-2022_Municipio de Tezontepec de Aldama',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0250-2022_Municipio%20de%20Tezontepec%20de%20Aldama.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0251-2022_Municipio de Tianguistengo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0251-2022_Municipio%20de%20Tianguistengo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0252-2022_Municipio de Tizayuca',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0252-2022_Municipio%20de%20Tizayuca.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0253-2022_Municipio de Tlahuelilpan',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0253-2022_Municipio%20de%20Tlahuelilpan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0254-2022_Municipio de Tlahuiltepa',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0254-2022_Municipio%20de%20Tlahuiltepa.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0255-2022_Municipio de Tlanalapa',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0255-2022_Municipio%20de%20Tlanalapa.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0256-2022_Municipio de Tlanchinol',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0256-2022_Municipio%20de%20Tlanchinol.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0257-2022_Municipio de Tlaxcoapan',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0257-2022_Municipio%20de%20Tlaxcoapan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0258-2022_Municipio de Tolcayuca',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0258-2022_Municipio%20de%20Tolcayuca.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0259-2022_Municipio de Tula de Allende',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0259-2022_Municipio%20de%20Tula%20de%20Allende.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0260-2022_Municipio de Tulancingo de Bravo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0260-2022_Municipio%20de%20Tulancingo%20de%20Bravo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0261-2022_Municipio de Xochiatipan',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0261-2022_Municipio%20de%20Xochiatipan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0262-2022_Municipio de Xochicoatlán',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0262-2022_Municipio%20de%20Xochicoatlan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0263-2022_Municipio de Yahualica',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0263-2022_Municipio%20de%20Yahualica.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0264-2022_Municipio de Zacualtipán de Ángeles',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0264-2022_Municipio%20de%20Zacualtipan%20de%20Angeles.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0265-2022_Municipio de Zapotlán de Juárez',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0265-2022_Municipio%20de%20Zapotla%CC%81n%20de%20Juarez.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0266-2022_Municipio de Zempoala',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0266-2022_Municipio%20de%20Zempoala.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0267-2022_Municipio de Zimapán',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0267-2022_Municipio%20de%20Zimapan.pdf'
                                            },
                                            {
                                                'nombre': '',
                                                'link': ''
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0152-2022_Auditoria Superior del Estado de Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0152-2022_Auditoria%20Superior%20del%20Estado%20de%20Hidalgo.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0153-2022_Tribunal Superior de Justicia',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0153-2022_Tribunal%20Superior%20de%20Justicia.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0154-2022_Secretaría de Gobierno',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0154-2022_Secretaria%20de%20Gobierno.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0155-2022_Instituto Catastral del Estado de Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0155-2022_Instituto%20Catastral%20del%20Estado%20de%20Hidalgo.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0156-2022_Coordinación de Planeación Financiera',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0156-2022_Coordinacion%20de%20Planeacion%20Financiera.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0157-2022_Secretaría de Desarrollo Social',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0157-2022_Secretaria%20de%20Desarrollo%20Social.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0158-2022_Secretaría de Obras Públicas y Ordenamiento Territorial',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0158-2022_Secretaria%20de%20Obras%20Publicas%20y%20Ordenamiento%20Territorial.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0159-2022_Secretaría de Medio Ambiente y Recursos Naturales',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0159-2022_Secretaria%20de%20Medio%20Ambiente%20y%20Recursos%20Naturales.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0160-2022_Secretaría de Desarrollo Económico',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0160-2022_Secretaria%20de%20Desarrollo%20Economico.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0161-2022_Secretaría de Desarrollo Agropecuario',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0161-2022_Secretaria%20de%20Desarrollo%20Agropecuario.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0162-2022_Secretaría de Turismo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0162-2022_Secretaria%20de%20Turismo.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0163-2022_Secretaría de Educación Pública e Instituto Hidalguense de Educación',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0163-2022_Secretaria%20de%20Educacion%20Publica%20e%20Instituto%20Hidalguense%20de%20Educacion.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0164-2022_Secretaría de Salud y Servicios de Salud de Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0164-2022_Secretaria%20de%20Salud%20y%20Servicios%20de%20Salud%20de%20Hidalgo.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0165-2022_Secretaría de Seguridad Pública',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0165-2022_Secretaria%20de%20Seguridad%20Publica.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0166-2022_Secretaría del Trabajo y Previsión Social',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0166-2022_Secretaria%20del%20Trabajo%20y%20Prevision%20Social.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0167-2022_Secretaría de Movilidad y Transporte',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0167-2022_Secretaria%20de%20Movilidad%20y%20Transporte.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0168-2022_Secretaría de Cultura',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0168-2022_Secretaria%20de%20Cultura.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0169-2022_Unidad de Planeación y Prospectiva',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0169-2022_Unidad%20de%20Planeacion%20y%20Prospectiva.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0170-2022_Procuraduría General de Justicia',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0170-2022_Procuraduria%20General%20de%20Justicia.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0171-2022_Sistema para el Desarrollo Integral de la Familia',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0171-2022_Sistema%20para%20el%20Desarrollo%20Integral%20de%20la%20Familia.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0172-2022_Comisión Estatal para el Desarrollo Sostenible de los Pueblos Indígenas',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0172-2022_Comision%20Estatal%20para%20el%20Desarrollo%20Sostenible%20de%20los%20Pueblos%20Indigenas.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0173-2022_Consejo de Ciencia, Tecnología e Innovación de Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0173-2022_Consejo%20de%20Ciencia%20Tecnologia%20e%20Innovacion%20de%20Hidalgo.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0174-2022_Universidad Autónoma del Estado de Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2022/Primer%20Trimestre/SFP-SE-02-0174-2022_Universidad%20Autonoma%20del%20Estado%20de%20Hidalgo.PDF'
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                'trimestre': '2021',
                                'detalle': [
                                    {
                                        'nombre': 'Cuarto Trimestre',
                                        'url': '',
                                        'urltres': [
                                            {
                                                'nombre': 'SFP-SE-02-0152-2022_Auditoria Superior del Estado de Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Cuarto%20Trimestre/SFP-SE-02-0759-2021_Auditoria%20Superior%20del%20Estado%20de%20Hidalgo.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0153-2022_Tribunal Superior de Justicia',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Cuarto%20Trimestre/SFP-SE-02-0760-2021_Tribunal%20Superior%20de%20Justicia.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0154-2022_Secretaría de Gobierno',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Cuarto%20Trimestre/SFP-SE-02-0761-2021_Secretaria%20de%20Gobierno.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0155-2022_Instituto Catastral del Estado de Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Cuarto%20Trimestre/SFP-SE-02-0762-2021_Instituto%20Catastral%20del%20Estado%20de%20Hidalgo.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0156-2022_Coordinación de Planeación Financiera',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Cuarto%20Trimestre/SFP-SE-02-0763-2021_Coordinacion%20de%20Planeacio%CC%81n%20Financiera.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0157-2022_Secretaría de Desarrollo Social',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Cuarto%20Trimestre/SFP-SE-02-0764-2021_Secretaria%20de%20Desarrollo%20Social.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0158-2022_Secretaría de Obras Públicas y Ordenamiento Territorial',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Cuarto%20Trimestre/SFP-SE-02-0765-2021_Secretaria%20de%20Obras%20Publicas%20y%20Ordenamiento%20Territorial.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0159-2022_Secretaría de Medio Ambiente y Recursos Naturales',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Cuarto%20Trimestre/SFP-SE-02-0766-2021_Secretaria%20de%20Medio%20Ambiente%20y%20Recursos%20Naturales.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0160-2022_Secretaría de Desarrollo Económico',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Cuarto%20Trimestre/SFP-SE-02-0767-2021_Secretaria%20de%20Desarrollo%20Economico.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0161-2022_Secretaría de Desarrollo Agropecuario',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Cuarto%20Trimestre/SFP-SE-02-0768-2021_Secretaria%20de%20Desarrollo%20Agropecuario.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0162-2022_Secretaría de Turismo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Cuarto%20Trimestre/SFP-SE-02-0769-2021_Secretaria%20de%20Turismo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0163-2022_Secretaría de Educación Pública e Instituto Hidalguense de Educación',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Cuarto%20Trimestre/SFP-SE-02-0770-2021_Secretaria%20de%20Educacion%20Publica%20e%20Instituto%20Hidalguense%20de%20Educacion.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0164-2022_Secretaría de Salud y Servicios de Salud de Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Cuarto%20Trimestre/SFP-SE-02-0771-2021_Secretaria%20de%20Salud%20y%20Servicios%20de%20Salud%20de%20Hidalgo.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0165-2022_Secretaría de Seguridad Pública',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Cuarto%20Trimestre/SFP-SE-02-0772-2021_Secretaria%20de%20Seguridad%20Publica.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0166-2022_Secretaría del Trabajo y Previsión Social',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Cuarto%20Trimestre/SFP-SE-02-0773-2021_Secretaria%20del%20Trabajo%20y%20Prevision%20Social.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0167-2022_Secretaría de Movilidad y Transporte',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Cuarto%20Trimestre/SFP-SE-02-0774-2021_Secretaria%20de%20Movilidad%20y%20Transporte.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0168-2022_Secretaría de Cultura',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Cuarto%20Trimestre/SFP-SE-02-0775-2021_Secretaria%20de%20Cultura.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0169-2022_Unidad de Planeación y Prospectiva',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Cuarto%20Trimestre/SFP-SE-02-0776-2021_Unidad%20de%20Planeacion%20y%20Prospectiva.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0170-2022_Procuraduría General de Justicia',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Cuarto%20Trimestre/SFP-SE-02-0777-2021_Procuraduria%20General%20de%20Justicia.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0171-2022_Sistema para el Desarrollo Integral de la Familia',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Cuarto%20Trimestre/SFP-SE-02-0778-2021_Sistema%20para%20el%20Desarrollo%20Integral%20de%20la%20Familia.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0172-2022_Comisión Estatal para el Desarrollo Sostenible de los Pueblos Indígenas',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Cuarto%20Trimestre/SFP-SE-02-0779-2021_Comision%20Estatal%20para%20el%20Desarrollo%20Sostenible%20de%20los%20Pueblos%20Indigenas.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0173-2022_Consejo de Ciencia, Tecnología e Innovación de Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Cuarto%20Trimestre/SFP-SE-02-0780-2021_Consejo%20de%20Ciencia%20Tecnologia%20e%20Innovacion%20de%20Hidalgo.PDF'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0174-2022_Universidad Autónoma del Estado de Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Cuarto%20Trimestre/SFP-SE-02-0781-2021_Universidad%20Autonoma%20del%20Estado%20de%20Hidalgo.PDF'
                                            }
                                        ]
                                    },
                                    {
                                        'nombre': 'Tercer Trimestre',
                                        'url': '',
                                        'urltres': [
                                            {
                                                'nombre': 'SFP-SE-02-0152-2022_Auditoria Superior del Estado de Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Tercer%20Trimestre/SFP-SE-02-0614-2021_Auditoria%20Superior%20del%20Estado%20de%20Hidalgo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0153-2022_Tribunal Superior de Justicia',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Tercer%20Trimestre/SFP-SE-02-0615-2021_Tribunal%20Superior%20de%20Justicia.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0154-2022_Secretaría de Gobierno',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Tercer%20Trimestre/SFP-SE-02-0616_2021_Secretaria%20de%20Gobierno.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0155-2022_Instituto Catastral del Estado de Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Tercer%20Trimestre/SFP-SE-02-0617_2021_Instituto%20Catastral%20del%20Estado%20de%20Hidalgo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0156-2022_Coordinación de Planeación Financiera',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Tercer%20Trimestre/SFP-SE-02-0618_2021_Coordinacion%20de%20Planeacion%20Financiera.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0157-2022_Secretaría de Desarrollo Social',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Tercer%20Trimestre/SFP-SE-02-0619_2021_Secretaria%20de%20Desarrollo%20Social.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0158-2022_Secretaría de Obras Públicas y Ordenamiento Territorial',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Tercer%20Trimestre/SFP-SE-02-0620_2021_Secretaria%20de%20Obras%20Publicas%20y%20Ordenamiento%20Territorial.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0159-2022_Secretaría de Medio Ambiente y Recursos Naturales',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Tercer%20Trimestre/SFP-SE-02-0621_2021_Secretaria%20de%20Medio%20Ambiente%20y%20Recursos%20Naturales.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0160-2022_Secretaría de Desarrollo Económico',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Tercer%20Trimestre/SFP-SE-02-0622_2021_Secretaria%20de%20Desarrollo%20Economico.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0161-2022_Secretaría de Desarrollo Agropecuario',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Tercer%20Trimestre/SFP-SE-02-0623_2021_Secretaria%20de%20Desarrollo%20Agropecuario.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0162-2022_Secretaría de Turismo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Tercer%20Trimestre/SFP-SE-02-0624_2021_Secretaria%20de%20Turismo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0163-2022_Secretaría de Educación Pública e Instituto Hidalguense de Educación',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Tercer%20Trimestre/SFP-SE-02-0625_2021_Secretaria%20de%20Educacion%20Publica%20e%20Instituto%20Hidalguense%20de%20Educacion.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0164-2022_Secretaría de Salud y Servicios de Salud de Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Tercer%20Trimestre/SFP-SE-02-0626_2021_Secretaria%20de%20Salud%20y%20Servicios%20de%20Salud%20de%20Hidalgo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0165-2022_Secretaría de Seguridad Pública',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Tercer%20Trimestre/SFP-SE-02-0627_2021_Secretaria%20de%20Seguridad%20Publica.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0166-2022_Secretaría del Trabajo y Previsión Social',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Tercer%20Trimestre/SFP-SE-02-0628_2021_Secretaria%20del%20Trabajo%20y%20Prevision%20Social.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0167-2022_Secretaría de Movilidad y Transporte',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Tercer%20Trimestre/SFP-SE-02-0629_2021_Secretaria%20de%20Movilidad%20y%20Transporte.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0168-2022_Secretaría de Cultura',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Tercer%20Trimestre/SFP-SE-02-0630_2021_Secretaria%20de%20Cultura.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0169-2022_Unidad de Planeación y Prospectiva',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Tercer%20Trimestre/SFP-SE-02-0631_2021_Unidad%20de%20Planeacion%20y%20Prospectiva.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0170-2022_Procuraduría General de Justicia',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Tercer%20Trimestre/SFP-SE-02-0632_2021_Procuraduria%20General%20de%20Justicia.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0171-2022_Sistema para el Desarrollo Integral de la Familia',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Tercer%20Trimestre/SFP-SE-02-0633_2021_Sistema%20para%20el%20Desarrollo%20Integral%20de%20la%20Familia.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0172-2022_Comisión Estatal para el Desarrollo Sostenible de los Pueblos Indígenas',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Tercer%20Trimestre/SFP-SE-02-0634_2021_Comision%20Estatal%20para%20el%20Desarrollo%20Sostenible%20de%20los%20Pueblos%20Indigenas.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0173-2022_Consejo de Ciencia, Tecnología e Innovación de Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Tercer%20Trimestre/SFP-SE-02-0635_2021_Consejo%20de%20Ciencia%20Tecnologia%20e%20Innovacion%20de%20Hidalgo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0174-2022_Universidad Autónoma del Estado de Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Tercer%20Trimestre/SFP-SE-02-0636_2021_Universidad%20Autonoma%20del%20Estado%20de%20Hidalgo.pdf'
                                            }
                                        ]
                                    },
                                    {
                                        'nombre': 'Segundo Trimestre',
                                        'url': '',
                                        'urltres': [
                                            {
                                                'nombre': 'SFP-SE-02-0152-2022_Auditoria Superior del Estado de Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Segundo%20Trimestre/Organismos/SFP-0560-2021_Auditoria%20Superior%20del%20Estado%20de%20Hidalgo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0153-2022_Tribunal Superior de Justicia',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Segundo%20Trimestre/Organismos/SFP-0561-2021_Tribunal%20Superior%20de%20Justicia.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0154-2022_Secretaría de Gobierno',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Segundo%20Trimestre/Organismos/SFP-0562-2021_Secretaria%20de%20Gobierno.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0155-2022_Instituto Catastral del Estado de Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Segundo%20Trimestre/Organismos/SFP-0563-2021_Instituto%20Catastral%20del%20Estado%20de%20Hidalgo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0156-2022_Coordinación de Planeación Financiera',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Segundo%20Trimestre/Organismos/SFP-0564-2021_Coordinacion%20de%20Planeacion%20Financiera.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0157-2022_Secretaría de Desarrollo Social',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Segundo%20Trimestre/Organismos/SFP-0565-2021_Secretaria%20de%20Desarrollo%20Social.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0158-2022_Secretaría de Obras Públicas y Ordenamiento Territorial',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Segundo%20Trimestre/Organismos/SFP-0566-2021_Secretaria%20de%20Obras%20Publicas%20y%20Ordenamiento%20Territorial.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0159-2022_Secretaría de Medio Ambiente y Recursos Naturales',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Segundo%20Trimestre/Organismos/SFP-0567-2021_Secretaria%20de%20Medio%20Ambiente%20y%20Recursos%20Naturales.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0160-2022_Secretaría de Desarrollo Económico',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Segundo%20Trimestre/Organismos/SFP-0568-2021_Secretaria%20de%20Desarrollo%20Economico.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0161-2022_Secretaría de Desarrollo Agropecuario',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Segundo%20Trimestre/Organismos/SFP-0569-2021_Secretaria%20de%20Desarrollo%20Agropecuario.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0162-2022_Secretaría de Turismo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Segundo%20Trimestre/Organismos/SFP-0570-2021_Secretaria%20de%20Turismo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0163-2022_Secretaría de Educación Pública e Instituto Hidalguense de Educación',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Segundo%20Trimestre/Organismos/SFP-0571-2021_Secretaria%20de%20Educacion%20Publica%20e%20Instituto%20Hidalguense%20de%20Educacion.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0164-2022_Secretaría de Salud y Servicios de Salud de Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Segundo%20Trimestre/Organismos/SFP-0572-2021_Secretaria%20de%20Salud%20y%20Servicios%20de%20Salud%20de%20Hidalgo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0165-2022_Secretaría de Seguridad Pública',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Segundo%20Trimestre/Organismos/SFP-0573-2021_Secretaria%20de%20Seguridad%20Publica.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0166-2022_Secretaría del Trabajo y Previsión Social',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Segundo%20Trimestre/Organismos/SFP-0574-2021_Secretaria%20del%20Trabajo%20y%20Prevision%20Social.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0167-2022_Secretaría de Movilidad y Transporte',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Segundo%20Trimestre/Organismos/SFP-0575-2021_Secretaria%20de%20Movilidad%20y%20Transporte.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0168-2022_Secretaría de Cultura',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Segundo%20Trimestre/Organismos/SFP-0576-2021_Secretaria%20de%20Cultura.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0169-2022_Unidad de Planeación y Prospectiva',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Segundo%20Trimestre/Organismos/SFP-0577-2021_Unidad%20de%20Planeacion%20y%20Prospectiva.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0170-2022_Procuraduría General de Justicia',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Segundo%20Trimestre/Organismos/SFP-0578-2021_Procuraduria%20General%20de%20Justicia.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0171-2022_Sistema para el Desarrollo Integral de la Familia',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Segundo%20Trimestre/Organismos/SFP-0579-2021_Sistema%20para%20el%20Desarrollo%20Integral%20de%20la%20Familia.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0172-2022_Comisión Estatal para el Desarrollo Sostenible de los Pueblos Indígenas',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Segundo%20Trimestre/Organismos/SFP-0580-2021_Comision%20Estatal%20para%20el%20Desarrollo%20Sostenible%20de%20los%20Pueblos%20Indigenas.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0173-2022_Consejo de Ciencia, Tecnología e Innovación de Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Segundo%20Trimestre/Organismos/SFP-0581-2021_Consejo%20de%20Ciencia%20Tecnologia%20e%20Innovacion%20de%20Hidalgo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0174-2022_Universidad Autónoma del Estado de Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Segundo%20Trimestre/Organismos/SFP-0582-2021_Universidad%20Autonoma%20del%20Estado%20de%20Hidalgo.pdf'
                                            }
                                        ]
                                    },
                                    {
                                        'nombre': 'Primer Trimestre',
                                        'url': '',
                                        'urltres': [
                                            {
                                                'nombre': 'SFP-SE-02-0184-2022_Municipio de Acatlán',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0214-2021_Municipio%20de%20Acatlan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0185-2022_Municipio de Acaxochitlán',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0215-2021_Municipio%20de%20Acaxochitlan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0186-2022_Municipio de Actopan',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0216-2021_Municipio%20de%20Actopan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0187-2022_Municipio de Agua Blanca de Iturbide',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0217-2021_Municipio%20de%20Agua%20Blanca%20de%20Iturbide.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0188-2022_Municipio de Ajacuba',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0218-2021_Municipio%20de%20Ajacuba.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0189-2022_Municipio de Alfajayucan',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0219-2021_Municipio%20de%20Alfajayucan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0190-2022_Municipio de Almoloya',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0220-2021_Municipio%20de%20Almoloya.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0191-2022_Municipio de Apan',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0221-2021_Municipio%20de%20Apan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0192-2022_Municipio de El Arenal',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0222-2021_Municipio%20de%20El%20Arenal.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0193-2022_Municipio de Atitalaquia',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0223-2021_Municipio%20de%20Atitalaquia.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0194-2022_Municipio de Atlapexco',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0224-2021_Municipio%20de%20Atlapexco.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0195-2022_Municipio de Atotonilco el Grande',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0225-2021_Municipio%20de%20Atotonilco%20el%20Grande.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0196-2022_Municipio de Atotonilco de Tula',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0226-2021_Municipio%20de%20Atotonilco%20de%20Tula.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0197-2022_Municipio de Calnali',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0227-2021_Municipio%20de%20Calnali.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0198-2022_Municipio de Cardonal',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0228-2021_Municipio%20de%20Cardonal.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0199-2022_Municipio de Cuautepec de Hinojosa',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0229-2021_Municipio%20de%20Cuautepec%20de%20Hinojosa.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0200-2022_Municipio de Chapantongo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0230-2021_Municipio%20de%20Chapantongo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0201-2022_Municipio de Chapulhuacán',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0231-2021_Municipio%20de%20Chapulhuacan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0202-2022_Municipio de Chilcuautla',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0232-2021_Municipio%20de%20Chilcuautla.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0203-2022_Municipio de Eloxochitlán',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0233-2021_Municipio%20de%20Eloxochitlan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0204-2022_Municipio de Emiliano Zapata',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0234-2021_Municipio%20de%20Emiliano%20Zapata.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0205-2022_Municipio de Epazoyucan',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0235-2021_Municipio%20de%20Epazoyucan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0206-2022_Municipio de Francisco I. Madero',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0236-2021_Municipio%20de%20Francisco%20I%20Madero.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0207-2022_Municipio de Huasca de Ocampo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0237-2021_Municipio%20de%20Huasca%20de%20Ocampo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0208-2022_Municipio de Huautla',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0238-2021_Municipio%20de%20Huautla.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0209-2022_Municipio de Huazalingo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0239-2021_Municipio%20de%20Huazalingo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0210-2022_Municipio de Huehuetla',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0240-2021_Municipio%20de%20Huehuetla.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0211-2022_Municipio de Huejutla de Reyes',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0241-2021_Municipio%20de%20Huejutla%20de%20Reyes.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0212-2022_Municipio de Huichapan',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0242-2021_Municipio%20de%20Huichapan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0213-2022_Municipio de Ixmiquilpan',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0243-2021_Municipio%20de%20Ixmiquilpan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0214-2022_Municipio de Jacala de Ledezma',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0244-2021_Municipio%20de%20Jacala%20de%20Ledezma.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0215-2022_Municipio de Jaltocán',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0245-2021_Municipio%20de%20Jaltocan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0216-2022_Municipio de Juárez Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0246-2021_Municipio%20de%20Juarez%20Hidalgo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0217-2022_Municipio de Lolotla',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0247-2021_Municipio%20de%20Lolotla.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0218-2022_Municipio de Metepec',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0248-2021_Municipio%20de%20Metepec.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0219-2022_Municipio de San Agustín Metzquititlán',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0249-2021_Municipio%20de%20San%20Agustin%20Metzquititlan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0220-2022_Municipio de Metztitlán',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0250-2021_Municipio%20de%20Metztitlan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0221-2022_Municipio de Mineral del Chico',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0251-2021_Municipio%20de%20Mineral%20del%20Chico.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0222-2022_Municipio de Mineral del Monte',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0252-2021_Municipio%20de%20Mineral%20del%20Monte.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0223-2022_Municipio de La Misión',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0253-2021_Municipio%20de%20La%20Mision.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0224-2022_Municipio de Mixquiahuala de Juárez',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0254-2021_Municipio%20de%20Mixquiahuala%20de%20Juarez.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0225-2022_Municipio de Molango de Escamilla',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0255-2021_Municipio%20de%20Molango%20de%20Escamilla.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0226-2022_Municipio de Nicolás Flores',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0256-2021_Municipio%20de%20Nicolas%20Flores.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0227-2022_Municipio de Nopala de Villagrán',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0257-2021_Municipio%20de%20Nopala%20de%20Villagran.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0228-2022_Municipio de Omitlán de Juárez',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0258-2021_Municipio%20de%20Omitlan%20de%20Juarez.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0229-2022_Municipio de San Felipe Orizatlán',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0259-2021_Municipio%20de%20San%20Felipe%20Orizatlan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0230-2022_Municipio de Pacula',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0260-2021_Municipio%20de%20Pacula.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0231-2022_Municipio de Pachuca de Soto',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0261-2021_Municipio%20de%20Pachuca%20de%20Soto.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0232-2022_Municipio de Pisaflores',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0262-2021_Municipio%20de%20Pisaflores.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0233-2022_Municipio de Progreso de Obregón',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0263-2021_Municipio%20de%20Progreso%20de%20Obregon.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0234-2022_Municipio de Mineral de la Reforma',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0264-2021_Municipio%20de%20Mineral%20de%20la%20Reforma.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0235-2022_Municipio de San Agustín Tlaxiaca',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0265-2021_Municipio%20de%20San%20Agustin%20Tlaxiaca.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0236-2022_Municipio de San Bartolo Tutotepec',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0266-2021_Municipio%20de%20San%20Bartolo%20Tutotepec.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0237-2022_Municipio de San Salvador',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0267-2021_Municipio%20de%20San%20Salvador.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0238-2022_Municipio de Santiago de Anaya',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0268-2021_Municipio%20de%20Santiago%20de%20Anaya.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0239-2022_Municipio de Santiago Tulantepec de Lugo Guerrero',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0269-2021_Municipio%20de%20Santiago%20Tulantepec%20de%20Lugo%20Guerrero.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0240-2022_Municipio de Singuilucan',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0270-2021_Municipio%20de%20Singuilucan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0241-2022_Municipio de Tasquillo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0271-2021_Municipio%20de%20Tasquillo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0242-2022_Municipio de Tecozautla',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0272-2021_Municipio%20de%20Tecozautla.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0243-2022_Municipio de Tenango de Doria',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0273-2021_Municipio%20de%20Tenango%20de%20Doria.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0244-2022_Municipio de Tepeapulco',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0274-2021_Municipio%20de%20Tepeapulco.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0245-2022_Municipio de Tepehuacán de Guerrero',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0275-2021_Municipio%20de%20Tepehuacan%20de%20Guerrero.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0246-2022_Municipio de Tepeji del Río de Ocampo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0276-2021_Municipio%20de%20Tepeji%20del%20Rio%20de%20Ocampo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0247-2022_Municipio de Tepetitlán',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0277-2021_Municipio%20de%20Tepetitlan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0248-2022_Municipio de Tetepango',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0278-2021_Municipio%20de%20Tetepango.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0249-2022_Municipio de Villa de Tezontepec',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0279-2021_Municipio%20de%20Villa%20de%20Tezontepec.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0250-2022_Municipio de Tezontepec de Aldama',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0280-2021_Municipio%20de%20Tezontepec%20de%20Aldama.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0251-2022_Municipio de Tianguistengo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0281-2021_Municipio%20de%20Tianguistengo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0252-2022_Municipio de Tizayuca',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0282-2021_Municipio%20de%20Tizayuca.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0253-2022_Municipio de Tlahuelilpan',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0283-2021_Municipio%20de%20Tlahuelilpan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0254-2022_Municipio de Tlahuiltepa',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0284-2021_Municipio%20de%20Tlahuiltepa.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0255-2022_Municipio de Tlanalapa',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0285-2021_Municipio%20de%20Tlanalapa.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0256-2022_Municipio de Tlanchinol',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0286-2021_Municipio%20de%20Tlanchinol.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0257-2022_Municipio de Tlaxcoapan',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0287-2021_Municipio%20de%20Tlaxcoapan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0258-2022_Municipio de Tolcayuca',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0288-2021_Municipio%20de%20Tolcayuca.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0259-2022_Municipio de Tula de Allende',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0289-2021_Municipio%20de%20Tula%20de%20Allende.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0260-2022_Municipio de Tulancingo de Bravo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0290-2021_Municipio%20de%20Tulancingo%20de%20Bravo.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0261-2022_Municipio de Xochiatipan',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0291-2021_Municipio%20de%20Xochiatipan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0262-2022_Municipio de Xochicoatlán',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0292-2021_Municipio%20de%20Xochicoatlan.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0263-2022_Municipio de Yahualica',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0293-2021_Municipio%20de%20Yahualica.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0264-2022_Municipio de Zacualtipán de Ángeles',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0294-2021_Municipio%20de%20Zacualtipan%20de%20Angeles.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0265-2022_Municipio de Zapotlán de Juárez',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0295-2021_Municipio%20de%20Zapotlan%20de%20Juarez.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0266-2022_Municipio de Zempoala',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0296-2021_Municipio%20de%20Zempoala.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0267-2022_Municipio de Zimapán',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/SFP-0297-2021_Municipio%20de%20Zimapan.pdf'
                                            },
                                            {
                                                'nombre': '',
                                                'link': ''
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0152-2022_Auditoria Superior del Estado de Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/Organismos/SFP-0298-2021_AUDITORIA%20SUPERIOR%20DEL%20ESTADO%20DE%20HIDALGO.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0153-2022_Tribunal Superior de Justicia',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/Organismos/SFP-0299-2021_TRIBUNAL%20SUPERIOR%20DE%20JUSTICIA.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0154-2022_Secretaría de Gobierno',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/Organismos/SFP-0300-2021_SECRETARIA%20DE%20GOBIERNO.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0155-2022_Instituto Catastral del Estado de Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/Organismos/SFP-0301-2021_INSTITUTO%20CATASTRAL%20DEL%20ESTADO%20DE%20HIDALGO.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0156-2022_Coordinación de Planeación Financiera',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/Organismos/SFP-0302-2021_COORDINACION%20DE%20PLANEACION%20FINANCIERA.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0157-2022_Secretaría de Desarrollo Social',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/Organismos/SFP-0303-2021_SECRETARIA%20DE%20DESARROLLO%20SOCIAL.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0158-2022_Secretaría de Obras Públicas y Ordenamiento Territorial',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/Organismos/SFP-0304-2021_SECRETARIA%20DE%20OBRAS%20PUBLICAS%20Y%20ORDENAMIENTO%20TERRITORIAL.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0159-2022_Secretaría de Medio Ambiente y Recursos Naturales',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/Organismos/SFP-0305-2021_SECRETARIA%20DE%20MEDIO%20AMBIENTE%20Y%20RECURSOS%20NATURALES.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0160-2022_Secretaría de Desarrollo Económico',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/Organismos/SFP-0306-2021_SECRETARIA%20DE%20DESARROLLO%20ECONOMICO.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0161-2022_Secretaría de Desarrollo Agropecuario',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/Organismos/SFP-0307-2021_SECRETARIA%20DE%20DESARROLLO%20AGROPECUARIO.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0162-2022_Secretaría de Turismo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/Organismos/SFP-0308-2021_SECRETARIA%20DE%20TURISMO.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0163-2022_Secretaría de Educación Pública e Instituto Hidalguense de Educación',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/Organismos/SFP-0309-2021_SECRETARIA%20DE%20EDUCACION%20PUBLICA%20E%20INSTITUTO%20HIDALGUENSE%20DE%20EDUCACION.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0164-2022_Secretaría de Salud y Servicios de Salud de Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/Organismos/SFP-0310-2021_SECRETARIA%20DE%20SALUD%20Y%20SERVICIOS%20DE%20SALUD%20DE%20HIDALGO.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0165-2022_Secretaría de Seguridad Pública',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/Organismos/SFP-0311-2021_SECRETARIA%20DE%20SEGURIDAD%20PUBLICA.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0166-2022_Secretaría del Trabajo y Previsión Social',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/Organismos/SFP-0312-2021_SECRETARIA%20DEL%20TRABAJO%20Y%20PREVISION%20SOCIAL.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0167-2022_Secretaría de Movilidad y Transporte',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/Organismos/SFP-0313-2021_SECRETARIA%20DE%20MOVILIDAD%20Y%20TRANSPORTE.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0168-2022_Secretaría de Cultura',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/Organismos/SFP-0314-2021_SECRETARIA%20DE%20CULTURA.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0169-2022_Unidad de Planeación y Prospectiva',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/Organismos/SFP-0315-2021_UNIDAD%20DE%20PLANEACION%20Y%20PROSPECTIVA.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0170-2022_Procuraduría General de Justicia',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/Organismos/SFP-0316-2021_PROCURADURIA%20GENERAL%20DE%20JUSTICIA.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0171-2022_Sistema para el Desarrollo Integral de la Familia',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/Organismos/SFP-0317-2021_SISTEMA%20PARA%20EL%20DESARROLLO%20INTEGRAL%20DE%20LA%20FAMILIA.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0172-2022_Comisión Estatal para el Desarrollo Sostenible de los Pueblos Indígenas',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/Organismos/SFP-0318-2021_COMISION%20ESTATAL%20PARA%20EL%20DESARROLLO%20SOSTENIBLE%20DE%20LOS%20PUEBLOS%20INDIGENAS.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0173-2022_Consejo de Ciencia, Tecnología e Innovación de Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/Organismos/SFP-0319-2021_CONSEJO%20DE%20CIENCIA%20TECNOLOGIA%20E%20INNOVACION%20DE%20HIDALGO.pdf'
                                            },
                                            {
                                                'nombre': 'SFP-SE-02-0174-2022_Universidad Autónoma del Estado de Hidalgo',
                                                'link': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2021/Primer%20Trimestre/Organismos/SFP-0320-2021_UNIVERSIDAD%20AUTONOMA%20DEL%20ESTADO%20DE%20HIDALGO.pdf'
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                'trimestre': '2020',
                                'detalle': [
                                    {
                                        'nombre': 'Circular Núm. 004 Cuarto Trimestre',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2020/Circular%20Num%20004_Cuarto%20Trimestre.pdf'
                                    },
                                    {
                                        'nombre': 'Circular Núm. 0103 Tercer Trimestre',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2020/Circular%20Num%200103_Tercer%20Trimestre.pdf'
                                    },
                                    {
                                        'nombre': 'Circular Núm. 097 Informe Definitivo 2019 y Segundo Trimestre 2020',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2020/Circular%20Num%20097_Informe%20Definitivo%202019%20y%20Segundo%20Trimestre%202020.pdf'
                                    },
                                    {
                                        'nombre': 'Circular Núm. 092 Primer Trimestre Alcance',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2020/Circular%20Num%20092_Primer%20Trimestre%20Alcance.pdf'
                                    },
                                    {
                                        'nombre': 'Circular Núm. 091 Primer Trimestre',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2020/Circular%20Num%20091_Primer%20Trimestre.pdf'
                                    }
                                ]
                            },
                            {
                                'trimestre': '2019',
                                'detalle': [
                                    {
                                        'nombre': 'Circular Num. 089_Cuarto Trimestre',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2019/Circular%20Num%20089_Cuarto%20Trimestre.pdf'
                                    },
                                    {
                                        'nombre': 'Circular Num. 078_Tercer Trimestre Alcance',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2019/Circular%20Num%20078_Tercer%20Trimestre_Alcance.pdf'
                                    },
                                    {
                                        'nombre': 'Circular Num. 077_Tercer Trimestre',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2019/Circular%20Num%20077_Tercer%20Trimestre.pdf'
                                    },
                                    {
                                        'nombre': 'Circular Num. 071_Informe Definitivo 2018 y Segundo Trimestre 2019',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2019/Circular%20Num%20071_Informe%20Definitivo%202018%20y%20Segundo%20Trimestre%202019.pdf'
                                    },
                                    {
                                        'nombre': 'Circular Num. 063_Primer Trimestre',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2019/Circular%20Num%20063_Primer%20Trimestre.pdf'
                                    }
                                ]
                            },
                            {
                                'trimestre': '2018',
                                'detalle': [
                                    {
                                        'nombre': 'Circular Num. 057_Cuarto Trimestre',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2018/Circular%20Num%20057_Cuarto%20Trimestre.pdf'
                                    },
                                    {
                                        'nombre': 'Circular Num. 051_Tercer Trimestre',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2018/Circular%20Num%20051_Tercer%20Trimestre.pdf'
                                    },
                                    {
                                        'nombre': 'Circular Num. 042_Informe Definitivo 2017 y Segundo Trimestre 2018',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2018/Circular%20Num%20042_Informe%20Definitivo%202017%20y%20Segundo%20Trimestre%202018.pdf'
                                    },
                                    {
                                        'nombre': 'Oficio Num. SFP-0536-2018_Primer Trimestre',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/InformesTrimestrales/2018/Oficio%20Num%20SFP-0536-2018_Primer%20Trimestre.pdf'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Cuentas bancarias',
                        'data': [
                            {
                                'trimestre': '2024',
                                'detalle': [
                                    {
                                        'nombre': 'Manuales de normas y lineamientos para el ejercicio de los recursos 2024 del gasto de inversión y del gasto de operación',
                                        'url': '/Servicios/EjerciciodelPresupuesto2024',
                                        'urldos': [
                                            {
                                                'descripcion': 'Anexo R (gasto de operación)',
                                                'link': '../files/servicios/docx/CircularesDireccionGeneraldeEgresos/CuentasBancarias/2024/Anexo R_Gasto de Operacion.docx'
                                            },
                                            {
                                                'descripcion': 'Anexo F (gasto de inversión)',
                                                'link': '../files/servicios/docx/CircularesDireccionGeneraldeEgresos/CuentasBancarias/2024/Anexo F_Gasto de Inversion.docx'
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                'trimestre': '2023',
                                'detalle': [
                                    {
                                        'nombre': 'Manuales de normas y lineamientos para el ejercicio de los recursos 2023 del gasto de inversión y del gasto de operación',
                                        'url': '/Servicios/EjerciciodelPresupuesto2023',
                                        'urldos': [
                                            {
                                                'descripcion': 'Anexo R (gasto de operación)',
                                                'link': '../files/servicios/docx/CircularesDireccionGeneraldeEgresos/CuentasBancarias/2023/Anexo%20R%20(Gasto%20de%20Operacion)%20-%20Secretaria%20de%20Hacienda%20-%20Direccion%20General%20de%20Tesoreria.docx'
                                            },
                                            {
                                                'descripcion': 'Anexo F (gasto de inversión)',
                                                'link': '../files/servicios/docx/CircularesDireccionGeneraldeEgresos/CuentasBancarias/2023/Anexo%20F%20(Gasto%20de%20Inversion)%20-%20Secretaria%20de%20Hacienda%20-%20Direccion%20General%20de%20Tesoreria.docx'
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                'trimestre': '2022',
                                'detalle': [
                                    {
                                        'nombre': 'Circular Núm. 0008',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/CuentasBancarias/2022/CIRCULAR%200008.PDF',
                                        'urldos': [
                                            {
                                                'descripcion': 'Anexo de Circular Formato de Notificación, Ratificación o Baja de Cuenta Bancaria',
                                                'link': '../files/servicios/docx/CircularesDireccionGeneraldeEgresos/CuentasBancarias/2022/Anexo%20de%20Circular%20Formato%20de%20Notificacion,%20Ratificacion%20o%20Baja%20de%20Cuenta%20Bancaria.docx'
                                            },
                                            {
                                                'descripcion': 'Anexo de Circular Formato de Notificación, Ratificación o Baja de Cuenta Bancaria',
                                                'link': '../files/servicios/docx/CircularesDireccionGeneraldeEgresos/CuentasBancarias/2022/Anexo%20de%20Circular_Formato%20de%20Notificacion,%20Ratificacion%20o%20Baja%20de%20Cuenta%20Bancaria230922.docx'
                                            }
                                        ]
                                    },
                                    {
                                        'nombre': 'Circular Núm. 0004',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/CuentasBancarias/2022/CIRCULAR%200004.PDF',
                                        'urldos': [
                                            {
                                                'descripcion': 'Anexo de Circular Formato de Notificación, Ratificación o Baja de Cuenta Bancaria',
                                                'link': '../files/servicios/docx/CircularesDireccionGeneraldeEgresos/CuentasBancarias/2022/Anexo%20de%20Circular_Formato%20de%20Notificacion,%20Ratificacion%20o%20Baja%20de%20Cuenta%20Bancaria.docx'
                                            },
                                            {
                                                'descripcion': 'Anexo de Circular Enlace de Cuentas Bancarias',
                                                'link': '../files/servicios/xls/CircularesDireccionGeneraldeEgresos/CuentasBancarias/2022/Anexo%20de%20Circular_Enlace%20de%20Cuentas%20bancarias.xlsx'
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                'trimestre': '2021',
                                'detalle': [
                                    {
                                        'nombre': 'Circular Núm. 119-2021',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/CuentasBancarias/2021/Circular%20Num%20119-2021.pdf',
                                        'urldos': [
                                            {
                                                'descripcion': 'Formato Anexo (archivo descargable)',
                                                'link': '../files/servicios/docx/CircularesDireccionGeneraldeEgresos/CuentasBancarias/2021/Anexo%20de%20Circular%20Num%20119-2021_Formato%20de%20Notificacion,%20Ratificacion%20o%20Baja%20de%20Cuenta%20Bancaria.docx'
                                            },
                                            {
                                                'descripcion': 'Anexo de Circular Núm. 119-2021 Enlace de Cuentas bancarias (archivo descargable)',
                                                'link': '../files/servicios/xls/CircularesDireccionGeneraldeEgresos/CuentasBancarias/2021/Anexo%20de%20Circular%20Num%20119-2021_Enlace%20de%20Cuentas%20bancarias.xlsx'
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                'trimestre': '2020',
                                'detalle': [
                                    {
                                        'nombre': 'Circular Núm. 001-2020',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/CuentasBancarias/2020/Circular%20Num%20001-2020.pdf',
                                        'urldos': [
                                            {
                                                'descripcion': 'Formato Anexo (archivo descargable)',
                                                'link': '../files/servicios/docx/CircularesDireccionGeneraldeEgresos/CuentasBancarias/2020/Formato%20Anexo_Circular%20Num%20001.docx'
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                'trimestre': '2019',
                                'detalle': [
                                    {
                                        'nombre': 'Oficio Núm. SFP-SE-02-0243-2019',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/CuentasBancarias/2019/Oficio%20Num%20SFP-SE-02-0243-2019.pdf',
                                        'urldos': [
                                            {
                                                'descripcion': 'Formato Anexo (archivo descargable)',
                                                'link': '../files/servicios/docx/CircularesDireccionGeneraldeEgresos/CuentasBancarias/2019/Formato%20Anexo_Oficio%20Num%20SFP-SE-02-0243-2019.docx'
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Apertura de cuentas bancarias específicas para recursos federales',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Circular No. 105',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/AperturadeCuentasBancariasEspeci%CC%81ficasparaRecursosFederales/Circular%20Num%20105.pdf'
                                    },
                                    {
                                        'nombre': 'Circular No. 83',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/AperturadeCuentasBancariasEspeci%CC%81ficasparaRecursosFederales/Circular%20Num%2083.pdf'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Reintegros a la Tesorería de la Federacion de recursos federales',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Circular No. 148',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/ReintegrosalaTesoreriadelaFederaciondeRecursosFederales/CIRCULAR%20%200148.pdf'
                                    },
                                    {
                                        'nombre': 'Circular No. 124',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/ReintegrosalaTesoreriadelaFederaciondeRecursosFederales/Circular%20124.pdf'
                                    },
                                    {
                                        'nombre': 'Circular No. 109',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/ReintegrosalaTesoreriadelaFederaciondeRecursosFederales/Circular%20No%20109.pdf'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Sistema de notificación de cuenta bancaria para reintegros de recursos federales y/o estatales',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Circular No. 110',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/SistemadeNotificaciondeCuentaBancariaparaReintegrosdeRecursosFederalesyEstatales/Circular%20No%20110.pdf'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Recursos Federales',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Circular No. 125',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/ReintegrosalaTesoreriadelaFederaciondeRecursosFederales/Circular%20125.pdf'
                                    },
                                    {
                                        'nombre': 'Circular No. 107',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/RecursosFederales/Circular%20No%20107.pdf'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'Solicitud de trámites (pólizas de egresos y comprobantes bancarios)',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Circular No. 3',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/Solicitud%20de%20Tramites/Circular%20Num%203.pdf'
                                    }
                                ]
                            }
                        ]   
                    },
                    {
                        'titulo': 'SEPDR',
                        'data': [
                            {
                                'trimestre': '',
                                'detalle': [
                                    {
                                        'nombre': 'Circular No. 0015',
                                        'url': '../files/servicios/pdf/CircularesDireccionGeneraldeEgresos/SEPDR/CIRCULAR%20%200015.pdf'
                                    }
                                ]
                            }
                        ]   
                    }
                ]
            }
        ]
    }