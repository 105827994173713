export const transparencia_item = [
    {
        "titulo": 'Transparencia',
        "rubros": [
            {
                "subtitulo": 'Artículo 69 Ley de Transparencia y Acceso a la Información Pública Gubernamental para el Estado de Hidalgo',
                "data": [
                    {
                        "transparencia_item_nombre": '48 Rubros',
                        "transparencia_item_url": 'Rubros'
                    },
                    {
                        "transparencia_item_nombre": 'Ir a Plataforma Nacional de Transparencia',
                        "transparencia_item_url": 'https://consultapublicamx.plataformadetransparencia.org.mx/vut-web/?idSujetoObigadoParametro=2131&idEntidadParametro=13&idSectorParametro=21'
                    }
                ]
            },
            {
                "subtitulo": "Transparencia Financiera",
                "data": [
                    {
                        "transparencia_item_nombre": 'Transparencia Financiera',
                        "transparencia_item_url": '/TransparenciaSecretaria'
                    },
                    {
                        "transparencia_item_nombre": 'Estados Financieros',
                        "transparencia_item_url": '/Secretaría/EstadosFinancieros'
                    },
                    // {
                    //     "transparencia_item_nombre": 'Estados Financieros',
                    //     "transparencia_item_url": '/Secretaría/EstadosFinancieros'
                    // },
                    // {
                    //     "transparencia_item_nombre": 'Informes de Resultados de la Cuenta Pública del Poder Ejecutivo',
                    //     "transparencia_item_url": 'https://s-finanzas.hidalgo.gob.mx/transparencia/informesResultadosCuentaPublica.html'
                    // },
                    {
                        "transparencia_item_nombre": 'Informes de Resultados de la Cuenta Pública del Poder Ejecutivo',
                        "transparencia_item_url": '/InformedeResultadosCuentaPublica'
                    },
                    // {
                    //     "transparencia_item_nombre": 'Armonización Contable',
                    //     "transparencia_item_url": 'https://ehacienda.hidalgo.gob.mx/Transparencia/Armonizacion'
                    // },
                    {
                        "transparencia_item_nombre": 'Armonización Contable',
                        "transparencia_item_url": '/Armonizacion/ArmonizacionContable'
                    },
                    {
                        "transparencia_item_nombre": 'Formatos a que hace referencia la LDFEFyM',
                        "transparencia_item_url": '/FormatosDisciplinaFinanciera'
                    },
                    // {
                    //     "transparencia_item_nombre": 'Informe de la Hacienda Pública',
                    //     "transparencia_item_url": 'https://s-finanzas.hidalgo.gob.mx/servicios/cuentaHaciendaPublica.html'
                    // }
                    {
                        "transparencia_item_nombre": 'Informe de la Hacienda Pública',
                        "transparencia_item_url": '/InformeHaciendaPublica'
                    }
                ]
            }
        ]
    }
]